import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuickSort1Component } from './views/Algorithms/quick-sort1/quick-sort1.component';
import { QuickSort2Component } from './views/Algorithms/quick-sort2/quick-sort2.component';
import { BubbleSortComponent } from './views/Algorithms/bubble-sort/bubble-sort.component';
import { BubbleSortFlagComponent } from './views/Algorithms/bubble-sort-flag/bubble-sort-flag.component';
import { InsertionSortComponent } from './views/Algorithms/insertion-sort/insertion-sort.component';
import { SelectionSortComponent } from './views/Algorithms/selection-sort/selection-sort.component';
import { BinaryTreeComponent } from './views/data-structures/binary-tree/binary-tree.component';
import { DirectedGraphComponent } from './views/data-structures/graphs/directed-graph/directed-graph.component';
import { UndirectedGraphComponent } from './views/data-structures/graphs/undirected-graph.component';


const routes: Routes = [
  { path: 'bubble-sort', component: BubbleSortComponent },
  { path: 'bubble-sort-flag', component: BubbleSortFlagComponent },
  { path: 'insertion-sort', component: InsertionSortComponent },
  { path: 'quick-sort1', component: QuickSort1Component },
  { path: 'quick-sort2', component: QuickSort2Component },
  { path: 'selection-sort', component: SelectionSortComponent },
  { path: 'binary-search-tree', component: BinaryTreeComponent },
  { path: 'directed-graph', component: DirectedGraphComponent },
  { path: 'undirected-graph', component: UndirectedGraphComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [
  BubbleSortComponent, BubbleSortFlagComponent, InsertionSortComponent, QuickSort1Component, QuickSort2Component, SelectionSortComponent,
  BinaryTreeComponent, DirectedGraphComponent, UndirectedGraphComponent,
]
