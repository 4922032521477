<h2>{{_bottomSheetRef.containerInstance.bottomSheetConfig.ariaLabel}}</h2>

<div class="grid-7">
	<ng-container style="padding: 50px 50px 50px 50px;" *ngFor="let node of nodes; let i = index;">
		<button mat-mini-fab color="visualizer-object" (click)="selectNode(node)">{{node}}</button>
	</ng-container>
	<br>
</div>

<br />
<button mat-raised-button (click)="cancel()" color="warn" style="width: 100%;">Cancel</button>