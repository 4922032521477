<div class="flex-container">
	<!-- ---------------------- Tab View - Code Tracer------------------------>
	<div style="min-width: 385px;">
		<mat-tab-group
			#tabLang
			(selectedTabChange)="selectedTabChange(tabLang.selectedIndex)"
		>
			<mat-tab label="C++">
				<pre
					class="prettyprint"
					style="border: 0;"
				><code class="prettyprint"><span id="cpp_stmt_001">void bubbleSort(int arr[], int n)</span>
&#123;
    <span id="cpp_stmt_002">int i = n - 1;</span>
    <span id="cpp_stmt_003">bool done = false;</span>

    <span id="cpp_stmt_004">while(!done)</span>
    &#123;
        <span id="cpp_stmt_005">done = true;</span>        
        <span id="cpp_stmt_006">for (int j = 0; j &lt; i; ++j)</span>
        &#123;
            <span id="cpp_stmt_007">if (arr[j] &gt; arr[j + 1])</span>
            &#123;
                <span id="cpp_stmt_008">done = false;</span>
                <span id="cpp_stmt_009">std::swap(arr[j], arr[j + 1]);</span>
            <span id="cpp_stmt_010">&#125;</span>
        <span id="cpp_stmt_011">&#125;</span>
        <span id="cpp_stmt_012">--i;</span>
    <span id="cpp_stmt_013">&#125;</span>
<span id="cpp_stmt_014">&#125;</span></code></pre>
			</mat-tab>

			<mat-tab label="Java">
				<h1>Hello World</h1>
				<p>System.out.println("hello world");</p>
			</mat-tab>
		</mat-tab-group>
	</div>

	<!-- ---------------------- Table - Analytics --------------------------->
	<div style="min-width: 220px;">
		<div class=" box box-primary">
			<div class="box-header ">
				<h3 class="box-title ">Things to remember</h3>

				<div class="box-tools ">
					&nbsp;
				</div>
			</div>

			<div class="box-body no-padding ">
				<table style="width: 100%; ">
					<tbody>
						<tr>
							<th style="width: 15px">#</th>
							<th>Time Complexity</th>
							<th style="width: 40px; text-align: center;">
								&nbsp;
							</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>Best Case</td>
							<td style=" text-align: center;">
								<span class="badge bg-green"
									>O(n)</span
								>
							</td>
						</tr>
						<tr>
							<td>2.</td>
							<td>Worst Case</td>
							<td style=" text-align: center;">
								<span class="badge bg-red"
									>O(n<sup>2</sup>)</span
								>
							</td>
						</tr>
						<tr>
							<td>3.</td>
							<td>Average Case</td>
							<td style=" text-align: center;">
								<span class="badge bg-red"
									>O(n<sup>2</sup>)</span
								>
							</td>
						</tr>
						<tr>
							<td></td>
							<td>&nbsp;</td>
							<td></td>
						</tr>
						<tr>
							<th style="width: 10px">#</th>
							<th>Others</th>
							<th style="width: 40px"></th>
						</tr>
						<tr>
							<td>4.</td>
							<td>
								When to use <b><i>Flagged Bubble Sort</i></b>
							</td>
							<td style=" text-align: center;">
								<span class="badge bg-aqua"
									>almost sorted array</span
								>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>

<!-- ---------------------- Visualizer --------------------------->
<nn-inplace-algorithm-visualizer
	algorithm="BubbleSortFlag"
	[selectedCode]="selectedCode"
	[statsValues] = "[0, 0]"
	[statsUnits]="['comparisons', 'swaps']"
	[statsPrefixes] = "['','']"
	[statsSuffixes] = "['','']"
></nn-inplace-algorithm-visualizer>
