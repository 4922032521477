import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-insertion-sort',
	templateUrl: './insertion-sort.component.html',
	styleUrls: ['./insertion-sort.component.css']
})
export class InsertionSortComponent implements OnInit
{
	selectedCode = 'cpp';

	constructor() { }

	ngOnInit() { }

	/**
	* TabView Event handler.
	*/
	selectedTabChange(index)
	{
		switch (index)
		{
			case 0:
				this.selectedCode = 'cpp'
				break;
			case 1:
				this.selectedCode = 'python'
				break;
			default:
				this.selectedCode = ''
				break;
		}
	}

}
