export class Helper {
    static arrayEqual(arrA: any[], arrB: any[]): boolean
    {
        if (arrA === arrB)
        {
            return true;
        }
        else if (arrA == null || arrB == null)
        {
            return false;
        }
        else if (arrA.length != arrB.length)
        {
            return false;
        }
        else
        {
            let i = 0;

            while (i < arrA.length && arrA[i] == arrB[i])
            {
                ++i;
            }

            return i == arrA.length;
        }
    }

    static deepEqual(objA: any, objB: any): boolean
    {
        if (objA === objB)
        {
            return true;
        }
        else if (objA == null || objB == null)
        {
            return false;
        }
        if (Object.keys(objA).length != Object.keys(objB).length)
        {
            return false;
        }
        else
        {
            let same = true;
            let i = 0;

            while (i < Object.keys(objA).length && same)
            {
                let k = Object.keys(objA)[i];

                if (Array.isArray(objA[k]))
                {
                    same = this.arrayEqual(objA[k], objB[k]);
                }
                else if (Object.keys(objA[k]).length == 0)
                {
                    same = objA[k] == objB[k];
                }
                else
                {
                    same = this.deepEqual(objA[k], objB[k]);
                }

                ++i;
            }

            return same;
        }
    }

    static isMobile(): boolean
    {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    }
}
