import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})

export class GoogleAnalyticsService
{
  // Guide: https://developers.google.com/analytics/devguides/collection/gtagjs/events

  /* WRONG
  public static eventEmitter(name: string, category: string, action: string, label: string = null, value: number = null)
  {
    // Wrong
    //gtag('event', name, { eventCategory: category, eventLabel: label, eventAction: action, eventValue: value });
  }
  */

  /**
   * Send Google Analytics Events on a web page
   * @param action    the string that will appear as the event action in Google Analytics Event reports.
   * @param category  the string that will appear as the event category.
   * @param label     the string that will appear as the event label.
   * @param value     a non-negative integer that will appear as the event value.
   */
  public static emitEvent(action: string, category: string, label: string, value: number)
  {
    gtag('event', action, { event_category: category, event_label: label, value: value });
  }

  public static emitNonInteractionEvent(name: string, label: string, category: string)
  {
    gtag('event', name, { event_label: label, event_category: category, non_interaction: false });
  }
}
