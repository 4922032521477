/*
    Nguyen, Nguyen

    December 14, 2019
*/

import { Array1DVisualizer } from "../Array1DVisualizer.lib";
import { ScriptManager, Performable } from '../ScriptManager.lib';
import { ArrayVisualizerSnapshotManager } from '../ArrayVisualizerSnapshotManager.lib';
import { Shape2DStyle } from '../Shape2D.lib';

export namespace Algorithms
{
    export class InplaceAlgorithmScriptBuilder
    {
        private sender: Performable;
        private sm: ScriptManager;
        private snap: ArrayVisualizerSnapshotManager;
        private vs: Array1DVisualizer;
        private clonedData: any[];
        private snapID = 0; // To keep track the versions of the visualizer

        /**
         * Construct a script builder
         * @param sender            The object that calls this constructor.
         * @param arrayVisualizer   The array visualizer
         */
        constructor(sender: Performable, scriptManager: ScriptManager, snapshotManager: ArrayVisualizerSnapshotManager, arrayVisualizer: Array1DVisualizer)
        {
            this.sender = sender;
            this.sm = scriptManager;
            this.snap = snapshotManager;
            this.vs = arrayVisualizer;
        }

        /**
         * Build and load the script
         * @param scriptManager The target to load the script to
         * @param algorithm     The algorithm to build
         */
        buildScript(algorithm: string)
        {
            // Clone the data
            this.clonedData = Object.assign([], this.vs.getData());

            // Select a version to sort
            switch (algorithm)
            {
                case 'BubbleSort':
                    this.bubbleSort(this.clonedData);
                    break;

                case 'BubbleSortFlag':
                    this.bubbleSortFlag(this.clonedData);
                    break;

                case 'InsertionSort':
                    this.insertionSort(this.clonedData);
                    break;

                case 'QuickSort1':
                    this.quickSort(this.clonedData, 1);
                    break;

                case 'QuickSort2':
                    this.quickSort(this.clonedData, 2);
                    break;

                case 'SelectionSort':
                    this.selectionSort(this.clonedData);
                    break;
            }
        }

        /*****************************************************************************************************************************/
        private bubbleSort(arr: any[])
        {
            let n = arr.length;
            let swapCounter = 0; // Count number of swaps for Analytics
            let comparisonCounter = 0; // Count number of comparisons for scripting/animating purposes

            // Snapshot
            this.snap.setMultipleIterators([0, 1], [0, 0], [true, true]);
            this.snapID = this.snap.applyChanging();

            this.sm.addScript({
                key: 'normal',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'selectStatement', args: ['stmt_001'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            this.sm.addScript({
                key: `Outer For Loop`,
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'selectStatement', args: ['stmt_002'] },
                    { target: this.vs, command: 'setIterator', args: [0, 0, true] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            for (let i = 0; i < n - 1; ++i)
            {
                this.sm.addScript({
                    key: 'Inner For Loop',
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['stmt_003'] },
                        { target: this.vs, command: 'setIterator', args: [1, 0, true] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                for (var j = 0; j < n - i - 1; ++j)
                {
                    this.sm.addScript({
                        key: 'If Statement',
                        snapID: this.snapID,
                        actions: [
                            { target: this.sender, command: 'selectStatement', args: ['stmt_004'] },
                            { target: this.sender, command: 'updateStatsValue', args: [0, ++comparisonCounter] },
                            { target: this.sender, command: 'delay', args: [150] }
                        ]
                    });

                    if (arr[j] > arr[j + 1])
                    {
                        // Snapshot
                        this.snap.setMultipleElements([j, j + 1], [arr[j + 1], arr[j]]);
                        this.snapID = this.snap.applyChanging();

                        this.sm.addScript({
                            key: 'swap',
                            snapID: this.snapID,
                            actions: [
                                { target: this.sender, command: 'selectStatement', args: ['stmt_005'] },
                                { target: this.sender, command: 'updateStatsValue', args: [1, ++swapCounter] },
                                { target: this.vs, command: 'swap', args: [j, j + 1] }
                            ]
                        });

                        [arr[j], arr[j + 1]] = [arr[j + 1], arr[j]];
                    }

                    this.sm.addScript({
                        key: 'End If',
                        snapID: this.snapID,
                        actions: [
                            { target: this.sender, command: 'selectStatement', args: ['stmt_006'] },
                            { target: this.sender, command: 'delay', args: [150] }
                        ]
                    });

                    // Snapshot
                    this.snap.setIterator(1, j + 1);
                    this.snapID = this.snap.applyChanging();

                    this.sm.addScript({
                        key: 'Inner For Loop - Next',
                        snapID: this.snapID,
                        actions: [
                            { target: this.sender, command: 'selectStatement', args: ['stmt_003'] },
                            { target: this.vs, command: 'moveIterator', args: [1, j + 1] }
                        ]
                    });
                }

                this.sm.addScript({
                    key: 'End Inner For Loop',
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['stmt_007'] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                // Snapshot
                this.snap.setElement(j, arr[j], this.vs.config.cellAccentColor);
                this.snapID = this.snap.applyChanging();

                this.sm.addScript({
                    key: 'Mark as correct',
                    snapID: this.snapID,
                    actions: [
                        { target: this.vs, command: 'setColor', args: [j, this.vs.config.cellAccentColor] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                // Snapshot
                this.snap.setIterator(0, i + 1);
                this.snapID = this.snap.applyChanging();

                this.sm.addScript({
                    key: `Outter For Loop - Next`,
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['stmt_002'] },
                        { target: this.vs, command: 'moveIterator', args: [0, i + 1] }
                    ]
                });
            }

            this.sm.addScript({
                key: 'End Outter For Loop',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'selectStatement', args: ['stmt_008'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            // Snapshot
            this.snap.setElement(0, arr[0], this.vs.config.cellAccentColor);
            this.snapID = this.snap.applyChanging();

            this.sm.addScript({
                key: 'Mark as correct',
                snapID: this.snapID,
                actions: [
                    { target: this.vs, command: 'setColor', args: [0, this.vs.config.cellAccentColor] }
                ]
            });

            this.sm.addScript({
                key: 'End Function',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'selectStatement', args: ['stmt_009'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            this.sm.addScript({
                key: 'Un-select the all statements',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'unselectAllStatements', args: null }
                ]
            });

            // Script's General Info
            this.sm.setGeneralInfo('0', n * (n - 1) / 2);
            this.sm.setGeneralInfo('1', swapCounter);
        }

        private bubbleSortFlag(arr: any[])
        {
            let swapCounter = 0; // Count number of swaps for Analytics
            let comparisonCounter = 0; // Count number of comparisons for scripting/animating purposes

            let i = arr.length - 1;
            let j = 0;
            let done = false;

            this.sm.addScript({
                key: 'Enter The Function',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'selectStatement', args: ['stmt_001'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            // Snapshot
            this.snap.setIterator(0, i, true);
            this.snapID = this.snap.applyChanging();

            this.sm.addScript({
                key: 'Declare variable',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'selectMultipleStatements', args: ['stmt_002', 'stmt_003'] },
                    { target: this.vs, command: 'setIterator', args: [0, i, true] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            ///////////////////////////////////////////////////////////////////////////

            this.sm.addScript({
                key: 'While Loop',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'selectStatement', args: ['stmt_004'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            while (!done)
            {
                this.sm.addScript({
                    key: 'Select a statement',
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['stmt_005'] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                done = true;

                // Snapshot
                this.snap.setIterator(1, 0, true);
                this.snapID = this.snap.applyChanging();

                this.sm.addScript({
                    key: 'For Loop',
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['stmt_006'] },
                        { target: this.vs, command: 'setIterator', args: [1, 0, true] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                ///////////////////////////////////////////////////////////////////////////

                for (j = 0; j < i; ++j)
                {
                    this.sm.addScript({
                        key: 'If Statement',
                        snapID: this.snapID,
                        actions: [
                            { target: this.sender, command: 'selectStatement', args: ['stmt_007'] },
                            { target: this.sender, command: 'updateStatsValue', args: [0, ++comparisonCounter] },
                            { target: this.sender, command: 'delay', args: [150] }
                        ]
                    });

                    if (arr[j] > arr[j + 1])
                    {
                        this.sm.addScript({
                            key: 'Update variable',
                            snapID: this.snapID,
                            actions: [
                                { target: this.sender, command: 'selectStatement', args: ['stmt_008'] },
                                { target: this.sender, command: 'delay', args: [150] }
                            ]
                        });

                        done = false;

                        // Snapshot
                        this.snap.setMultipleElements([j, j + 1], [arr[j + 1], arr[j]]);
                        this.snapID = this.snap.applyChanging();

                        this.sm.addScript({
                            key: 'swap',
                            snapID: this.snapID,
                            actions: [
                                { target: this.vs, command: 'swap', args: [j, j + 1] },
                                { target: this.sender, command: 'selectStatement', args: ['stmt_009'] },
                                { target: this.sender, command: 'updateStatsValue', args: [1, ++swapCounter] }
                            ]
                        });

                        [arr[j], arr[j + 1]] = [arr[j + 1], arr[j]];
                    }

                    this.sm.addScript({
                        key: 'End If',
                        snapID: this.snapID,
                        actions: [
                            { target: this.sender, command: 'selectStatement', args: ['stmt_010'] },
                            { target: this.sender, command: 'delay', args: [150] }
                        ]
                    });

                    ///////////////////////////////////////////////////////////////////////////

                    // Snapshot
                    this.snap.setIterator(1, j + 1, true);
                    this.snapID = this.snap.applyChanging();

                    this.sm.addScript({
                        key: 'Advance the Loop',
                        snapID: this.snapID,
                        actions: [
                            { target: this.sender, command: 'selectStatement', args: ['stmt_006'] },
                            { target: this.vs, command: 'moveIterator', args: [1, j + 1] },
                        ]
                    });
                }

                ///////////////////////////////////////////////////////////////////////////

                this.sm.addScript({
                    key: 'End For Loop',
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['stmt_011'] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                // Snapshot
                this.snap.setElement(i, arr[i], this.vs.config.cellAccentColor);
                this.snapID = this.snap.applyChanging();

                this.sm.addScript({
                    key: 'The element is at proper position',
                    snapID: this.snapID,
                    actions: [
                        { target: this.vs, command: 'setColor', args: [i, this.vs.config.cellAccentColor] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                --i;

                // Snapshot
                this.snap.setIterator(0, i);
                this.snapID = this.snap.applyChanging();

                this.sm.addScript({
                    key: 'Advance The While Loop',
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['stmt_012'] },
                        { target: this.vs, command: 'moveIterator', args: [0, i] }
                    ]
                });

                this.sm.addScript({
                    key: 'While Loop - Next',
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['stmt_004'] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });
            }

            this.sm.addScript({
                key: 'End While',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'selectStatement', args: ['stmt_013'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            ///////////////////////////////////////////////////////////////////////////

            let tempIndices: number[] = [];
            let tempValues: any[] = [];
            let tempColors: Shape2DStyle[] = [];

            for (let h = 0; h <= i; ++h)
            {
                tempIndices.push(h);
                tempValues.push(arr[h]);
                tempColors.push(this.vs.config.cellAccentColor);
            }

            // Snapshot
            this.snap.setMultipleElements(tempIndices, tempValues, tempColors);
            this.snapID = this.snap.applyChanging();

            this.sm.addScript({
                key: 'All elements are sorted',
                snapID: this.snapID,
                actions: [
                    { target: this.vs, command: 'setColorForAll', args: [this.vs.config.cellAccentColor] }
                ]
            });

            this.sm.addScript({
                key: 'End Function',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'selectStatement', args: ['stmt_014'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            this.sm.addScript({
                key: 'Un-select the all statements',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'unselectAllStatements', args: null }
                ]
            });
            ///////////////////////////////////////////////////////////////////////////

            // Script's General Info
            this.sm.setGeneralInfo('0', comparisonCounter);
            this.sm.setGeneralInfo('1', swapCounter);
        }

        /*****************************************************************************************************************************/

        private insertionSort(arr: any[])
        {
            let shiftCounter = 0; // Count number of swaps for Analytics
            let comparisonCounter = 0; // Count number of comparisons for scripting/animating purposes

            let key: any;
            let i: number;
            let j: number;
            let n = arr.length;

            this.sm.addScript({
                key: 'Enter The Function',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'selectStatement', args: ['stmt_001'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            // Snapshot
            this.snap.setIterator(0, 1, true);
            this.snapID = this.snap.applyChanging();

            this.sm.addScript({
                key: 'Declare variables',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'selectStatement', args: ['stmt_002'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            ///////////////////////////////////////////////////////////////////////////

            this.sm.addScript({
                key: 'For Loop',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'selectStatement', args: ['stmt_003'] },
                    { target: this.vs, command: 'setIterator', args: [0, 1, true] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            for (i = 1; i < n; ++i)
            {
                key = arr[i];

                // Snapshot
                this.snap.moveElementToTemporaryArray(i, i);
                this.snapID = this.snap.applyChanging();

                this.sm.addScript({
                    key: 'Choose a key',
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['stmt_004'] },
                        { target: this.vs, command: 'moveElementToTemporaryArray', args: [i] }
                    ]
                });

                j = i - 1;

                // Snapshot
                this.snap.setIterator(1, j, true);
                this.snapID = this.snap.applyChanging();

                this.sm.addScript({
                    key: 'Set j',
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['stmt_005'] },
                        { target: this.vs, command: 'setIterator', args: [1, j, true] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                this.sm.addScript({
                    key: 'While Loop',
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['stmt_006'] },
                        { target: this.sender, command: 'updateStatsValue', args: [0, ++comparisonCounter] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                while (j >= 0 && key < arr[j])
                {
                    arr[j + 1] = arr[j]; // Shift right.

                    // Snapshot
                    this.snap.setMultipleElements([j + 1, j], [arr[j], null], [this.vs.config.cellColor, this.vs.config.cellSelectionColor]);
                    this.snapID = this.snap.applyChanging();

                    this.sm.addScript({
                        key: `Shift Right`,
                        snapID: this.snapID,
                        actions: [
                            { target: this.sender, command: 'selectStatement', args: ['stmt_007'] },
                            { target: this.sender, command: 'updateStatsValue', args: [1, ++shiftCounter] },
                            { target: this.vs, command: 'shiftRight', args: [j] }
                        ]
                    });

                    --j;

                    // Snapshot
                    this.snap.setIterator(1, j);
                    this.snapID = this.snap.applyChanging();

                    this.sm.addScript({
                        key: 'Advance the Loop',
                        snapID: this.snapID,
                        actions: [
                            { target: this.sender, command: 'selectStatement', args: ['stmt_008'] },
                            { target: this.vs, command: 'moveIterator', args: [1, j] },
                        ]
                    });

                    this.sm.addScript({
                        key: 'While Loop - Next',
                        snapID: this.snapID,
                        actions: [
                            { target: this.sender, command: 'selectStatement', args: ['stmt_006'] },
                            { target: this.sender, command: 'updateStatsValue', args: [0, ++comparisonCounter] },
                            { target: this.sender, command: 'delay', args: [150] }
                        ]
                    });
                }

                this.sm.addScript({
                    key: 'End While Loop',
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['stmt_009'] },
                        { target: this.sender, command: 'delay', args: [50] }
                    ]
                });

                arr[j + 1] = key;

                // Snapshot
                this.snap.moveElementBackFromTemporaryArray(j + 1, i);
                this.snapID = this.snap.applyChanging();

                this.sm.addScript({
                    key: `Move the key to the correct position`,
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['stmt_010'] },
                        { target: this.vs, command: 'moveElementBackFromTemporaryArray', args: [i, j + 1] }
                    ]
                });

                // Snapshot
                this.snap.setIterator(0, i + 1);
                this.snapID = this.snap.applyChanging();

                this.sm.addScript({
                    key: 'For Loop - Next',
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['stmt_003'] },
                        { target: this.vs, command: 'moveIterator', args: [0, i + 1] }
                    ]
                });
            }

            this.sm.addScript({
                key: 'End For Loop',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'selectStatement', args: ['stmt_011'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            this.sm.addScript({
                key: 'End Function',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'selectStatement', args: ['stmt_012'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            this.sm.addScript({
                key: 'CodeTracer - End',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'unselectAllStatements', args: null }
                ]
            });

            ///////////////////////////////////////////////////////////////////////////

            // Script's General Info
            this.sm.setGeneralInfo('0', shiftCounter);
            this.sm.setGeneralInfo('1', comparisonCounter);
        }

        /*****************************************************************************************************************************/

        private quickSort(arr: any[], version: number)
        {
            let n = arr.length;
            var temp = { swapCounter: 0, comparisonCounter: 0 }; //Analytics

            this.sm.addScript({
                key: 'CodeTracer', snapID: this.snapID, actions: [
                    { target: this.sender, command: 'selectStatement', args: ['f1_stmt_001'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            if (version == 1)
            {
                this.quickSort_v1(arr, 0, n - 1, temp);
            }
            else
            {
                this.quickSort_v2(arr, 0, n - 1, temp);
            }

            this.sm.addScript({
                key: 'CodeTracer', snapID: this.snapID, actions: [
                    { target: this.sender, command: 'unselectAllStatements', args: null }
                ]
            });

            this.sm.addScript({
                key: 'CodeTracer', snapID: this.snapID, actions: [
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            // Script's General Info
            this.sm.setGeneralInfo('0', temp.comparisonCounter);
            this.sm.setGeneralInfo('1', temp.swapCounter);
        }

        private quickSort_v1(arr: any, h: number, k: number, temp: { swapCounter: number, comparisonCounter: number })
        {
            this.sm.addScript({
                key: 'CodeTracer', snapID: this.snapID, actions: [
                    { target: this.sender, command: 'selectStatement', args: ['f1_stmt_001'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            this.sm.addScript({
                key: 'CodeTracer', snapID: this.snapID, actions: [
                    { target: this.sender, command: 'selectStatement', args: ['f1_stmt_002'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            if (h < k)
            {
                this.sm.addScript({
                    key: 'Partition',
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['f1_stmt_003A'] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                let p = this.quickSort_partition_v1(arr, h, k, temp);

                this.sm.addScript({
                    key: 'CodeTracer', snapID: this.snapID, actions: [
                        { target: this.sender, command: 'selectStatement', args: ['f1_stmt_003B'] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                this.sm.addScript({
                    key: 'CodeTracer', snapID: this.snapID, actions: [
                        { target: this.sender, command: 'selectStatement', args: ['f1_stmt_004'] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                this.quickSort_v1(arr, h, p - 1, temp);

                this.sm.addScript({
                    key: 'CodeTracer', snapID: this.snapID, actions: [
                        { target: this.sender, command: 'selectStatement', args: ['f1_stmt_005'] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                this.quickSort_v1(arr, p + 1, k, temp);
            }
            else if (h == k)
            {
                // Snapshot
                this.snap.setElement(h, arr[h], this.vs.config.cellAccentColor);
                this.snapID = this.snap.applyChanging();

                this.sm.addScript({
                    key: 'Mark as correct',
                    snapID: this.snapID,
                    actions: [
                        { target: this.vs, command: 'setColor', args: [h, this.vs.config.cellAccentColor] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });
            }

            this.sm.addScript({
                key: 'CodeTracer - End If', snapID: this.snapID, actions: [
                    { target: this.sender, command: 'selectStatement', args: ['f1_stmt_006'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            this.sm.addScript({
                key: 'CodeTracer - End Function', snapID: this.snapID, actions: [
                    { target: this.sender, command: 'selectStatement', args: ['f1_stmt_007'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });
        }

        private quickSort_partition_v1(arr: any, startIndex: number, endIndex: number, temp: { swapCounter: number, comparisonCounter: number })
        {
            let pivot = arr[startIndex];
            let h = startIndex + 1;
            let k = endIndex;

            ////////////////////////////////////////////////////////////////////////////////////////

            this.sm.addScript({
                key: 'CodeTracer', snapID: this.snapID, actions: [
                    { target: this.sender, command: 'selectStatement', args: ['f2_stmt_001'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            // Snapshot
            this.snap.setElement(startIndex, arr[startIndex], this.vs.config.cellSelectionColor);
            this.snap.setTag(startIndex, 'pivot', true);
            this.snap.setMultipleIterators([0, 1], [h, k], [true, true]);
            this.snapID = this.snap.applyChanging();

            this.sm.addScript({
                key: 'CodeTracer', snapID: this.snapID, actions: [
                    { target: this.sender, command: 'selectStatement', args: ['f2_stmt_002'] },
                    { target: this.vs, command: 'setColor', args: [startIndex, this.vs.config.cellSelectionColor] },
                    { target: this.vs, command: 'setTagVisibility', args: [startIndex, true] },
                    { target: this.vs, command: 'setIterator', args: [0, h, true] },
                    { target: this.vs, command: 'setIterator', args: [1, k, true] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            //////////////////////////////////////////////////////////////////////////////////////
            this.sm.addScript({
                key: 'CodeTracer', snapID: this.snapID, actions: [
                    { target: this.sender, command: 'selectStatement', args: ['f2_stmt_003'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            while (h <= k)
            {
                this.sm.addScript({
                    key: 'CodeTracer', snapID: this.snapID, actions: [
                        { target: this.sender, command: 'selectStatement', args: ['f2_stmt_004'] },
                        { target: this.sender, command: 'updateStatsValue', args: [0, ++temp.comparisonCounter] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                while (h <= k && arr[h] <= pivot)
                {
                    ++h;

                    // Snapshot
                    this.snap.setIterator(0, h);
                    this.snapID = this.snap.applyChanging();

                    this.sm.addScript({
                        key: `MoveIterators`,
                        snapID: this.snapID,
                        actions: [
                            { target: this.sender, command: 'selectStatement', args: ['f2_stmt_005'] },
                            { target: this.vs, command: 'moveIterator', args: [0, h] }
                        ]
                    });

                    this.sm.addScript({
                        key: 'CodeTracer', snapID: this.snapID, actions: [
                            { target: this.sender, command: 'selectStatement', args: ['f2_stmt_004'] },
                            { target: this.sender, command: 'updateStatsValue', args: [0, (h <= k ? ++temp.comparisonCounter : temp.comparisonCounter)] },
                            { target: this.sender, command: 'delay', args: [150] }
                        ]
                    });
                }

                this.sm.addScript({
                    key: 'While Loop', snapID: this.snapID, actions: [
                        { target: this.sender, command: 'selectStatement', args: ['f2_stmt_006'] },
                        { target: this.sender, command: 'updateStatsValue', args: [0, (h <= k ? ++temp.comparisonCounter : temp.comparisonCounter)] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                while (h <= k && arr[k] > pivot)
                {
                    --k;

                    // Snapshot
                    this.snap.setIterator(1, k);
                    this.snapID = this.snap.applyChanging();

                    this.sm.addScript({
                        key: `MoveIterators`,
                        snapID: this.snapID,
                        actions: [
                            { target: this.sender, command: 'selectStatement', args: ['f2_stmt_007'] },
                            { target: this.vs, command: 'moveIterator', args: [1, k] }
                        ]
                    });

                    this.sm.addScript({
                        key: 'While Loop - Next', snapID: this.snapID, actions: [
                            { target: this.sender, command: 'selectStatement', args: ['f2_stmt_006'] },
                            { target: this.sender, command: 'updateStatsValue', args: [0, (h <= k ? ++temp.comparisonCounter : temp.comparisonCounter)] },
                            { target: this.sender, command: 'delay', args: [150] }
                        ]
                    });
                }

                this.sm.addScript({
                    key: 'CodeTracer', snapID: this.snapID, actions: [
                        { target: this.sender, command: 'selectStatement', args: ['f2_stmt_008'] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                if (h < k)
                {
                    // Snapshot
                    this.snap.setMultipleElements([h, k], [arr[k], arr[h]], null);
                    this.snapID = this.snap.applyChanging();

                    this.sm.addScript({
                        key: 'swap',
                        snapID: this.snapID,
                        actions: [
                            { target: this.sender, command: 'selectStatement', args: ['f2_stmt_009'] },
                            { target: this.sender, command: 'updateStatsValue', args: [1, ++temp.swapCounter] },
                            { target: this.vs, command: 'swap', args: [h, k] }
                        ]
                    });

                    [arr[h], arr[k]] = [arr[k], arr[h]];
                }

                this.sm.addScript({
                    key: 'CodeTracer', snapID: this.snapID, actions: [
                        { target: this.sender, command: 'selectStatement', args: ['f2_stmt_003'] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });
            }

            if (k != startIndex)
            {
                // Snapshot
                this.snap.setMultipleElements([startIndex, k], [arr[k], arr[startIndex]], [this.vs.config.cellColor, this.vs.config.cellAccentColor]);
                this.snap.setTag(startIndex, 'pivot', false);
                this.snapID = this.snap.applyChanging();

                this.sm.addScript({
                    key: 'swap', snapID: this.snapID, actions: [
                        { target: this.sender, command: 'selectStatement', args: ['f2_stmt_010'] },
                        { target: this.sender, command: 'updateStatsValue', args: [1, ++temp.swapCounter] },
                        { target: this.vs, command: 'setTagVisibility', args: [startIndex, false] },
                        { target: this.vs, command: 'setColor', args: [startIndex, this.vs.config.cellAccentColor] },
                        { target: this.vs, command: 'swap', args: [startIndex, k] }
                    ]
                });

                [arr[startIndex], arr[k]] = [arr[k], arr[startIndex]];
            }
            else
            {
                // Snapshot
                this.snap.setElement(k, arr[k], this.vs.config.cellAccentColor);
                this.snap.setTag(startIndex, 'pivot', false);
                this.snapID = this.snap.applyChanging();

                this.sm.addScript({
                    key: 'Mark as correct',
                    snapID: this.snapID,
                    actions: [
                        { target: this.vs, command: 'setTagVisibility', args: [startIndex, false] },
                        { target: this.vs, command: 'setColor', args: [startIndex, this.vs.config.cellAccentColor] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });
            }

            this.sm.addScript({
                key: 'delay', snapID: this.snapID, actions: [
                    { target: this.sender, command: 'selectStatement', args: ['f2_stmt_011'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            return k;
        }

        private quickSort_v2(arr: any, h: number, k: number, temp: { swapCounter: number, comparisonCounter: number })
        {
            this.sm.addScript({
                key: 'CodeTracer', snapID: this.snapID, actions: [
                    { target: this.sender, command: 'selectStatement', args: ['f1_stmt_001'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });
            this.sm.addScript({
                key: 'CodeTracer', snapID: this.snapID, actions: [
                    { target: this.sender, command: 'selectStatement', args: ['f1_stmt_002'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            if (h < k)
            {
                this.sm.addScript({
                    key: 'Partition',
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['f1_stmt_003A'] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                let p = this.quickSort_partition_v2(arr, h, k, temp);

                this.sm.addScript({
                    key: 'CodeTracer', snapID: this.snapID, actions: [
                        { target: this.sender, command: 'selectStatement', args: ['f1_stmt_003B'] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                this.sm.addScript({
                    key: 'CodeTracer', snapID: this.snapID, actions: [
                        { target: this.sender, command: 'selectStatement', args: ['f1_stmt_004'] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                this.quickSort_v2(arr, h, p - 1, temp);

                this.sm.addScript({
                    key: 'CodeTracer', snapID: this.snapID, actions: [
                        { target: this.sender, command: 'selectStatement', args: ['f1_stmt_005'] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                this.quickSort_v2(arr, p + 1, k, temp);
            }
            else if (h == k)
            {
                // Snapshot
                this.snap.setElement(h, arr[h], this.vs.config.cellAccentColor);
                this.snapID = this.snap.applyChanging();

                this.sm.addScript({
                    key: 'Mark as correct',
                    snapID: this.snapID,
                    actions: [
                        { target: this.vs, command: 'setColor', args: [h, this.vs.config.cellAccentColor] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });
            }

            this.sm.addScript({
                key: 'CodeTracer - End If', snapID: this.snapID, actions: [
                    { target: this.sender, command: 'selectStatement', args: ['f1_stmt_006'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            this.sm.addScript({
                key: 'CodeTracer - End Function', snapID: this.snapID, actions: [
                    { target: this.sender, command: 'selectStatement', args: ['f1_stmt_007'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });
        }

        private quickSort_partition_v2(arr: any, startIndex: number, endIndex: number, temp: { swapCounter: number, comparisonCounter: number })
        {
            let pivot = arr[startIndex];
            let h = startIndex;
            let k = startIndex + 1;

            ////////////////////////////////////////////////////////////////////////////////////////
            this.sm.addScript({
                key: 'CodeTracer', snapID: this.snapID, actions: [
                    { target: this.sender, command: 'selectStatement', args: ['f2_stmt_001'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            // Snapshot
            this.snap.setElement(startIndex, arr[startIndex], this.vs.config.cellSelectionColor);
            this.snap.setTag(startIndex, 'pivot', true);
            this.snap.setMultipleIterators([0, 1], [h, k], [true, true]);
            this.snapID = this.snap.applyChanging();

            this.sm.addScript({
                key: 'CodeTracer', snapID: this.snapID, actions: [
                    { target: this.sender, command: 'selectStatement', args: ['f2_stmt_002'] },
                    { target: this.vs, command: 'setColor', args: [startIndex, this.vs.config.cellSelectionColor] },
                    { target: this.vs, command: 'setTagVisibility', args: [startIndex, true] },
                    { target: this.vs, command: 'setIterator', args: [0, h, true] },
                    { target: this.vs, command: 'setIterator', args: [1, k, true] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            ///////////////////////////////////////////////////////////////////////////////////////

            this.sm.addScript({
                key: 'CodeTracer', snapID: this.snapID, actions: [
                    { target: this.sender, command: 'selectStatement', args: ['f2_stmt_003'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            for (k; k <= endIndex; ++k)
            {
                this.sm.addScript({
                    key: 'CodeTracer', snapID: this.snapID, actions: [
                        { target: this.sender, command: 'selectStatement', args: ['f2_stmt_004'] },
                        { target: this.sender, command: 'updateStatsValue', args: [0, ++temp.comparisonCounter] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                if (arr[k] <= pivot)
                {
                    ++h;

                    // Snapshot
                    this.snap.setIterator(0, h);
                    this.snapID = this.snap.applyChanging();

                    this.sm.addScript({
                        key: `MoveIterators`,
                        snapID: this.snapID,
                        actions: [
                            { target: this.sender, command: 'selectStatement', args: ['f2_stmt_005'] },
                            { target: this.vs, command: 'moveIterator', args: [0, h] }
                        ]
                    });

                    this.sm.addScript({
                        key: 'If statement',
                        snapID: this.snapID,
                        actions: [
                            { target: this.sender, command: 'selectStatement', args: ['f2_stmt_006'] },
                            { target: this.sender, command: 'delay', args: [150] }
                        ]
                    });

                    if (h != k)
                    {
                        // Snapshot
                        this.snap.setMultipleElements([h, k], [arr[k], arr[h]], null);
                        this.snapID = this.snap.applyChanging();

                        this.sm.addScript({
                            key: 'swap',
                            snapID: this.snapID,
                            actions: [
                                { target: this.sender, command: 'updateStatsValue', args: [1, ++temp.swapCounter] },
                                { target: this.vs, command: 'swap', args: [h, k] }
                            ]
                        });

                        [arr[h], arr[k]] = [arr[k], arr[h]];
                    }
                }

                this.sm.addScript({
                    key: 'End If',
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['f2_stmt_007'] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                // Snapshot
                this.snap.setIterator(1, k + 1);
                this.snapID = this.snap.applyChanging();

                this.sm.addScript({
                    key: `Advance the for loop`,
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['f2_stmt_003'] },
                        { target: this.vs, command: 'moveIterator', args: [1, k + 1] }
                    ]
                });
            }

            this.sm.addScript({
                key: 'End For Loop',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'selectStatement', args: ['f2_stmt_008'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });


            if (h != startIndex)
            {
                // Snapshot
                this.snap.setMultipleElements([startIndex, h], [arr[h], arr[startIndex]], [this.vs.config.cellColor, this.vs.config.cellAccentColor]);
                this.snap.setTag(startIndex, 'pivot', false);
                this.snapID = this.snap.applyChanging();

                this.sm.addScript({
                    key: 'swap', snapID: this.snapID, actions: [
                        { target: this.sender, command: 'selectStatement', args: ['f2_stmt_009'] },
                        { target: this.sender, command: 'updateStatsValue', args: [1, ++temp.swapCounter] },
                        { target: this.vs, command: 'setTagVisibility', args: [startIndex, false] },
                        { target: this.vs, command: 'setColor', args: [startIndex, this.vs.config.cellAccentColor] },
                        { target: this.vs, command: 'swap', args: [startIndex, h] }
                    ]
                });

                [arr[startIndex], arr[h]] = [arr[h], arr[startIndex]];
            }
            else
            {
                // Snapshot
                this.snap.setElement(k, arr[k], this.vs.config.cellAccentColor);
                this.snap.setTag(startIndex, 'pivot', false);
                this.snapID = this.snap.applyChanging();

                this.sm.addScript({
                    key: 'Mark as correct',
                    snapID: this.snapID,
                    actions: [
                        { target: this.vs, command: 'setTagVisibility', args: [startIndex, false] },
                        { target: this.vs, command: 'setColor', args: [startIndex, this.vs.config.cellAccentColor] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });
            }

            this.sm.addScript({
                key: 'delay', snapID: this.snapID, actions: [
                    { target: this.sender, command: 'selectStatement', args: ['f2_stmt_010'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            return h;
        }

        /*****************************************************************************************************************************/

        private selectionSort(arr: any[])
        {
            let swapCounter = 0; // Count number of swaps for Analytics
            let comparisonCounter = 0; // Count number of comparisons for scripting/animating purposes

            let i = 0;
            let j = 0;
            let minIndex: number;
            let n = arr.length;

            this.sm.addScript({
                key: 'Enter The Function',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'selectStatement', args: ['stmt_001'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            this.sm.addScript({
                key: 'Declare variable',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'selectStatement', args: ['stmt_002'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            // Snapshot
            this.snap.setIterator(0, i, true);
            this.snapID = this.snap.applyChanging();

            this.sm.addScript({
                key: 'Outter For Loop',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'selectStatement', args: ['stmt_003'] },
                    { target: this.vs, command: 'setIterator', args: [0, i, true] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            for (i = 0; i < n - 1; ++i)
            {
                minIndex = i;

                this.sm.addScript({
                    key: 'Set MinIndex',
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['stmt_004'] },
                        { target: this.vs, command: 'setColor', args: [minIndex, this.vs.config.cellSelectionColor] },
                        { target: this.vs, command: 'setTagVisibility', args: [minIndex, true] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                // Snapshot
                this.snap.setIterator(1, i + 1, true);
                this.snapID = this.snap.applyChanging();

                this.sm.addScript({
                    key: `Inner For Loop`,
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['stmt_005'] },
                        { target: this.vs, command: 'setIterator', args: [1, i + 1, true] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                for (j = i + 1; j < n; ++j)
                {
                    this.sm.addScript({
                        key: 'If Statement',
                        snapID: this.snapID,
                        actions: [
                            { target: this.sender, command: 'selectStatement', args: ['stmt_006'] },
                            { target: this.sender, command: 'updateStatsValue', args: [0, ++comparisonCounter] },
                            { target: this.sender, command: 'delay', args: [150] }
                        ]
                    });

                    if (arr[j] < arr[minIndex])
                    {
                        let oldMinIndex = minIndex;
                        minIndex = j;

                        this.sm.addScript({
                            key: 'Set MinIndex',
                            snapID: this.snapID,
                            actions: [
                                // De-select old minIndex
                                { target: this.vs, command: 'setColor', args: [oldMinIndex, this.vs.config.cellColor] },
                                { target: this.vs, command: 'setTagVisibility', args: [oldMinIndex, false] },

                                // Select new minIndex
                                { target: this.sender, command: 'selectStatement', args: ['stmt_007'] },
                                { target: this.vs, command: 'setColor', args: [minIndex, this.vs.config.cellSelectionColor] },
                                { target: this.vs, command: 'setTagVisibility', args: [minIndex, true] },
                                { target: this.sender, command: 'delay', args: [150] }
                            ]
                        });
                    }

                    this.sm.addScript({
                        key: 'End If',
                        snapID: this.snapID,
                        actions: [
                            { target: this.sender, command: 'selectStatement', args: ['stmt_008'] },
                            { target: this.sender, command: 'delay', args: [150] }
                        ]
                    });

                    this.sm.addScript({
                        key: 'Inner For Loop - Next',
                        snapID: this.snapID,
                        actions: [
                            { target: this.sender, command: 'selectStatement', args: ['stmt_005'] },
                            { target: this.vs, command: 'moveIterator', args: [1, j + 1] },
                        ]
                    });
                }

                this.sm.addScript({
                    key: 'End Inner For Loop',
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['stmt_009'] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                // Snapshot
                this.snap.setTag(minIndex, 'min', false);
                this.snap.setMultipleElements([minIndex, i], [arr[i], arr[minIndex]], [this.vs.config.cellColor, this.vs.config.cellAccentColor]);
                this.snapID = this.snap.applyChanging();

                this.sm.addScript({
                    key: 'swap',
                    snapID: this.snapID,
                    actions: [
                        // De-select old minIndex
                        { target: this.sender, command: 'selectStatement', args: ['stmt_010'] },
                        { target: this.vs, command: 'setTagVisibility', args: [minIndex, false] },
                        { target: this.vs, command: 'setColor', args: [minIndex, this.vs.config.cellAccentColor] },
                        { target: this.sender, command: 'updateStatsValue', args: [1, ++swapCounter] },
                        { target: this.vs, command: 'swap', args: [minIndex, i] }
                    ]
                });

                [arr[minIndex], arr[i]] = [arr[i], arr[minIndex]];

                // Snapshot
                this.snap.setIterator(0, i + 1, true);
                this.snapID = this.snap.applyChanging();

                this.sm.addScript({
                    key: 'Outter For Loop - Next',
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['stmt_003'] },
                        { target: this.vs, command: 'moveIterator', args: [0, i + 1] },
                    ]
                });
            }

            this.sm.addScript({
                key: 'End Outter For Loop',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'selectStatement', args: ['stmt_011'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });
            
            this.sm.addScript({
                key: 'End Function',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'selectStatement', args: ['stmt_012'] },
                    { target: this.vs, command: 'setColor', args: [i, this.vs.config.cellAccentColor] }, // arr[i] is the last element
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            this.sm.addScript({
                key: '',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'unselectAllStatements', args: null }
                ]
            });

            // Script's General Info
            this.sm.setGeneralInfo('0', comparisonCounter);
            this.sm.setGeneralInfo('1', swapCounter);
        }
    }
}