/*
    Nguyen, Nguyen

    December 14, 2019
*/

import { ScriptManager, Performable } from '../ScriptManager.lib';
import { GraphVisualizer, Node } from '../GraphVisualizer.lib';

export namespace DataStructures
{
    export class GraphScriptBuilder
    {
        private sender: Performable;
        private sm: ScriptManager;
        private vs: GraphVisualizer;
        private clonedRoot: Node;
        private clonedNodes: Node[];
        private snapID = 0; // To keep track the versions of the visualizer

        /**
         * Construct a script builder
         * @param sender            The object that calls this constructor.
         * @param visualizer        The visualizer
         */
        constructor(sender: Performable, scriptManager: ScriptManager, visualizer: GraphVisualizer)
        {
            this.sender = sender;
            this.sm = scriptManager;
            this.vs = visualizer;
        }

        /**
         * Build and load the script
         * @param scriptManager The target to load the script to
         * @param algorithm     The algorithm to build
         */
        buildTraversalScript(traversalType: 'dfs' | 'bfs', fromNode: string)
        {
            // Clone the data. BE CAREFULE. Some how the UNABLE TO CLONE the nodes
            this.clonedRoot = Object.assign({}, this.vs.root);
            this.clonedNodes = Object.assign([{}], this.vs.nodes);
			//this.clonedNodes[0].setFillColor('red');
			
			// Unselect all the nodes
			for(let e of this.clonedNodes)
			{
				e.visited = false;
			}

			// Select a version to sort
            switch (traversalType)
            {
                case 'dfs':
                    this.dfs(fromNode);
                    break;
                case 'bfs':
                    this.bfs(fromNode);
                    break;
            }
        }

        /********************************************************** DFS - BEGIN *******************************************************************/
        getDFSTraversalTrack(node: string)
        {
			let results: {action: 'visit' | 'back', node: string}[] = [];

			let n = this.clonedNodes.find(e => e.getText() == node);
			if (n != null)
			{
				this.buildDFSTraversalTrack(null, n, results);
			}
            
            return results;
        }

        private chooseNodeFromList(nodes: {node: Node, weight: number}[])
        {
            let choosenNode: Node = null;

            for(var e of nodes)
            {
                if(!e.node.visited)
                {
                    if (choosenNode == null || choosenNode.getText().localeCompare(e.node.getText()) > 0)
                    {
                        choosenNode = e.node;
                    }
                }
            }
            return choosenNode;
        }
        private buildDFSTraversalTrack(previousNode: Node, currentNode: Node, results: {action: 'visit' | 'back', node: string}[]): void
        {
            if(!currentNode.visited)
            {
                results.push({action: 'visit', node: currentNode.getText()});
            }

            currentNode.visited = true;
            
            // Choose an adjacent node.
            var choosenNode: Node = this.chooseNodeFromList(currentNode.childNodes);
            
            while(choosenNode != null)
            {
                this.buildDFSTraversalTrack(currentNode, choosenNode, results);
                choosenNode = this.chooseNodeFromList(currentNode.childNodes);
            }
            /*
            for(var e of currentNode.childNodes)
            {
                if(!e.node.visited)
                {
                    this.buildDFSTraversalTrack(currentNode, e.node, results);
                }
            }
            */

            if (previousNode)
            {
                results.push({action: 'back', node: previousNode.getText()});
            }
        }
	
		// Assumtions: All nodes in the graph are flagged as unvisited.
        private dfs(node: string): void
        {
            let track = this.getDFSTraversalTrack(node);
            
            if (track.length > 0)
            {
                this.sm.addScript({
                    key: 'Delay',
                    snapID: null,
                    actions: [
                        { target: this.sender, command: 'addToOutput', args: ['DFS: ' + node] },
                        { target: this.sender, command: 'delay', args: [2000] },
                        { target: this.vs, command: 'visit', args: [node] },
                        { target: this.sender, command: 'delay', args: [100] }
                    ]
                });

                this.sm.addScript({
                    key: 'Visit Node',
                    snapID: null,
                    actions: [
                        
                    ]
                });
            }

            for(let i = 1; i < track.length; ++i)
            {	
                if(track[i].action == 'visit')
                {
					
                    this.sm.addScript({
                        key: 'Animate traverse to the next node',
                        snapID: null,
                        actions: [
                            { target: this.vs, command: 'animateTraverse', args: [track[i - 1].node, track[i].node, '1000'] },
                            { target: this.sender, command: 'delay', args: [800] }
                        ]
                    });

					this.sm.addScript({
                        key: 'Visit Node',
                        snapID: null,
                        actions: [
                            { target: this.vs, command: 'visit', args: [track[i].node] },
                            { target: this.sender, command: 'delay', args: [100] }
                        ]
                    });
                    
                    this.sm.addScript({
                        key: 'Display The Output',
                        snapID: null,
                        actions: [
                            { target: this.sender, command: 'addToOutput', args: [', ' + track[i].node] },
                            { target: this.sender, command: 'delay', args: [30] }
                        ]
					});
                }
                else
                {
					if (i > 0)
					{
						this.sm.addScript({
							key: 'Animate backtrack to the previous node',
							snapID: null,
							actions: [
								{ target: this.vs, command: 'animateBacktrack', args: [track[i].node, track[i - 1].node, '1500'] },
								{ target: this.sender, command: 'delay', args: [1500] }
							]
						});
					}
				}
				
            }
        }
        /************************************************************ DFS - END *****************************************************************/
                
        /********************************************************** BFS - BEGIN *******************************************************************/
        getBFSTraversalTrack(node: string)
        {
            // Clone the data. BE CAREFULE. Some how the UNABLE TO CLONE the nodes
            this.clonedRoot = Object.assign({}, this.vs.root);
            this.clonedNodes = Object.assign([{}], this.vs.nodes);

			let results: {action: 'traverse' | 'back', fromNode: string, toNode: string}[] = [];

            let currentNode = this.clonedNodes.find(e => e.getText() == node);
			if (currentNode != null)
			{
                let queue: Node[] = [];
                queue.push(currentNode);

                while(queue.length > 0)
                {
                    currentNode = queue.shift();
                    if (!currentNode.visited)
                    {   
                        currentNode.visited = true;
                    }

                    // Choose an adjacent node.
                    var choosenNode: Node = this.chooseNodeFromList(currentNode.childNodes);
                    
                    while(choosenNode != null)
                    {
                        //this.buildDFSTraversalTrack(currentNode, choosenNode, results);
                        
                        results.push({action: 'traverse',fromNode: currentNode.getText(), toNode: choosenNode.getText()});
                        queue.push(choosenNode);
                        choosenNode.visited = true;

                        choosenNode = this.chooseNodeFromList(currentNode.childNodes);
                    }

                    /*
                    for(let e of currentNode.childNodes)
                    {
                        if (!e.node.visited)
                        {
                            results.push({action: 'traverse',fromNode: currentNode.getText(), toNode: e.node.getText()});
                            queue.push(e.node);
                            e.node.visited = true;
                        }
                    }
                    */
                }
			}
            
            return results;
        }

        private bfs(node: string): void
        {
            let track = this.getBFSTraversalTrack(node);

            if (track.length > 0)
            {
                this.sm.addScript({
                    key: 'Delay',
                    snapID: null,
                    actions: [
                        { target: this.sender, command: 'addToOutput', args: ['BFS: ' + node] },
                        { target: this.sender, command: 'delay', args: [2000] },
                        { target: this.vs, command: 'visit', args: [node] },
                        { target: this.sender, command: 'delay', args: [100] }
                    ]
                });

                this.sm.addScript({
                    key: 'Visit Node',
                    snapID: null,
                    actions: [
                        
                    ]
                });
            }

            
            for(let i = 0; i < track.length; ++i)
            {	
                this.sm.addScript({
                    key: 'Animate traverse to the next node',
                    snapID: null,
                    actions: [
                        { target: this.vs, command: 'animateTraverse', args: [track[i].fromNode, track[i].toNode, '1000'] },
                        { target: this.sender, command: 'delay', args: [800] }
                    ]
                });

                this.sm.addScript({
                    key: 'Visit Node',
                    snapID: null,
                    actions: [
                        { target: this.vs, command: 'visit', args: [track[i].toNode] },
                        { target: this.sender, command: 'delay', args: [100] }
                    ]
                });
                
                this.sm.addScript({
                    key: 'Display The Output',
                    snapID: null,
                    actions: [
                        { target: this.sender, command: 'addToOutput', args: [', ' + track[i].toNode] },
                        { target: this.sender, command: 'delay', args: [30] }
                    ]
                });
            }
        }
        /************************************************************ BFS - END *****************************************************************/

        
        
        /*
        private dfs(node: Node)
        {
            let swapCounter = 0; // Count number of swaps for Analytics
            let comparisonCounter = 0; // Count number of comparisons for scripting/animating purposes

            for (let i = 0; i < n - 1; ++i)
            {
                this.sm.addScript({
                    key: 'Inner For Loop',
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['stmt_003'] },
                        { target: this.vs, command: 'setIterator', args: [1, 0, true] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                for (var j = 0; j < n - i - 1; ++j)
                {
                    this.sm.addScript({
                        key: 'If Statement',
                        snapID: this.snapID,
                        actions: [
                            { target: this.sender, command: 'selectStatement', args: ['stmt_004'] },
                            { target: this.sender, command: 'updateStatsValue', args: [0, ++comparisonCounter] },
                            { target: this.sender, command: 'delay', args: [150] }
                        ]
                    });

                    if (arr[j] > arr[j + 1])
                    {
                        // Snapshot
                        this.snap.setMultipleElements([j, j + 1], [arr[j + 1], arr[j]]);
                        this.snapID = this.snap.applyChanging();

                        this.sm.addScript({
                            key: 'swap',
                            snapID: this.snapID,
                            actions: [
                                { target: this.sender, command: 'selectStatement', args: ['stmt_005'] },
                                { target: this.sender, command: 'updateStatsValue', args: [1, ++swapCounter] },
                                { target: this.vs, command: 'swap', args: [j, j + 1] }
                            ]
                        });

                        [arr[j], arr[j + 1]] = [arr[j + 1], arr[j]];
                    }

                    this.sm.addScript({
                        key: 'End If',
                        snapID: this.snapID,
                        actions: [
                            { target: this.sender, command: 'selectStatement', args: ['stmt_006'] },
                            { target: this.sender, command: 'delay', args: [150] }
                        ]
                    });

                    // Snapshot
                    this.snap.setIterator(1, j + 1);
                    this.snapID = this.snap.applyChanging();

                    this.sm.addScript({
                        key: 'Inner For Loop - Next',
                        snapID: this.snapID,
                        actions: [
                            { target: this.sender, command: 'selectStatement', args: ['stmt_003'] },
                            { target: this.vs, command: 'moveIterator', args: [1, j + 1] }
                        ]
                    });
                }

                this.sm.addScript({
                    key: 'End Inner For Loop',
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['stmt_007'] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                // Snapshot
                this.snap.setElement(j, arr[j], this.vs.config.cellAccentColor);
                this.snapID = this.snap.applyChanging();

                this.sm.addScript({
                    key: 'Mark as correct',
                    snapID: this.snapID,
                    actions: [
                        { target: this.vs, command: 'setColor', args: [j, this.vs.config.cellAccentColor] },
                        { target: this.sender, command: 'delay', args: [150] }
                    ]
                });

                // Snapshot
                this.snap.setIterator(0, i + 1);
                this.snapID = this.snap.applyChanging();

                this.sm.addScript({
                    key: `Outter For Loop - Next`,
                    snapID: this.snapID,
                    actions: [
                        { target: this.sender, command: 'selectStatement', args: ['stmt_002'] },
                        { target: this.vs, command: 'moveIterator', args: [0, i + 1] }
                    ]
                });
            }

            this.sm.addScript({
                key: 'End Outter For Loop',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'selectStatement', args: ['stmt_008'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            // Snapshot
            this.snap.setElement(0, arr[0], this.vs.config.cellAccentColor);
            this.snapID = this.snap.applyChanging();

            this.sm.addScript({
                key: 'Mark as correct',
                snapID: this.snapID,
                actions: [
                    { target: this.vs, command: 'setColor', args: [0, this.vs.config.cellAccentColor] }
                ]
            });

            this.sm.addScript({
                key: 'End Function',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'selectStatement', args: ['stmt_009'] },
                    { target: this.sender, command: 'delay', args: [150] }
                ]
            });

            this.sm.addScript({
                key: 'Un-select the all statements',
                snapID: this.snapID,
                actions: [
                    { target: this.sender, command: 'unselectAllStatements', args: null }
                ]
            });

            // Script's General Info
            this.sm.setGeneralInfo('0', n * (n - 1) / 2);
            this.sm.setGeneralInfo('1', swapCounter);
        }
        */
    }
}