import { Component, Input, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DataStructures } from 'src/app/lib/DataStructures/GraphScriptBuilder.lib';
import { GraphVisualizer, GraphVisualizerConfig, Node } from 'src/app/lib/GraphVisualizer.lib';
import { Helper } from 'src/app/lib/Helper.lib';
import { ScriptManager } from 'src/app/lib/ScriptManager.lib';
import { DynamicSnackBar } from '../dynamic-snack-bar/dynamic-snack-bar.component';
import { AddEdgesForm } from './forms/add-edges/add-edges.component';
import { AddNodesForm } from './forms/add-nodes/add-nodes.component';
import { CreateEditEdgeComponent, CreateEditEdgesResult } from './forms/create-edit-edge/create-edit-edge.component';
import { SelectNodeForm } from './forms/select-node/select-node.component';

@Component({
	selector: 'nn-graph',
	templateUrl: './graph.component.html',
	styleUrls: ['./graph.component.css']
})
export class GraphComponent implements OnInit
{
	@Input('type') graphType: 'directed' | 'undirected';

	// Visualizer & ScriptPlayer
	private scriptManager: ScriptManager;
	private vs: GraphVisualizer;
	private autoPlayNext = false;
	isPlaying = false;
	progress: number = 0;
	private snackbarMessage = '';

	constructor(private router: Router, private route: ActivatedRoute, private _bottomSheet: MatBottomSheet, private snackBar: MatSnackBar) { }

	// Very very Important to clean up things
	ngOnDestroy()
	{
		// If autoPlayNext==true && the script is not cleared, 
		// 		the timer will keep running til the end of the script.
		//
		// Which means, this component will not be desctructed 
		//      if the script keeps playing.
		this.scriptManager.clear();

		// From Angular 11, the element must be destroyed
		// when routing between pages that use the same element ID.
		document.getElementById('canvasContainer').remove();
		document.getElementById('menu').remove();
	}

	ngOnInit(): void
	{
		// Instantiate a visualizer
		let config: GraphVisualizerConfig = {
			canvasID: 'nodes',
			edgeCanvasID: 'edges',
			marginTop: 50,
			marginLeft: 55,
			graphType: this.graphType,
			nodeRadius: 20,
			horizontalSpacing: 10,
			verticalSpacing: 70,
			nodeColor: { fillColor: '#0097A7', strokeColor: '#18FFFF', strokeWidth: 1, textColor: 'white' },
			nodeAccentColor: { fillColor: '#E64A19', strokeColor: '#ffccbc', strokeWidth: 1, textColor: 'white' },
			nodeSelectionColor: { fillColor: '#795548', strokeColor: '#4e342e', strokeWidth: 1, textColor: 'white' },
			tagColor: { fillColor: 'transparent', strokeColor: 'transparent', strokeWidth: 1, textColor: '#212121' },
			edgeColor: { fillColor: '#616161', strokeColor: '#707070', strokeWidth: 1.5, textColor: 'white' },
			edgeActivatedColor: { fillColor: 'white', strokeColor: '#2962ff', strokeWidth: 3, textColor: 'white' },
			edgeVisitedColor: { fillColor: 'white', strokeColor: '#7b1fa2', strokeWidth: 2, textColor: 'white' },
		};

		this.vs = new GraphVisualizer(config);


		// Script Manager
		this.scriptManager = new ScriptManager();

		// Visualizer's event
		this.vs.onAnimationEnd.addHandler((args: any) =>
		{
			let length = this.scriptManager.getLength();
			this.progress = Math.ceil((this.scriptManager.getCurrentPosition() / (length - 2)) * 100);

			if (this.autoPlayNext) {
				if (this.scriptManager.getCurrentPosition() >= length - 1) {
					this.autoPlayNext = false;
					this.isPlaying = false;
				}
				setTimeout(() => { this.scriptManager.next(); }, 50);
			}
		});

		// ScriptManager's event
		this.scriptManager.onPositionChanged.addHandler(() =>
		{
			if (this.scriptManager.getCurrentPosition() == this.scriptManager.getLength() - 2) {
				this.progress = 100;
				this.autoPlayNext = false;
				this.isPlaying = false;
			}
		});

		////////////////// URL QUERY ///////////////////////
		// ?nodes=(a,200,200),(b,300,300)
		// ?nodes=a,b,c,d&pos=(200,200),(100,100),(300,300),(250,250)&edges=(a,b)
		this.route.queryParams
			.subscribe(params =>
			{
				for(let i in params)
				{
					console.log(i);
					console.log(`# ${i} = ${params[i]}`) // popular
				}

				if(params['nodes'] != null && params['pos'] != null)
				{
					if (params['nodes'].length > 0)
					{
						this.vs.createNodesFromList(params['nodes'], params['pos']);
						this.vs.createEdgesFromList(params['edges']);
					}
				}				
				else
				{
					// LOAD A SAMPLE GRAPH		
					this.generateSampleGraph1();
				}
			}
		);

		// TEMPORARY
		//setInterval(() => { this.btnGetURL(); }, 2000);
		this.vs.onStateChanged.addHandler(() => { this.btnGetURL(); });
	}

	/**
	 * Reset the graph.
	*/
	reset(): void
	{
		this.vs.animCtrl.stop();
		this.autoPlayNext = false;
		this.isPlaying = false;
		this.scriptManager.clear();

		this.snackbarMessage = '';
		this.snackBar.dismiss();
		this.vs.setAllNodesAsUnvisited();
		this.vs.setAllEdgesAsUnvisited();
	}

	clearCanvas()
	{
		this.reset();
		this.vs.clear();
	}

	perform(command: string, args: any): void
	{
		switch (command) {
			case 'addToOutput':
				this.snackbarMessage += args[0];
				this.updateMessageSnackBar(this.snackbarMessage, 30000);
				break;

			case 'delay':
				if (this.isPlaying) {
					setTimeout(() =>
					{
						// Check if there is any action in the script. Except itself
						// getCurrentPosition() + 1 (`1` means this delay action itself)
						if (this.scriptManager.getCurrentPosition() + 1 < this.scriptManager.getLength()) {
							this.scriptManager.next();
						}
					}, args[0] * this.vs.getSpeedRatio());
				}
				break;

			default:
				throw new Error("Method not implemented.");
		}
	}

	performMediaControlCommand($event: { command: string, args: any[] })
	{
		switch ($event.command) {
			case 'play':
				if (!this.vs.locked) {
					//this.prepareVS();
					this.autoPlayNext = true; // This line of code must be on the top.
					this.isPlaying = true;
					this.scriptManager.next();
				}
				break;

			case 'pause':
				this.autoPlayNext = false;
				this.isPlaying = false;
				break;

			case 'next':
				if (!this.vs.locked) {
					this.scriptManager.next();
				}
				break;

			case 'resizeCanvas':
				this.vs.resizeCanvas();
				break;

			case 'speed':
				//this.vs.setSpeed($event.args[0])
				break;
			default:
				throw new Error("Method not implemented.");
		}
	}

	updateMessageSnackBar(message: string, duration: number)
	{
		if (this.snackBar._openedSnackBarRef)
			this.snackBar._openedSnackBarRef.containerInstance.snackBarConfig.data = message;
		else {
			let configSuccess: MatSnackBarConfig = {
				panelClass: 'style-success',
				duration: duration,
				horizontalPosition: 'center',
				verticalPosition: 'bottom'
			};

			this.snackBar.openFromComponent(DynamicSnackBar, { data: message, ...configSuccess });
		}
	}

	showMessageSnackBar(message: string, duration: number)
	{
		let configSuccess: MatSnackBarConfig = {
			panelClass: 'style-success',
			duration: duration,
			horizontalPosition: 'center',
			verticalPosition: 'bottom'
		};

		this.snackBar.openFromComponent(DynamicSnackBar, { data: message, ...configSuccess });
	}

	btnCreateEdge()
	{
		if (true || Helper.isMobile()) // MOBILE
		{
			this._bottomSheet.open(CreateEditEdgeComponent, { data: this.vs.nodes, disableClose: true });
			this._bottomSheet._openedBottomSheetRef.containerInstance.bottomSheetConfig.ariaLabel = 'Create Multiple Edges';
			this._bottomSheet._openedBottomSheetRef.afterDismissed().subscribe((data: CreateEditEdgesResult) =>
			{
				if (data != null) {
					for(let i of data.sourceNodes)
					{
						let source = this.vs.getNode(i);

						for(let j of data.targetNodes)
						{
							let target = this.vs.getNode(j);
							this.vs.createEdge(source, target, 1);
						}
					}
				}
			})
		}
		else  // PC
		{
			this.showMessageSnackBar('Select the source node', 20000);
			this.vs.switchToCreatingEdgeMode();

			this.vs.onSelectedNodesChanged.addHandler((e) =>
			{
				if (e.length == 1) {
					this.showMessageSnackBar('Select the target node', 200000);
				}
				else {
					this.snackBar.dismiss();
				}
			});
		}
	}

	btnGetURL()
	{
		let nodeList = '';
		let posList = '';
		let edgeList = '';
		
		for (let n of this.vs.nodes) {
			nodeList += n.getText() + ',';
			posList += '(' + n.getX() + ',' + n.getY() + ')' + ',';
			for (let e of n.edges) {
				edgeList += '(' + e.tail.getText() + ',' + e.head.getText() + ')' + ',';
			}
		}


		// function processAjaxData(response, urlPath){
		// 	document.getElementById("content").innerHTML = response.html;
		// 	document.title = response.pageTitle;
		// 	window.history.pushState({"html":response.html,"pageTitle":response.pageTitle},"", urlPath);
		// }

		
		//document.getElementById("content").innerHTML = response.html;
		//document.title = "response.pageTitle";
		let myUrl = `${window.location.origin }${this.router.url.split('?')[0]}?nodes=${nodeList}&pos=${posList}&edges=${edgeList}`;
		window.history.replaceState({"html":"response.html","pageTitle":"response.pageTitle"},"", myUrl);

		// this.route.queryParams
		// 	.subscribe(params =>
		// 	{
		// 		params['nodes'] = 'jaja'
		// 		params['pos'] = 'jaja'
		// 		params['edges'] = 'jaja'
		// 	}
		// );

		//alert(`${window.location.origin }${this.router.url.split('?')[0]}?nodes=${nodeList}&pos=${posList}&edges=${edgeList}`);
	}

	btnMoveNode(input: string)
	{
		var splitted = input.replace(/\s/g, "").split(",")
		this.vs.moveNode(this.vs.getNode(splitted[0]), Number(splitted[1]), Number(splitted[2]));
	}

	btnTest1() 
	{
		return;
		this.generateSampleGraph3();

		this.reset();

		// Script
		this.scriptManager = new ScriptManager();
		let scriptBuilder = new DataStructures.GraphScriptBuilder(this, this.scriptManager, this.vs);
		scriptBuilder.buildTraversalScript('bfs', 'a');

		// let tracks = scriptBuilder.getBFSTraversalTrack('a');
		// console.log(tracks)

		if (this.scriptManager.getLength() > 0) {
			this.performMediaControlCommand({ command: 'play', args: [] });
		}
		else {
			this.updateMessageSnackBar('There is nothing to show', 5000);
		}
	}

	btnTest2() 
	{
		let temp = this.vs.isEulerian();
		let eulerianType = '';
		if (temp == 0)
		{
			eulerianType = 'False';
		}
		else if (temp == 1)
		{
			eulerianType = 'True';
		}
		else if (temp == 2)
		{
			eulerianType = 'Semi (Eulerian path only)';
		}

		let hamiltonianCheckResult = '';
		if (this.vs.checkHamiltonian() == 0)
		{
			hamiltonianCheckResult = 'False';
		}
		else
		{
			hamiltonianCheckResult = 'May be T/F';
		}

		let text=  `Connected=${this.vs.isConnected()}; \nEulerian=${eulerianType}; Hamiltonian=${hamiltonianCheckResult}`;
		

		this.updateMessageSnackBar(text, 10000);
	}

	btnTest3()
	{
		return;
		this.reset();
		this.vs.clear();

		let nodes = 'x, y,';
		let positions = '(100,100), (300,200)';
		this.vs.createNodesFromList(nodes, positions);
		this.vs.createEdgesFromList('(x,y)');

		let nodeA = this.vs.getNode('x');
		nodeA.edges[0].makeQuadraticCurve();
		
	}

	btnGenerateVE()
	{
		let nodeList = '';
		let posList = '';
		let edgeList = '';

		console.log('-------- NODES -------');
		for (let n of this.vs.nodes) {
			console.log(n.getText() + ' (' + n.getX() + ',' + n.getY() + ')');
		}

		console.log('-------- EDGES -------');
		for (let n of this.vs.nodes) {
			nodeList += ', ' + n.getText();
			posList += ', ' + '(' + n.getX() + ',' + n.getY() + ')';
			for (let e of n.edges) {
				edgeList += ',' + '(' + e.tail.getText() + ',' + e.head.getText() + ')';
				console.log(n.getText() + ' (' + e.tail.getText() + ',' + e.head.getText() + ')');
			}
		}
		this.updateMessageSnackBar('Nodes: ' + nodeList + '\nPositions: ' + posList + '\nEdges: ' + edgeList, 30000);
		alert('Nodes: ' + nodeList + '\nPositions' + posList + '\nEdges: ' + edgeList);
	}

	generateSampleGraph1()
	{
		this.reset();
		this.vs.clear();

		let nodes = 'a, b, c, d, e, f, g ';
		let positions = '(461,85), (141,275), (176,74), (458,279), (44,169), (329,183), (607,207)';
		this.vs.createNodesFromList(nodes, positions);
		this.vs.createEdgesFromList('(a,d),(a,g),(a,c),(b,c),(b,f),(c,f),(d,g),(e,b),(f,a),(f,d)');
	}

	generateSampleGraph2()
	{
		this.reset();
		this.vs.clear();

		this.vs.insertNodeAt('a', 50, 50);
		this.vs.insertNodeAt('b', 200, 50);
		this.vs.insertNodeAt('c', 350, 50);
		this.vs.insertNodeAt('d', 50, 350);
		this.vs.insertNodeAt('e', 250, 350);

		this.vs.createEdgesFromList("(a,e),(a,d),(a,b),(b,c),(c,d)");
	}

	generateSampleGraph3()
	{
		this.reset();
		this.vs.clear();

		this.vs.insertNodeAt('a', 200, 200);
		this.vs.insertNodeAt('b', 350, 100);
		this.vs.insertNodeAt('c', 350, 250);
		this.vs.insertNodeAt('d', 500, 200);
		this.vs.insertNodeAt('e', 500, 300);
		this.vs.insertNodeAt('f', 370, 400);
		this.vs.insertNodeAt('g', 250, 350);

		this.vs.createEdgesFromList("(a,g),(a,b),(b,c),(c,d),(c,f),(c,g),(d,b),(e,c),(f,d),(f,e),(g,c),(g,f)");
	}

	openAddNodesForm(): void 
	{
		this._bottomSheet.open(AddNodesForm);
		this._bottomSheet._openedBottomSheetRef.afterDismissed().subscribe((data: { nodes: string, positions: string }) =>
		{
			this.vs.createNodesFromList(data.nodes, data.positions);
		})
	}

	openAddEdgesForm(): void 
	{
		this._bottomSheet.open(AddEdgesForm);
		this._bottomSheet._openedBottomSheetRef.afterDismissed().subscribe((data: string) =>
		{
			this.vs.createEdgesFromList(data);
		})
	}

	showNodePickerForTraversal(traversal: 'dfs' | 'bfs')
	{
		this._bottomSheet.open(SelectNodeForm, { data: this.vs.nodes, disableClose: true });
		this._bottomSheet._openedBottomSheetRef.containerInstance.bottomSheetConfig.ariaLabel = 'Select a starting node';
		this._bottomSheet._openedBottomSheetRef.afterDismissed().subscribe((data: string) =>
		{
			if (data != null) {
				this.reset();

				// Script
				this.scriptManager = new ScriptManager();
				let scriptBuilder = new DataStructures.GraphScriptBuilder(this, this.scriptManager, this.vs);
				scriptBuilder.buildTraversalScript(traversal, data);

				if (this.scriptManager.getLength() > 0) {
					this.performMediaControlCommand({ command: 'play', args: [] });
				}
				else {
					this.updateMessageSnackBar('There is nothing to show', 5000);
				}
			}
		})
	}
}