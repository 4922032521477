/*
    Nguyen, Nguyen

    December 14, 2019
*/

import { Shape2D, Shape2DStyle } from './Shape2D.lib';

export class Rectangle extends Shape2D
{
    private mWidth: number;
    private mHeight: number;
    private mTextAlign: string;

    constructor(x: number, y: number, width: number, height: number,
        text: any, color: Shape2DStyle)
    {
        super(x, y);
        this.mWidth = width;
        this.mHeight = height;

        // Frame
        this.svg.setAttributeNS(null, 'w', `${width}`);
        this.svg.setAttributeNS(null, 'h', `${height}`);

        this.svg_base = document.createElementNS("http://www.w3.org/2000/svg", "rect");
        this.svg_base.setAttributeNS(null, 'x', `${color.strokeWidth / 2}`);
        this.svg_base.setAttributeNS(null, 'y', `${color.strokeWidth / 2}`);
        this.svg_base.setAttributeNS(null, 'rx', '5');
        this.svg_base.setAttributeNS(null, 'ry', '5');
        this.svg_base.setAttributeNS(null, 'width', `${width - color.strokeWidth}`);
        this.svg_base.setAttributeNS(null, 'height', `${height - color.strokeWidth}`);

        this.setFillColor(color.fillColor);
        this.setStrokeColor(color.strokeColor);
        this.setStrokeWidth(color.strokeWidth);

        // Text
        this.svg_text = document.createElementNS("http://www.w3.org/2000/svg", "text");
        this.svg_text.setAttributeNS(null, 'x', `${width / 2}`);
        this.svg_text.setAttributeNS(null, 'y', `${height / 2}`);
        this.svg_text.setAttributeNS(null, 'text-anchor', 'middle');
        this.svg_text.setAttributeNS(null, 'alignment-baseline', 'central');
        this.setText(text);
        this.setTextColor(color.textColor);

        this.svg.appendChild(this.svg_base);
        this.svg.appendChild(this.svg_text);

    }
    
    // Getters
    getWidth()
    {
        return this.mWidth;
    }

    getHeight()
    {
        return this.mHeight;
    }

    getTextAlign()
    {
        return this.mTextAlign;
    }

    setTextAlign(value: string)
    {
        this.mTextAlign = value;
    }
}