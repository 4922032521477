import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-binary-tree',
  templateUrl: './binary-tree.component.html',
  styleUrls: ['./binary-tree.component.css']
})
export class BinaryTreeComponent implements OnInit {

  
  constructor() { }

  ngOnInit() {
  }

  
}
