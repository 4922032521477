import { Component, Inject, Input } from '@angular/core';
import {
	MatSnackBar, MatSnackBarConfig,
	MAT_SNACK_BAR_DATA, MatSnackBarRef
} from '@angular/material/snack-bar';

@Component({
	selector: 'dynamic-snack-bar',
	templateUrl: 'dynamic-snack-bar.component.html',
})
export class DynamicSnackBar {
	constructor(
		public snackBarRef: MatSnackBarRef<DynamicSnackBar>,
		@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
}