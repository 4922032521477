/*
    Nguyen, Nguyen

    December 14, 2019
*/

import { Shape2D, Shape2DStyle } from './Shape2D.lib';

export class Line extends Shape2D // I know, Line is an 1D shape. Not 2D. But....
{
    private mX1: number;
    private mY1: number;
    private mX2: number;
    private mY2: number;
    private mTextAlign: string;

    constructor(x1: number, y1: number, x2: number, y2: number,
        text: any, color: Shape2DStyle)
    {
        super(0, 0);

        this.mX1 = x1;
        this.mY1 = y1;
        this.mX2 = x2;
        this.mY2 = y2;

        // Frame
        this.svg.setAttributeNS(null, 'w', `${Math.abs(x2 - x1)}`);
        this.svg.setAttributeNS(null, 'h', `${Math.abs(y2 - y1)}`);

        this.svg_base = document.createElementNS("http://www.w3.org/2000/svg", "path");
        this.svg_base.setAttributeNS(null, 'd', `M${x1},${y1} ${x2},${y2}`);

        this.setFillColor(color.fillColor);
        this.setStrokeColor(color.strokeColor);
        this.setStrokeWidth(color.strokeWidth);

        this.svg.appendChild(this.svg_base);
    }
    
    makeQuadraticCurve()
    {
        let midPoint = {x: (this.mX1 + this.mX2) / 2, y: (this.mY1 + this.mY2) / 2};
        let slope = (this.mY2 - this.mY1) / (this.mX2 - this.mX1);

        // Find Line 2 Formula
        let line2_Slope = -1 / slope;
        let line2_b = midPoint.y - line2_Slope * midPoint.x;

        console.log(`y = ${line2_Slope}x + ${line2_b}`);

        // Line 2's x-axis intercept
        let x = -line2_b / line2_Slope;

        console.log(`x-axis intercept: (${x}, ${0})`);
        
        this.svg_base.setAttributeNS(null, 'd', `M${this.mX1},${this.mY1}, Q 200 400, ${this.mX2},${this.mY2}`);
        this.setFillColor('transparent');
    }

    // Getters
    getTextAlign()
    {
        return this.mTextAlign;
    }
    
    private tailOffset = 0;
    private headOffset = 0;

    setTailPointOffset(distance: number)
    {
        this.tailOffset = distance;
    }
    
    setTailPoint(x: number, y: number)
    {
        this.mX1 = x;
        this.mY1 = y;

        this.svg_base.setAttributeNS(null, 'd', `M${this.mX1},${this.mY1} ${this.mX2},${this.mY2}`);

        if (this.onPositionChanged)
        {
            this.onPositionChanged.raiseEvent(['tail point changed']);
        }
    }

    setHeadPointOffset(distance: number)
    {
        this.headOffset = distance;
        console.log('head off: ' + distance );
    }
    
    setHeadPoint(x: number, y: number)
    {
        this.mX2 = x;
        this.mY2 = y;

		////////////////
		let x2 = x;
		let y2 = y

		if (this.headOffset != 0) 
		{
			let alpha = Math.atan((y - this.mY1) / (x - this.mX1));
			x2 = this.headOffset * Math.cos(alpha);
			y2 = this.headOffset * Math.sin(alpha);

			if (x > this.mX1) {
				x2 = -x2;
				y2 = -y2;
			}

			x2 = this.mX2 + x2;
			y2 = this.mY2 + y2;
		}

		///////////////
        let x1 = this.mX1;
        let y1 = this.mY1
        if (this.tailOffset != 0)
        {
            let alpha = Math.atan((y-y1) / (x - x1));            
            x1 = this.tailOffset * Math.cos(alpha);
			y1 = this.tailOffset * Math.sin(alpha);
			
            if (x < this.mX1)
            {
                x1 = -x1;
                y1 = -y1;
            }

            x1 += this.mX1;
            y1 += this.mY1
        }

        this.svg_base.setAttributeNS(null, 'd', `M${x1},${y1} ${x2},${y2}`);
        //this.svg_base.setAttributeNS(null, 'd', `M${this.mX1},${this.mY1} ${this.mX2},${this.mY2}`);

        if (this.onPositionChanged)
        {
            this.onPositionChanged.raiseEvent(['head point changed']);
        }
    }

    setTailStyle(style: string)
    {
        this.svg.setAttributeNS(null, 'marker-start', `url(#${style})`);
    }

    setHeadStyle(style: string)
    {
        this.svg.setAttributeNS(null, 'marker-end', `url(#${style})`);
    }

    setTextAlign(value: string)
    {
        this.mTextAlign = value;
    }

    setTextColor(value: string)
    {
        this.mTextColor = value;
        this.svg_text.style.fill = value;
    }
}