<ul id="menu">
	<li class="menu-item">Create an edge</li>
	<li class="menu-item">Delete</li>
	<li class="menu-item">Rename</li>
</ul>

<div style="min-width: 220px;">
	<div class= "box box-info">
		<div class="box-header ">
			<!--<h3 class="box-title "></h3> -->			
			<button mat-icon-button [matMenuTriggerFor]="menuAdd">
				<mat-icon>add_circle_outline</mat-icon>
				<span class="aligned-with-icon-relative">New</span>
			</button>
			
			<span style="padding-right: 20px;"></span>

			<button mat-icon-button (click)="btnCreateEdge()">
				<mat-icon>linear_scale</mat-icon>
				<span class="aligned-with-icon-relative" style="margin-left: -37px;">New Edge</span>
			</button>
			
			<span style="padding-right: 20px;"></span>
			
			<button mat-icon-button [matMenuTriggerFor]="menuDelete">
				<mat-icon>remove_circle_outline</mat-icon> 
				<span class="aligned-with-icon-relative" style="margin-left: -30px;">Delete</span>
			</button>

			<span style="padding-right: 20px;"></span>
			
			<button mat-icon-button [matMenuTriggerFor]="menuTraverse">
				<mat-icon>directions_run</mat-icon> 
				<span class="aligned-with-icon-relative" style="margin-left: -35px;">Traversal</span>
			</button>

			<span style="padding-right: 35px;"></span>

			<button mat-icon-button [matMenuTriggerFor]="menuSampleGraphs">
				<mat-icon>insights</mat-icon> 
				<span class="aligned-with-icon-relative" style="margin-left: -50px;">Sample Graphs</span>
			</button>

			<span style="padding-right: 35px;"></span>
			
			<!-- SUB MENU ITEMS -->
			<mat-menu #menuAdd="matMenu" class="mat-menu-panel-with-text-below">
				<button mat-menu-item (click)="this.openAddNodesForm()">
					<mat-icon>scatter_plot</mat-icon>
					<span>Nodes</span>
				</button>
				<button mat-menu-item (click)="this.openAddEdgesForm()">
					<mat-icon>linear_scale</mat-icon>
					<span>Edges</span>
				</button>
			</mat-menu>
			
			<mat-menu #menuDelete="matMenu" class="mat-menu-panel-with-text-below">
				<button mat-menu-item disabled>
					<mat-icon>scatter_plot</mat-icon>
					<span>Nodes</span>
				</button>
				<button mat-menu-item disabled>
					<mat-icon>linear_scale</mat-icon>
					<span>Edges</span>
				</button>
				<button mat-menu-item (click)="clearCanvas()">
					<mat-icon>clear</mat-icon>
					<span>Everything</span>
				</button>
			</mat-menu>

			<mat-menu #menuTraverse="matMenu" class="mat-menu-panel-with-text-below">
				<button mat-menu-item (click)="this.showNodePickerForTraversal('dfs')">
					<mat-icon>play_for_work</mat-icon>
					<span>Depth First Search (DFS)</span>
				</button>
				<button mat-menu-item (click)="this.showNodePickerForTraversal('bfs')">
					<mat-icon>open_with</mat-icon>
					<span>Breadth First Search (BFS)</span>
				</button>
			</mat-menu>

			<mat-menu #menuSampleGraphs="matMenu" class="mat-menu-panel-with-text-below">
				<button mat-menu-item (click)="this.generateSampleGraph1()">
					<span>Sample Graph 1</span>
				</button>
				<button mat-menu-item (click)="this.generateSampleGraph2()">
					<span>Sample Graph 2</span>
				</button>
				<button mat-menu-item (click)="this.generateSampleGraph3()">
					<span>Sample Graph 3</span>
				</button>
			</mat-menu>			
		</div>
		
		<div class="box-body">
			<svg
				id="canvasContainer"
				width="800" height="500"
				viewBox="0 0 800 500" 
				preserveAspectRatio="xMinYMin meet"
				xmlns="http://www.w3.org/2000/svg" >

				<defs>
					 <!-- arrowhead marker definition -->
				    <marker id="arrow" viewBox="0 0 10 10" refX="26" refY="5"
				        markerWidth="12" markerHeight="12"
						markerUnits="userSpaceOnUse"
						fill="#707070"
						orient="auto-start-reverse">
				      <path d="M 0 0 L 10 5 L 0 10 z" />
				    </marker>

					<marker id="arrow-for-guideline" viewBox="0 0 10 10" refX="8" refY="5"
				        markerWidth="12" markerHeight="12"
						markerUnits="userSpaceOnUse"
						fill="#707070"
						orient="auto-start-reverse">
				      <path d="M 0 0 L 10 5 L 0 10 z" />
				    </marker>

					<!-- simple dot marker definition -->
					<marker id="dot" viewBox="0 0 10 10" refX="5" refY="5"
						markerWidth="5" markerHeight="5" markerUnits="userSpaceOnUse"
						orient="auto-start-reverse">
						<circle cx="5" cy="5" r="5" fill="red" />
					</marker>
				</defs>
				<style>
					@keyframes traverseForwards
					{
						from { stroke-dashoffset: 988; }
						to { stroke-dashoffset: 0; }
					}

					@keyframes traverseBackwards 
					{
						from { stroke-dashoffset: 0; }
						to { stroke-dashoffset: 988; }
					}
				</style>

				<!-- TEST DRAWING CURVED EDGES -->
				<!--
				<circle cx="50" cy="50" r="20" fill="#0097A7" stroke="#18FFFF" stroke-width="1" />
				<path d="M 35 60, C 0 120 100 120, 65 60" stroke="#707070" stroke-width="1.5" fill="transparent" 
					marker-end="url(#arrow-for-guideline)" />

				<circle cx="200" cy="75" r="20" fill="transparent" stroke="#707070" stroke-width="1.5" />
				<circle cx="200" cy="50" r="20" fill="#0097A7" stroke="#18FFFF" stroke-width="1" 
					marker-end="url(#arrow)"/>
				
				<circle cx="400" cy="75" r="20" fill="transparent" stroke="#707070" stroke-width="1.5"  
					marker-end="url(#arrow-for-guideline)"/>
				-->
				<svg id="edges" width="800" height="500"></svg>
				<svg id="nodes" width="800" height="500"></svg>
			</svg>
		</div>
		
		<div class="box-footer">
			<button mat-raised-button (click)="btnTest1()">Test 1</button>
			<button mat-raised-button (click)="btnTest2()">Test 2</button>
			<button mat-raised-button (click)="btnTest3()">Test 3</button>
		</div>
	</div>
</div>
