import { Shape2DStyle } from './Shape2D.lib';

/*
    Nguyen, Nguyen

    January 04, 2019
*/

export interface ArrayVisualizerSnapShot
{
	elements: { value: any, color: Shape2DStyle }[];
	temp_elements: { value: any, color: Shape2DStyle }[];
	iterators: { id: number, label: string, index: number, visible: boolean }[];
	tags: { index: number, label: string, visible: boolean }[];
}

export class ArrayVisualizerSnapshotManager
{
	private currentSnapID: number;
	private snapshots: ArrayVisualizerSnapShot[];

	constructor(snapshot: ArrayVisualizerSnapShot)
	{
		this.currentSnapID = 0;
		this.snapshots = [JSON.parse(JSON.stringify(snapshot))];
		this.snapshots.push(JSON.parse(JSON.stringify(this.snapshots[this.currentSnapID])));
		//console.log(this.snapshots);
	}

	duplicate()
	{
		this.snapshots.push(JSON.parse(JSON.stringify(this.snapshots[this.currentSnapID])));
		//++this.currentSnapID;
	}

	applyChanging()
	{
		++this.currentSnapID;
		this.snapshots.push(JSON.parse(JSON.stringify(this.snapshots[this.currentSnapID])));
		return this.currentSnapID;
	}

	discardChanging()
	{
		if (this.currentSnapID < this.snapshots.length - 1)
		{
			this.snapshots.length == this.snapshots.length - 1;
			this.snapshots.push(JSON.parse(JSON.stringify(this.snapshots[this.currentSnapID])));
		}
	}

	moveElementToTemporaryArray(index: number, tempIndex: number)
	{
		//this.duplicate();

		this.snapshots[this.currentSnapID + 1].temp_elements[tempIndex] = this.snapshots[this.currentSnapID].elements[index];
		this.snapshots[this.currentSnapID + 1].elements[index] = null;

		return this.currentSnapID;
	}

	moveElementBackFromTemporaryArray(index: number, tempIndex: number)
	{
		//this.duplicate();

		this.snapshots[this.currentSnapID + 1].elements[index] = this.snapshots[this.currentSnapID].temp_elements[tempIndex];
		this.snapshots[this.currentSnapID + 1].temp_elements[tempIndex] = null;

		return this.currentSnapID;
	}

	setTemporaryElement(index: number, value: any, color?: Shape2DStyle)
	{
		//this.duplicate();
		if (value)
		{
			if (color)
			{
				this.snapshots[this.currentSnapID + 1].temp_elements[index] = { value, color };
			}
			else
			{
				// Be careful. 1 hour waisted here. The element at the index must NOT be null
				this.snapshots[this.currentSnapID + 1].temp_elements[index].value = value;
			}
		}
		else
		{
			this.snapshots[this.currentSnapID + 1].temp_elements[index] = null;
		}

		return this.currentSnapID;
	}

	setElement(index: number, value: any, color?: Shape2DStyle)
	{
		//this.duplicate();
		if (value)
		{
			if (color)
			{
				this.snapshots[this.currentSnapID + 1].elements[index] = { value, color };
			}
			else
			{
				// Be careful. 1 hour waisted here. The element at the index must NOT be null
				this.snapshots[this.currentSnapID + 1].elements[index].value = value;
			}
		}
		else
		{
			this.snapshots[this.currentSnapID + 1].elements[index] = null;
		}

		return this.currentSnapID;
	}

	setMultipleElements(indices: number[], values: any[], colors?: Shape2DStyle[])
	{
		//this.duplicate();
		let n = indices.length;

		for (let i = 0; i < n; ++i)
		{
			if (values[i])
			{
				if (colors)
				{
					this.snapshots[this.currentSnapID + 1].elements[indices[i]] = { value: values[i], color: colors[i] };
				}
				else
				{
					this.snapshots[this.currentSnapID + 1].elements[indices[i]].value = values[i];
				}
			}
			else
			{
				this.snapshots[this.currentSnapID + 1].elements[indices[i]] = null;
			}
		}

		return this.currentSnapID;
	}

	setIterator(id: number, index: number, visible?: boolean)
	{
		//this.duplicate();
		this.snapshots[this.currentSnapID + 1].iterators[id].index = index;
		if (visible != null)
		{
			this.snapshots[this.currentSnapID + 1].iterators[id].visible = visible;
		}
		return this.currentSnapID;
	}

	setMultipleIterators(ids: number[], indices: number[], visible?: boolean[])
	{
		//this.duplicate();
		let n = ids.length;

		for (let i = 0; i < n; ++i)
		{
			this.snapshots[this.currentSnapID + 1].iterators[ids[i]].index = indices[i];
			if (visible != null)
			{
				this.snapshots[this.currentSnapID + 1].iterators[ids[i]].visible = visible[i];
			}
		}

		return this.currentSnapID;
	}

	setTag(index: number, label: string, visible: boolean)
	{
		//this.duplicate();
		this.snapshots[this.currentSnapID + 1].tags[index] = { index, label, visible };

		return this.currentSnapID;
	}

	get snapID()
	{
		return this.currentSnapID;
	}

	getSnapshot(id: number)
	{
		if (id <= this.currentSnapID)
		{
			return this.snapshots[id];
		}
		throw new Error(`Version ${id} does not exist`);
	}

	getLastestSnapShot()
	{
		return this.snapshots[this.currentSnapID];
	}
}