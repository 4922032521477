import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, ViewChild } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatButton } from '@angular/material/button';
import { MatStepper } from '@angular/material/stepper';

export interface CreateEditEdgesResult
{
	sourceNodes: string[];
	targetNodes: string[];
}

@Component({
	selector: 'app-create-edit-edge',
	templateUrl: './create-edit-edge.component.html',
	styleUrls: ['./create-edit-edge.component.css'],
	providers: [
		{
			provide: STEPPER_GLOBAL_OPTIONS,
			useValue: { showError: true }
		}
	]
})

export class CreateEditEdgeComponent
{
	//@ViewChild('stepper') stepper: MatStepper;
	@ViewChild('btnOK') btnOK: MatButton;

	// Data
	public nodes: string[] = [];
	public result: CreateEditEdgesResult = { sourceNodes: [], targetNodes: [] };


	constructor(public _bottomSheetRef: MatBottomSheetRef<CreateEditEdgeComponent>)
	{
		for (let e of _bottomSheetRef.containerInstance.bottomSheetConfig.data) {
			this.nodes.push(e.getText());
		}
	}

	cancel()
	{
		this._bottomSheetRef.dismiss(null);
	}

	submit()
	{
		this._bottomSheetRef.dismiss(this.result);
	}
	select(group: 'tail' | 'head', node: string)
	{
		let elem = document.getElementById(group + '_' + node);
		elem.style.backgroundColor = (elem.style.backgroundColor == 'orange' ? '#0097A7' : 'orange')

		if (group == 'tail') {
			if(this.result.sourceNodes.find(e => e == node) == null)
			{
				this.result.sourceNodes.push(node);
			}
			else
			{
				const index = this.result.sourceNodes.indexOf(node, 0);
				this.result.sourceNodes.splice(index, 1);
			}
		}
		else {
			if(this.result.targetNodes.find(e => e == node) == null)
			{
				this.result.targetNodes.push(node);
			}
			else
			{
				const index = this.result.targetNodes.indexOf(node, 0);
				this.result.targetNodes.splice(index, 1);
			}		
		}

		// Enable or Disable OK Button
		if (this.result.sourceNodes.length > 0 && this.result.targetNodes.length > 0)
		{
			this.btnOK.disabled = false;
		}
		else
		{
			this.btnOK.disabled = true;
		}
	}
}