<h2>{{_bottomSheetRef.containerInstance.bottomSheetConfig.ariaLabel}}</h2>
<mat-vertical-stepper #stepper>
    <mat-step [hasError]="result.sourceNodes.length == 0"
        errorMessage="Source is required.">
        <ng-template matStepLabel>Step 1: Choose source nodes</ng-template>
        <div class="grid-7">
            <ng-container style="padding: 50px 50px 50px 50px;" *ngFor="let node of nodes; let i = index;">
                <button mat-mini-fab color="visualizer-object" id="tail_{{node}}"
                    (click)="select('tail', node)">{{node}}</button>
            </ng-container>
        </div>
    </mat-step>
    <mat-step #step2 [hasError]="step2.interacted && result.sourceNodes.length == 0" errorMessage="Target is required.">
        <ng-template matStepLabel>Step 2: Choose target nodes</ng-template>
        <div class="grid-7">
            <ng-container style="padding: 50px 50px 50px 50px;" *ngFor="let node of nodes; let i = index;">
                <button mat-mini-fab color="visualizer-object" id="head_{{node}}"
                    (click)="select('head', node)">{{node}}</button>
            </ng-container>
        </div>
    </mat-step>
</mat-vertical-stepper>

<br>
<br>
<button #btnOK mat-raised-button (click)="submit()" [disabled]="true" color="primary" style="width: 100%;">Create Edges</button>
<br>
<button mat-raised-button (click)="cancel()" color="" style="width: 100%;">Cancel</button>