import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-quick-sort2',
	templateUrl: './quick-sort2.component.html',
	styleUrls: ['./quick-sort2.component.css']
})
export class QuickSort2Component implements OnInit
{

	selectedCode: string = 'cpp';

	constructor() { }

	ngOnInit() { }

	/**
	* TabView Event handler.
	*/
	selectedTabChange(index)
	{
		switch (index)
		{
			case 0:
				this.selectedCode = 'cpp'
				break;
			case 1:
				this.selectedCode = 'python'
				break;
			default:
				this.selectedCode = ''
				break;
		}
	}
}
