import { Component, OnInit } from '@angular/core';
import { TreeVisualizer, TreeVisualizerConfig } from 'src/app/lib/TreeVisualizer.lib';

@Component({
    selector: 'nn-tree-visualizer',
    templateUrl: './tree-visualizer.component.html',
    styleUrls: ['./tree-visualizer.component.css']
})
export class TreeVisualizerComponent implements OnInit
{
    // Visualizer & ScriptPlayer
    private vs: TreeVisualizer;

    isPlaying = false;
    progress: number = 0;

    constructor() { }

    // Very very Important to clean up things
	ngOnDestroy()
	{
		// If autoPlayNext==true && the script is not cleared, 
		// 		the timer will keep running til the end of the script.
		//
		// Which means, this component will not be desctructed 
		//      if the script keeps playing.
		//this.scriptManager.clear();

		// From Angular 11, the canvas must be destroyed
		// when routing between pages that use the same element ID.
		document.getElementById('canvasContainer').remove();
    }

    ngOnInit()
    {
        // Instantiate a visualizer
        let config: TreeVisualizerConfig = {
            canvasID: 'nodes',
            edgeCanvasID: 'edges',
            marginTop: 50,
            marginLeft: 55,
            treeType: 'BST',
            nodeRadius: 20,
            horizontalSpacing: 10,
            verticalSpacing: 70,
            nodeColor: { fillColor: '#0097A7', strokeColor: '#18FFFF', strokeWidth: 1, textColor: 'white' },
            nodeAccentColor: { fillColor: '#E64A19', strokeColor: '#ffccbc', strokeWidth: 1, textColor: 'white' },
            nodeSelectionColor: { fillColor: '#795548', strokeColor: '#4e342e', strokeWidth: 1, textColor: 'white' },
            tagColor: { fillColor: 'transparent', strokeColor: 'transparent', strokeWidth: 1, textColor: '#212121' },
        };

        this.vs = new TreeVisualizer(config);
    }

    performMediaControlCommand($event: { command: string, args: any[] })
    {
        console.log($event.command);
        switch ($event.command)
        {
            case 'resizeCanvas':
                //this.vs.resizeCanvas();
                break;
            default:
                throw new Error("Method not implemented.");
        }
    }

    testButton1()
    {
        this.vs.perform('insert', [50]);
        this.vs.perform('insert', [30]);
        this.vs.perform('insert', [10]);
        this.vs.perform('insert', [40]);
    }

    testButton2()
    {
        this.vs.perform('insert', [100]);
        this.vs.perform('insert', [70]);
        this.vs.perform('insert', [55]);
        this.vs.perform('insert', [75]);
        this.vs.perform('insert', [80]);
    }

    testButton3()
    {
        let num = Math.ceil(Math.random() * 100);
        console.log(num)
        this.vs.perform('insert', [num]);
    }

    testButton4()
    {
        // this.vs.perform('test', null);
        // console.log(this.vs.maxDepth(this.vs.root));

        //console.log(this.vs.nodesNeedReArraging.map(e => e.data))
        this.vs.printInOrder();
    }

    testButton5()
    {
        console.log(this.vs.countLevelsToLeftmost(this.vs.root));
    }

    testButton6()
    {
        console.log(this.vs.countLevelsToRightmost(this.vs.root));
        this.vs.resizeCanvas();
    }

    btnInsert(elem: any)
    {
        this.vs.insert(elem);
    }
}
