import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'nn-small-box',
	template: `
		<div class="small-box {{cssBgColor}}">
			<div class="inner">
				<h3>{{message}}</h3>
				<p>{{title}}</p>
			</div>
			<div class="icon">
				<i class="material-icons md-dark md-48" style="display: inline-block">{{matIconName}}</i>
			</div>
			<a class="small-box-footer"></a>
		</div>
  `,
	styles: []
})

export class SmallBoxComponent implements OnInit
{
	@Input('title') title: string;
	@Input("message") message: string;
	@Input("cssBgColor") cssBgColor: string;
	@Input("matIconName") matIconName: string;

	constructor() { }

	ngOnInit()
	{
	}

}
