<div class="flex-container">
	<!-- ---------------------- Tab View - Code Tracer------------------------>
	<div style="min-width: 385px;">
		<mat-tab-group
			#tabLang
			(selectedTabChange)="selectedTabChange(tabLang.selectedIndex)"
		>
			<mat-tab label="Algorithm">
				<pre
					class="prettyprint lang-python"
					style="border: 0;"
				><code class="prettyprint"><span id="algorithm_stmt_001">Algorithm selectionSort(L):</span>
    Input: A list L storing >= 1 elements

<span id="algorithm_stmt_002">n <span style="font-size: large;">&#10229;</span> size of list</span>

<span id="algorithm_stmt_003">for i <span style="font-size: large;">&#10229;</span> 0 to n - 2 do</span>
    <span id="algorithm_stmt_004">minIndex <span style="font-size: large;">&#10229;</span> i;</span>
    <span id="algorithm_stmt_005">for j <span style="font-size: large;">&#10229;</span> i + 1 to n - 1 do</span>
        <span id="algorithm_stmt_006">if L[j]  &lt; L[minIndex] then</span> 
            <span id="algorithm_stmt_007">minIndex <span style="font-size: large;">&#10229;</span> j;</span>
    <span id="algorithm_stmt_010">swap L[i] and L[minIndex]</span></code></pre>
			</mat-tab>

			<mat-tab label="C++">
				<pre
					class="prettyprint lang-java"
					style="border: 0;"
				><code class="language-java"><span id="cpp_stmt_001">void selectionSort(int a[], int n)</span>
&#123;
    <span id="cpp_stmt_002">int minIndex;</span>
    
    <span id="cpp_stmt_003">for (int i = 0; i &lt; n - 1; ++i)</span>
    &#123;
        // Find the minimum element in unsorted array
        <span id="cpp_stmt_004">minIndex = i;</span>
        <span id="cpp_stmt_005">for (int j = i + 1; j &lt; n; ++j)</span>
        &#123;
            <span id="cpp_stmt_006">if (arr[j] &lt; arr[minIndex])</span>
            &#123;
                <span id="cpp_stmt_007">minIndex = j;</span>
            <span id="cpp_stmt_008">&#125;</span>
        <span id="cpp_stmt_009">&#125;</span>
        <span id="cpp_stmt_010">std::swap(arr[minIndex], arr[i]);</span>
    <span id="cpp_stmt_011">&#125;</span>
<span id="cpp_stmt_012">&#125;</span></code></pre>
			</mat-tab>
		</mat-tab-group>
	</div>

	<!-- ---------------------- Table - Analytics --------------------------->
	<div style="min-width: 220px;">
		<div class=" box box-primary">
			<div class="box-header ">
				<h3 class="box-title ">Things to remember</h3>

				<div class="box-tools ">
					&nbsp;
				</div>
			</div>

			<div class="box-body no-padding ">
				<table style="width: 100%; ">
					<tbody>
						<tr>
							<th style="width: 15px">#</th>
							<th>Time Complexity</th>
							<th style="width: 40px; text-align: center;">
								&nbsp;
							</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>Best Case</td>
							<td style=" text-align: center;">
								<span class="badge bg-red"
									>O(n<sup>2</sup>)</span
								>
							</td>
						</tr>
						<tr>
							<td>2.</td>
							<td>Worst Case</td>
							<td style=" text-align: center;">
								<span class="badge bg-red"
									>O(n<sup>2</sup>)</span
								>
							</td>
						</tr>
						<tr>
							<td>3.</td>
							<td>Average Case</td>
							<td style=" text-align: center;">
								<span class="badge bg-red"
									>O(n<sup>2</sup>)</span
								>
							</td>
						</tr>
						<tr>
							<td></td>
							<td>&nbsp;</td>
							<td></td>
						</tr>						
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>

<!-- ---------------------- Visualizer --------------------------->
<nn-inplace-algorithm-visualizer
	algorithm="SelectionSort"
	[selectedCode]="selectedCode"
	[statsValues]="[0, 0]"
	[statsUnits]="['comparisons', 'swaps']"
	[statsPrefixes]="['', '']"
	[statsSuffixes]="['', '']"
></nn-inplace-algorithm-visualizer>
