<div class="flex-container">
	<!-- ---------------------- Tab View - Code Tracer------------------------>
	<div style="min-width: 385px;">
		<mat-tab-group
			#tabLang
			(selectedTabChange)="selectedTabChange(tabLang.selectedIndex)"
		>
			<mat-tab label="C++" value="cpp">
				<div class="flex-container" style="margin-top: -20px;">
					<div>
						<pre
							class="prettyprint lang-cpp"
							style="border: 0;"
						><code class="prettyprint"><span id="cpp_f1_stmt_001">void quickSort(int arr[], int low, int high)</span>
&#123;
    <span id="cpp_f1_stmt_002">if (low &lt; high)</span>
    &#123;
	<span id="cpp_f1_stmt_003B">int p</span> = <span id="cpp_f1_stmt_003A">partition(arr, low, high);</span>
        <span id="cpp_f1_stmt_004">quickSort(arr, low, p - 1);</span>
        <span id="cpp_f1_stmt_005">quickSort(arr, p + 1, high);</span>
    &#125;
&#125;</code></pre>
					</div>
					<div>
						<pre
							class="prettyprint"
							style="border: 0;"
						><code class="prettyprint lang-cpp"><span id="cpp_f2_stmt_001">int partition (int arr[], int low, int high)</span>
&#123;
    <span id="cpp_f2_stmt_002">int pivot = arr[low], h = low + 1, k = high;</span>

    <span id="cpp_f2_stmt_003">while (h &lt;= k)</span>
    &#123;
        <span id="cpp_f2_stmt_004">while (h &lt;= k && arr[h] &lt;= pivot)</span>
            <span id="cpp_f2_stmt_005">++h</span>;
        <span id="cpp_f2_stmt_006">while (h &lt;= k && arr[k] >= pivot)</span>
            <span id="cpp_f2_stmt_007">--k;</span>
		
        <span id="cpp_f2_stmt_008">if (h &lt; k)</span>
            <span id="cpp_f2_stmt_009">std::swap(arr[h], arr[k]);</span>
    &#125;
    <span id="cpp_f2_stmt_010">std::swap(arr[k], low);</span>
    <span id="cpp_f2_stmt_011">return k;</span>
&#125;</code></pre>
					</div>
				</div>
			</mat-tab>

			<mat-tab label="Java">
				<h1>Hello World</h1>
				<p>System.out.println("hello world");</p>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>

<!-- ---------------------- Visualizer --------------------------->
<nn-inplace-algorithm-visualizer
	algorithm="QuickSort1"
	[selectedCode]="selectedCode"
	[statsValues] = "[0, 0]"
	[statsUnits]="['comparisons', 'swaps']"
	[statsPrefixes] = "['','']"
	[statsSuffixes] = "['','']"
></nn-inplace-algorithm-visualizer>

<br />

<!-- ---------------------- Table - Analytics --------------------------->
<div style="min-width: 220px;">
	<div class=" box box-primary">
		<div class="box-header ">
			<h3 class="box-title ">Things to remember</h3>

			<div class="box-tools ">
				&nbsp;
			</div>
		</div>

		<div class="box-body no-padding ">
			<table style="width: 100%; ">
				<tbody>
					<tr>
						<th style="width: 15px">#</th>
						<th>Time Complexity</th>
						<th style="width: 40px;">&nbsp;</th>
					</tr>
					<tr>
						<td>1.</td>
						<td>Best Case</td>
						<td>
							<span class="badge bg-yellow">O(n log n)</span>
						</td>
					</tr>
					<tr>
						<td>2.</td>
						<td>Worst Case</td>
						<td>
							<span class="badge bg-red">O(n<sup>2</sup>)</span>
						</td>
					</tr>
					<tr>
						<td>3.</td>
						<td>Average Case</td>
						<td>
							<span class="badge bg-yellow">O(n log n)</span>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>&nbsp;</td>
						<td></td>
					</tr>
					<tr>
						<th style="width: 10px">#</th>
						<th>Others</th>
						<th style="width: 40px"></th>
					</tr>
					<tr>
						<td>4.</td>
						<td>Number of Comparisons</td>
						<td>
							<span class="badge bg-aqua">n log n</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>