<mat-sidenav-container class="sidenav-container">
	<mat-sidenav
		#drawer
		class="sidenav"
		fixedInViewport
		[attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
		[mode]="(isHandset$ | async) ? 'over' : 'side'"
		[opened]="(isHandset$ | async) === false"
		(opened)="sidebarStateChanged(true)"
		(closed)="sidebarStateChanged(false)"
	>
		<mat-toolbar>Menu</mat-toolbar>
		<mat-nav-list>
			<a mat-list-item routerLink="/bubble-sort" routerLinkActive="active">Bubble Sort</a>
			<a mat-list-item routerLink="/bubble-sort-flag" routerLinkActive="active" >Bubble Sort - Flag</a>
			<a mat-list-item routerLink="/insertion-sort" routerLinkActive="active">Insertion Sort</a>
			<a mat-list-item routerLink="/quick-sort1" routerLinkActive="active">Quick Sort 1</a>
			<a mat-list-item routerLink="/quick-sort2" routerLinkActive="active">Quick Sort 2</a>
			<a mat-list-item routerLink="/selection-sort" routerLinkActive="active">Selection Sort</a>
			<a mat-list-item routerLink="/binary-search-tree" routerLinkActive="active">Binary Search Tree</a>
			<a mat-list-item routerLink="/directed-graph" routerLinkActive="active">Directed Graph</a>
			<a mat-list-item routerLink="/undirected-graph" routerLinkActive="active">Undirected Graph</a>

		</mat-nav-list>
	</mat-sidenav>
	<mat-sidenav-content>
		<mat-toolbar color="primary" style="position: sticky">
			<button
				type="button"
				aria-label="Toggle sidenav"
				mat-icon-button
				(click)="drawer.toggle()"
				*ngIf="isHandset$"
			>
				<mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
			</button>
			<span>Learn CS</span>
			<span class="spacer"></span>
			<div id="ToolBarButtonArea"></div>
		</mat-toolbar>
		<!-- Add Content Here -->
		<div class="content">
			<ng-content></ng-content>
		</div>
		<!--
		<div>
			icons8.com
		</div>
		 -->
	</mat-sidenav-content>
</mat-sidenav-container>
