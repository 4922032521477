import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
	selector: 'app-add-nodes',
	templateUrl: './add-nodes.component.html',
	styleUrls: ['./add-nodes.component.css']
})
export class AddNodesForm {

	constructor(private _bottomSheetRef: MatBottomSheetRef<AddNodesForm>) { }	

	cancel()
	{
		this._bottomSheetRef.dismiss({nodes: [], positions: []});
	}
	
	createNodes(nodes: string, positions: string) 
	{		
		this._bottomSheetRef.dismiss({nodes: nodes, positions: positions});
	}
}
