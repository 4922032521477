<div class="flex-container">
	<!-- ---------------------- Tab View - Code Tracer------------------------>
	<div style="min-width: 420px;">
		<div class="tabs">
			<input
				class="input"
				name="tabs"
				type="radio"
				id="tab-0"
				value="algorithm"
				(click)="tabPageChanged()"
				checked="checked"
			/><label class="label" for="tab-0">Algorithm</label>
			<div class="panel" style="height: 275px;">
				<pre
					class="prettyprint lang-python"
					style="border: 0;"
				><code class="prettyprint"><span id="algorithm_stmt_001">Algorithm bubbleSort(L):</span>
    Input: A list L storing >= 1 elements

<span id="">n <span style="font-size: large;">&#10229;</span> size of list</span>

<span id="algorithm_stmt_002">for i <span style="font-size: large;">&#10229;</span> 0 to n - 2 do</span>
    <span id="algorithm_stmt_003">for j <span style="font-size: large;">&#10229;</span> 0 to n - i - 2 do</span>
        <span id="algorithm_stmt_004">if L[j]  &lt; L[j + 1] then</span>
    		<span id="algorithm_stmt_005">swap L[i] and L[j + 1]</span></code></pre>
			</div>

			<input
				class="input"
				name="tabs"
				type="radio"
				id="tab-1"
				value="cpp"
				(click)="tabPageChanged()"
			/>
			<label class="label" for="tab-1">C++</label>
			<div class="panel" style="height: 275px;">
				<pre
					class="prettyprint"
					style="border: 0;"
				><code class="prettyprint"><span id="cpp_stmt_001">void bubbleSort(int arr[], int n)</span>
&#123;
    <span id="cpp_stmt_002">for (int i = 0; i &lt; n - 1; i++)</span>
    &#123;
        <span id="cpp_stmt_003">for (int j = 0; j &lt; n - i - 1; j++)</span>
        &#123;
            <span id="cpp_stmt_004">if (arr[j] &gt; arr[j+1])</span>
            &#123;
                <span id="cpp_stmt_005">std::swap(arr[j], arr[j + 1]);</span>
            <span id="cpp_stmt_006">&#125;</span>
        <span id="cpp_stmt_007">&#125;</span>
    <span id="cpp_stmt_008">&#125;</span>
<span id="cpp_stmt_009">&#125;</span></code></pre>
			</div>
			<input
				class="input"
				name="tabs"
				type="radio"
				id="tab-2"
				value="python"
				(click)="tabPageChanged()"
			/>
			<label class="label" for="tab-2">Python</label>
			<div class="panel" style="height: 275px;">
				<pre
					class="prettyprint"
					style="border: 0;"
				><code class="prettyprint"><span id="python_stmt_001">def bubbleSort(arr):</span>
    n = len(arr)
        
    <span id="python_stmt_002">for i in range(n):</span>        
        <span id="python_stmt_003">for j in range(0, n-i-1):</span>                
            <span id="python_stmt_004">if arr[j] > arr[j+1] :</span>
                <span id="python_stmt_005">arr[j], arr[j+1] = arr[j+1], arr[j]</span>
                </code>
            </pre>
			</div>
			<input
				class="input"
				name="tabs"
				type="radio"
				id="tab-3"
				value="java"
				(click)="tabPageChanged()"
			/><label class="label" for="tab-3">Java</label>
			<div class="panel" style="height: 275px;">
				<h1>Hello World</h1>
				<p>System.out.println("hello world");</p>
			</div>
		</div>
	</div>

	<!-- ---------------------- Table - Analytics --------------------------->
	<div style="min-width: 220px;">
		<div class=" box box-primary">
			<div class="box-header ">
				<h3 class="box-title ">Things to remember</h3>

				<div class="box-tools ">
					&nbsp;
				</div>
			</div>

			<div class="box-body no-padding ">
				<table style="width: 100%; ">
					<tbody>
						<tr>
							<th style="width: 15px">#</th>
							<th>Time Complexity</th>
							<th style="width: 40px;">&nbsp;</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>Best Case</td>
							<td>
								<span class="badge bg-red"
									>O(n<sup>2</sup>)</span
								>
							</td>
						</tr>
						<tr>
							<td>2.</td>
							<td>Worst Case</td>
							<td>
								<span class="badge bg-red"
									>O(n<sup>2</sup>)</span
								>
							</td>
						</tr>
						<tr>
							<td>3.</td>
							<td>Average Case</td>
							<td>
								<span class="badge bg-red"
									>O(n<sup>2</sup>)</span
								>
							</td>
						</tr>
						<tr>
							<td></td>
							<td>&nbsp;</td>
							<td></td>
						</tr>
						<tr>
							<th style="width: 10px">#</th>
							<th>Others</th>
							<th style="width: 40px"></th>
						</tr>
						<tr>
							<td>4.</td>
							<td>Number of Comparisons</td>
							<td>
								<span class="badge bg-aqua">n(n-1)/2</span>
							</td>
						</tr>
						<tr>
							<td>5.</td>
							<td>
								When to use <b><i>Bubble Sort</i></b>
							</td>
							<td>
								<span class="badge bg-aqua">never ever</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>

<!-- ---------------------- Visualizer --------------------------->
<nn-inplace-algorithm-visualizer
	#vsRef
	algorithm="BubbleSort"
	[selectedCode]="selectedCode"
	[statsValues] = "[0, 0]"
	[statsUnits]="['comparisons', 'swaps']"
	[statsPrefixes] = "['','']"
	[statsSuffixes] = "['','']"
></nn-inplace-algorithm-visualizer>
