import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-bubble-sort',
	templateUrl: './bubble-sort.component.html',
	styleUrls: ['./bubble-sort.component.css']
})

export class BubbleSortComponent implements OnInit
{
	selectedCode = 'algorithm';

	constructor() { }

	ngOnInit() { }

	/**
	 * TabView Event handler.
	 */
	tabPageChanged()
	{
		this.selectedCode = document.querySelector('input[name="tabs"]:checked').getAttribute('value');
	}
}