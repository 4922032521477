/*
    Nguyen, Nguyen

    December 22, 2019
*/

import { AnimationController } from './AnimationController.lib';
import { EventHandler } from './EventHandler.lib';
import { Rectangle } from './Rectangle.lib';
import { Shape2D, Shape2DStyle } from './Shape2D.lib';
import { Performable } from './ScriptManager.lib';
import { ArrayVisualizerSnapShot } from './ArrayVisualizerSnapshotManager.lib';

export interface Array1DVisualizerConfig
{
    readonly canvasID: string; // The ID of a canvas to draw on
    marginTop: number;
    marginLeft: number;
    cellWidth: number;
    cellHeight: number;
    cellSpacing: number;
    iteratorHeight: number;
    iteratorWidth: number;
    showGrid: boolean;
    cellColor: Shape2DStyle;
    cellAccentColor: Shape2DStyle;
    cellSelectionColor: Shape2DStyle;
    iteratorColor: Shape2DStyle;
    tagColor: Shape2DStyle;
}

class Iterator extends Rectangle
{
    private mCurrentValue;
    private mPreviousValue;

    constructor(label: string, index: number, x: number, y: number, width: number, height: number, color: Shape2DStyle)
    {
        super(x, y, width, height, label, color);
        this.mCurrentValue = index;
        this.mPreviousValue = index;
    }

    getIndex()
    {
        return this.mCurrentValue;
    }

    getPreviousIndex()
    {
        return this.mPreviousValue;
    }

    setIndex(newValue: number)
    {
        this.mPreviousValue = this.mCurrentValue;
        this.mCurrentValue = newValue;
    }
}

export class Array1DVisualizer implements Performable
{
    /***** Config *****/
    readonly config: Array1DVisualizerConfig;

    // Canvas / context
    private canvas: any; // The canvas to draw on
    public data: any[]; // Store data
    private numOfElems: number;
    private elements: Shape2D[]; // Array elements
    private temp_data: any[]; // Temporary Array
    private temp_elements: Shape2D[]; // Temporary elements
    private iters: Iterator[];
    private tags: Shape2D[]; // The tags shown in the area below the array

    // Animation Controller
    animCtrl: AnimationController;

    // Event
    onAnimationEnd: EventHandler;

    // Lock
    locked = false;

    // Playing speed
    private speedRatio: number = 1;

    constructor(values: any[], config: Array1DVisualizerConfig)
    {
        this.config = config;
        this.canvas = document.getElementById(config.canvasID);

        if (this.canvas == null)
        {
            console.log(`Canvas ${config.canvasID} not found.`);
        }
        else
        {
            // Init
            this.onAnimationEnd = new EventHandler();
            this.animCtrl = new AnimationController();

            this.data = [];
            this.numOfElems = 0;
            this.elements = [];
            this.temp_data = [];
            this.temp_elements = [];
            this.iters = [];
            this.tags = [];

            if (values.length > 0)
            {
                this.createElements(values);
                this.resizeCanvas();
            }

            // Canvas Resize event
            window.addEventListener('resize', this.resizeCanvas.bind(this), false);
            window.addEventListener('orientationchange', this.resizeCanvas.bind(this), false);
        }
    }

    /**
     * Perform an action on the visualizer
     * @param command   A command of an action to perform
     * @param args[]    An array of arguments
     * 
     * Example of swapping the elements 2nd and 4th:
     * 
     * perform("swap", [2, 4])
     */
    perform(command: string, args: any[]): void
    {
        if (!this.locked)
        {
            switch (command)
            {
                case "setIterator": // Will not raise any event
                    this.setIterator(args[0], args[1], args[2]);
                    break;

                case "moveIterator": // will raise onAnimationEnd Event when complete
                    this.moveIterator(args[0], args[1]);
                    break;

                case "swap": // will raise onAnimationEnd Event when complete
                    this.swap(args[0], args[1]);
                    break;

                case "shiftLeft": // will raise onAnimationEnd Event when complete
                    this.moveElementHorizontal(args[0], args[0] - 1);
                    break;

                case "shiftRight": // will raise onAnimationEnd Event when complete
                    this.moveElementHorizontal(args[0], args[0] + 1);
                    break;

                case "moveElement": // will raise onAnimationEnd Event when complete
                    this.moveElementHorizontal(args[0], args[1]);
                    break;

                case "moveElementToTemporaryArray": // will raise onAnimationEnd Event when complete
                    this.moveElementToTemporaryArray(args[0]);
                    break;

                case "moveElementBackFromTemporaryArray": // will raise onAnimationEnd Event when complete
                    this.moveElementBackFromTemporaryArray(args[0], args[1]);
                    break;

                case "setColor": // Will not raise any event
                    this.setColor(args[0], args[1]);
                    break;

                case "setColorForAll":
                    this.setColorForAll(args[0]);
                    break;

                case "toggleElementVisibility":
                    this.toggleElementVisibility(args[0]);
                    break;

                case "toggleIteratorVisibility":
                    this.toggleIteratorVisibility(args[0]);
                    break;

                case "toggleTagVisibility":
                    this.toggleTagVisibility(args[0]);
                    break;

                case "setTagVisibility": // Will not raise any event
                    this.setTagVisibility(args[0], args[1]);
                    break;

                default:
                    throw new Error("Method not implemented.");
            }
        }
    }

    restore(snapshot: ArrayVisualizerSnapShot)
    {
        this.clear();

        let n = snapshot.elements.length;

        // Create Elements
        for (let i = 0; i < n; ++i)
        {
            if (snapshot.elements[i])
            {
                this.insertBack(snapshot.elements[i].value, snapshot.elements[i].color);
            }
            else
            {
                this.data.push(null);
                this.elements.push(null);
                ++this.numOfElems;
            }

        }

        // Create Temporary Elements
        // TODO: Finish this function ASAP
        this.updateTemporaryElements(snapshot.temp_elements);

        // Create Iterators
        snapshot.iterators.forEach(e => this.createIterator(e.label, e.index, e.visible))

        // Create Tags
        snapshot.tags.forEach(e => this.setTagVisibility(e.index, e.visible));
    }

    getSnapShot()
    {
        let snap: ArrayVisualizerSnapShot = {
            elements: [],
            temp_elements: [],
            iterators: [],
            tags: []
        };

        let n = this.elements.length;
        for (let i = 0; i < n; ++i)
        {
            snap.elements.push({ value: this.data[i], color: this.elements[i].getColor() });

            if (this.temp_elements[i])
            {
                snap.temp_elements.push({ value: this.temp_elements[i].getText(), color: this.temp_elements[i].getColor() });
            }
            else
            {
                snap.temp_elements.push(null);
            }
        }

        n = this.tags.length;
        for (let i = 0; i < n; ++i)
        {
            snap.tags.push({ index: i, label: this.tags[i].getText(), visible: this.tags[i].isVisible() });
        }

        n = this.iters.length;
        for (let i = 0; i < n; ++i)
        {
            snap.iterators.push({
                id: i,
                label: this.iters[i].getText(),
                index: this.iters[i].getIndex(),
                visible: this.iters[i].isVisible()
            });
        }

        return snap;
    }

    /****************************************/
    /*             Public Methods           */
    /****************************************/
    /**
     * Return the value at the given index
     * @param index 
     */
    at(index: number)
    {
        let id = this.elements[index].getAttribute('id');
        let textElement = this.elements[index].getElementById(`${id}_text`);
        return textElement.innerHTML;
    }

    /**
     * Return the number of elements
     */
    getLength()
    {
        return this.elements.length;
    }

    getData()
    {
        return this.data;
    }

    /**
     * Clear the whole array
     */
    clear()
    {
        this.data.length = 0;
        this.numOfElems = 0;
        this.elements.length = 0;
        this.iters.length = 0;
        this.tags.length = 0;
        this.canvas.innerHTML = "";
    }

    /**
     * Return the number of iterators
     */
    getNumberOfIters()
    {
        return this.iters.length;
    }

    get NumberOfTags()
    {
        return this.tags.length;
    }

    advanceIterator(iter: any, n: number)
    {

    }

    /**
     * Create an iterator
     * @param label     The label to display
     * @param index     Initial index
     */
    createIterator(label: string, index: number, visible: boolean)
    {
        if (this.elements.length > 0)
        {
            let padding_bottom = 5;
            let x: number;
            let y = this.config.marginTop - this.config.iteratorHeight - padding_bottom;

            if (index > -1 && index < this.elements.length)
            {
                x = this.config.marginLeft + index * (this.config.cellWidth + this.config.cellSpacing) + this.iters.length * this.config.iteratorWidth;
            }
            else if (index >= this.elements.length)
            {
                x = this.config.marginLeft + (this.elements.length - 1) * (this.config.cellWidth + this.config.cellSpacing) + this.config.cellWidth + this.iters.length * this.config.iteratorWidth;
            }
            else
            {
                x = this.config.marginLeft + 1 * (this.config.cellWidth + this.config.cellSpacing) - this.config.cellWidth - this.config.cellSpacing;
            }

            let iter = new Iterator(label, index, x, y, this.config.iteratorWidth, this.config.iteratorHeight, this.config.iteratorColor);
            iter.setOpacity((visible ? 1 : 0));
            this.canvas.appendChild(iter.getSVG());
            this.iters.push(iter);
        }
    }

    setIterator(iterID: number, toIndex: number, visible?: boolean)
    {
        // Check if the iterator exists
        if (this.iters != null && iterID < this.iters.length)
        {
            let newX: number;

            this.iters[iterID].setIndex(toIndex);
            newX = this.config.marginLeft + toIndex * (this.config.cellWidth + this.config.cellSpacing) + iterID * this.config.iteratorWidth;

            this.iters[iterID].setX(newX);

            if (visible)
            {
                this.iters[iterID].setOpacity(1);
            }
        }
    }
    /**
     * Move an iterator to a given index, or move the iterator back and forward between 2 given indexes.
     * 
     * If the current index == toIndex, the iterator will get back to the fromIndex.
     * @param iterID        The ID of the iterator to move
     * @param toIndex       The position to move to
     * @param fromIndex     Optional. The current position (before moving).
     */
    moveIterator(iterID: number, toIndex: number)
    {
        // Check if the iterator exists
        if (this.iters != null && iterID < this.iters.length)
        {
            this.locked = true; // Lock

            let newX: number;

            this.iters[iterID].setIndex(toIndex);
            newX = this.config.marginLeft + toIndex * (this.config.cellWidth + this.config.cellSpacing) + iterID * this.config.iteratorWidth;

            // Motion a to b
            this.animCtrl.addLinearMotion(this.iters[iterID], [
                { x: newX, y: this.iters[iterID].getY(), duration: 250 * this.speedRatio },
            ]);

            // Add an event handler
            this.animCtrl.onStop.addHandler(() =>
            {
                if (this.onAnimationEnd)
                {
                    this.locked = false;
                    this.onAnimationEnd.raiseEvent([`moveIterator(${iterID}, ${toIndex})`]);
                    this.animCtrl.onStop.removeAllHandlers();
                }
            });
            this.animCtrl.start();
        }
        else
        {
            if (this.onAnimationEnd)
            {
                this.onAnimationEnd.raiseEvent([`Iterators[${iterID}] not found`]);
            }
        }
    }

    createTag(label: string, index: number, visible: boolean)
    {
        if (index > -1 && index < this.elements.length)
        {
            let padding_top = 15;
            let x = this.elements[index].getX();
            let y = this.elements[0].getY() + this.config.iteratorHeight + padding_top;

            let rect = new Rectangle(x, y, this.config.cellWidth, this.config.iteratorHeight, label, this.config.tagColor);
            rect.setOpacity((visible ? 1 : 0));
            this.canvas.appendChild(rect.getSVG());
            this.tags.push(rect);
        }
    }

    /**
     * Remove an element at the given index
     * @param index     The index of the element to remove
     */
    removeAt(index: number) // Remove an element at an index
    {

    }

    /**
     * Returns the playing speed.
     */
    getSpeedRatio()
    {
        return this.speedRatio;
    }

    /**
     * Set the playing speed
     * @param value     Number from 0-100.
     */
    setSpeed(value: number)
    {
        this.speedRatio = 50 / value;
    }

    setColor(index: number, color: Shape2DStyle)
    {

        if (color.fillColor)
        {
            this.elements[index].setFillColor(color.fillColor);
        }

        if (color.strokeColor)
        {
            this.elements[index].setStrokeColor(color.strokeColor);
        }

        if (color.textColor)
        {
            this.elements[index].setTextColor(color.textColor);
        }
    }

    /**
     * Set the colors for all elements
     * @param color 
     */
    setColorForAll(color: Shape2DStyle)
    {
        this.locked = true;
        this.elements.forEach(e =>
        {
            if (color.fillColor)
            {
                e.setFillColor(color.fillColor);
            }

            if (color.strokeColor)
            {
                e.setStrokeColor(color.strokeColor);
            }

            if (color.textColor)
            {
                e.setTextColor(color.textColor);
            }
        });

        if (this.onAnimationEnd)
        {
            this.onAnimationEnd.raiseEvent([`setColorForAll(${color})`]);
        }

        this.locked = false;
    }

    /**
     * Swap elements
     * @param a The index of the element to swap
     * @param b The index of the element to swap
     */
    swap(a: number, b: number)
    {
        // Case 1: The indices are the same. No swap
        if (a == b)
        {
            if (this.onAnimationEnd)
            {
                this.onAnimationEnd.raiseEvent([`swap(${a}, ${b})`]);
                this.animCtrl.onStop.removeAllHandlers();
            }
        }
        // Case 2: The indices are diffrent. Then swap
        else
        {
            this.locked = true;

            // Get current position of element a
            let a_x = this.elements[a].getX();
            let a_y = this.elements[a].getY();

            // Get current position of element b
            let b_x = this.elements[b].getX()
            let b_y = this.elements[b].getY();

            // Motion a to b
            this.animCtrl.addLinearMotion(this.elements[a], [
                { x: a_x, y: a_y + this.config.cellHeight + 10, duration: 250 * this.speedRatio },
                { x: b_x, y: a_y + this.config.cellHeight + 10, duration: 250 * this.speedRatio },
                { x: b_x, y: b_y, duration: 250 * this.speedRatio },
            ]);

            // Motion b to a
            this.animCtrl.addLinearMotion(this.elements[b], [
                { x: b_x, y: b_y + this.config.cellHeight + 10, duration: 250 * this.speedRatio },
                { x: a_x, y: b_y + this.config.cellHeight + 10, duration: 250 * this.speedRatio },
                { x: a_x, y: a_y, duration: 250 * this.speedRatio },
            ]);

            // Event handler
            this.animCtrl.onStop.addHandler(() =>
            {
                this.locked = false;

                if (this.onAnimationEnd)
                {
                    this.onAnimationEnd.raiseEvent([`swap(${a}, ${b})`]);
                    this.animCtrl.onStop.removeAllHandlers();
                }
            });

            // Start moving
            this.animCtrl.start();

            // Swap the real data
            [this.data[a], this.data[b]] = [this.data[b], this.data[a]];
            [this.elements[a], this.elements[b]] = [this.elements[b], this.elements[a]];
        }
    }

    moveElementHorizontal(index: number, newIndex: number)
    {
        this.locked = true;

        let new_x = this.config.marginLeft + newIndex * (this.config.cellWidth + this.config.cellSpacing);

        // Motion a to b
        this.animCtrl.addLinearMotion(this.elements[index], [
            { x: new_x, y: this.elements[index].getY(), duration: 250 * this.speedRatio }
        ]);

        // Event handler
        this.animCtrl.onStop.addHandler(() =>
        {
            this.locked = false;

            if (this.onAnimationEnd)
            {
                this.onAnimationEnd.raiseEvent([`moveElementHorizontal(${index}, ${newIndex})`]);
                this.animCtrl.onStop.removeAllHandlers();
            }
        });

        // Start moving
        this.animCtrl.start();

        // Move the real data
        this.data[newIndex] = this.data[index];
        this.elements[newIndex] = this.elements[index];
        this.data[index] = null;
        this.elements[index] = null;
    }

    moveElementToTemporaryArray(index: number) // Should be type of int
    {
        this.locked = true;

        let new_y = this.config.marginTop + this.config.cellHeight + 10;

        // Motion
        this.animCtrl.addLinearMotion(this.elements[index], [
            { x: this.elements[index].getX(), y: new_y, duration: 250 * this.speedRatio }
        ]);

        // Event handler
        this.animCtrl.onStop.addHandler(() =>
        {
            this.locked = false;

            if (this.onAnimationEnd)
            {
                this.onAnimationEnd.raiseEvent([`moveElementToTemporaryArray(${index})`]);
                this.animCtrl.onStop.removeAllHandlers();
            }
        });

        // Start moving
        this.animCtrl.start();

        // Update the real data
        this.temp_data[index] = this.data[index];
        this.temp_elements[index] = this.elements[index];

        this.data[index] = null;
        this.elements[index] = null;
    }

    moveElementBackFromTemporaryArray(tempIndex: number, mainIndex: number) // Should be type of int
    {
        this.locked = true;

        let new_x = this.config.marginLeft + mainIndex * (this.config.cellWidth + this.config.cellSpacing);
        let new_y = this.config.marginTop;

        // Motion
        this.animCtrl.addLinearMotion(this.temp_elements[tempIndex], [
            { x: new_x, y: this.config.marginTop + this.config.cellHeight + 10, duration: 250 * this.speedRatio },
            { x: new_x, y: new_y, duration: 250 * this.speedRatio }
        ]);

        // Add an event handler
        this.animCtrl.onStop.addHandler(() =>
        {
            this.locked = false;

            if (this.onAnimationEnd)
            {
                this.onAnimationEnd.raiseEvent([`moveElementBackFromTemporaryArray(${tempIndex}, ${mainIndex})`]);
                this.animCtrl.onStop.removeAllHandlers();
            }
        });

        // Start moving
        this.animCtrl.start();

        // Update the real data
        this.data[mainIndex] = this.temp_data[tempIndex];
        this.elements[mainIndex] = this.temp_elements[tempIndex];

        this.temp_data[tempIndex] = null;
        this.temp_elements[tempIndex] = null;
    }

    toggleElementVisibility(elementIndex: number)
    {
        if (elementIndex < this.elements.length)
        {
            this.locked = true;
            this.elements[elementIndex].toggleVisibility();
            if (this.onAnimationEnd)
            {
                this.locked = false;
                this.onAnimationEnd.raiseEvent([`toggleElementVisibility(${elementIndex})`]);
            }
        }
        else
        {
            if (this.onAnimationEnd)
            {
                this.onAnimationEnd.raiseEvent([`Elements[${elementIndex}] not found`]);
            }
        }
    }

    toggleIteratorVisibility(iteratorIndex: number)
    {
        if (iteratorIndex < this.iters.length)
        {
            this.locked = true;
            this.iters[iteratorIndex].toggleVisibility();
            if (this.onAnimationEnd)
            {

                this.locked = false;
                this.onAnimationEnd.raiseEvent([`toggleIteratorVisibility(${iteratorIndex})`]);
            }
        }
        else
        {
            if (this.onAnimationEnd)
            {
                this.onAnimationEnd.raiseEvent([`Iterators[${iteratorIndex}] not found`]);
            }
        }
    }

    toggleTagVisibility(tagIndex: number)
    {
        if (tagIndex < this.tags.length)
        {
            this.locked = true;
            this.tags[tagIndex].toggleVisibility();
            if (this.onAnimationEnd)
            {
                this.locked = false;
                this.onAnimationEnd.raiseEvent([`toggleTagVisibility(${tagIndex})`]);
            }
        }
        else
        {
            if (this.onAnimationEnd)
            {
                this.onAnimationEnd.raiseEvent([`Tags[${tagIndex}] not found`]);
            }
        }
    }

    setTagVisibility(tagIndex: number, visible: boolean)
    {
        if (tagIndex < this.tags.length)
        {
            this.tags[tagIndex].setOpacity(visible ? 1 : 0);
        }
    }

    insertBack(elem: any, color: Shape2DStyle)
    {
        let currentX = this.config.marginLeft + this.numOfElems * (this.config.cellWidth + this.config.cellSpacing);

        this.data.push(elem);
        this.temp_data.push(null);

        let rect = new Rectangle(currentX, this.config.marginTop, this.config.cellWidth, this.config.cellHeight, elem, color);
        this.canvas.appendChild(rect.getSVG());

        this.elements.push(rect);
        this.temp_elements.push(null);

        ++this.numOfElems;
    }

    createElements(arr: any[], colors?: Shape2DStyle[])
    {
        // Clear the visualizer
        this.clear();

        this.numOfElems = arr.length;
        let currentX = this.config.marginLeft;

        if (colors)
        {
            for (let i = 0; i < this.numOfElems; ++i)
            {
                this.data.push(arr[i]);
                this.temp_data.push(null);

                let rect = new Rectangle(currentX, this.config.marginTop, this.config.cellWidth, this.config.cellHeight, arr[i], colors[i]);
                this.canvas.appendChild(rect.getSVG());

                this.elements.push(rect);
                this.temp_elements.push(null);

                currentX += this.config.cellWidth + this.config.cellSpacing;
            }
        }
        else
        {
            for (let i = 0; i < this.numOfElems; ++i)
            {
                this.data.push(arr[i]);
                this.temp_data.push(null);

                let rect = new Rectangle(currentX, this.config.marginTop, this.config.cellWidth, this.config.cellHeight, arr[i], this.config.cellColor);
                this.canvas.appendChild(rect.getSVG());

                this.elements.push(rect);
                this.temp_elements.push(null);

                currentX += this.config.cellWidth + this.config.cellSpacing;
            }
        }
    }

    // This function must be call after the function createElements.
    // To avoid temp_elements from being replaced with 'null'
    updateTemporaryElements(elements: { value: any, color: Shape2DStyle }[])
    {
        let currentX = this.config.marginLeft;

        for (let i = 0; i < this.numOfElems; ++i)
        {
            if (elements[i])
            {
                let rect = new Rectangle(currentX, this.config.marginTop + this.config.cellHeight + 10, this.config.cellWidth, this.config.cellHeight, elements[i].value, elements[i].color);
                this.canvas.appendChild(rect.getSVG());

                this.temp_elements[i] = rect;
                this.temp_data[i] = elements[i].value;
            }

            currentX += this.config.cellWidth + this.config.cellSpacing;
        }
    }

    /**
     * Generate random numbers
     * @param n The quantiy of numbers to generate
     */
    createRandomNumbers(n: number)
    {
        let arr = [];
        for (let i = 0; i < n; ++i)
        {
            arr.push(Math.floor(Math.random() * 100) + 1);
        }

        this.createElements(arr);
    }

    /**
     * Create numbers from a string
     * @param {string} str A string of numbers separated by a comma
     * 
     * Example: "-1, -3, 42, 101, 16, -17, 72, -31, 9"
     */
    createNumbersFromString(str: any)
    {
        let arr = [];
        let temp = str.replace(/\s/g, '').split(',', 15);
        temp.forEach(e =>
        {
            if (!isNaN(e))
            {
                arr.push(parseInt(e));
            }
        });

        this.createElements(arr);
    }

    /**
     * Resize to fit the content
     */
    resizeCanvas()
    {
        let svg = document.querySelector(`#canvasContainer`) as SVGSVGElement;
        let vbBase = svg.viewBox.baseVal;
        let box = svg.getBBox();

        // scale = Size of Objects / Size of Canvas
        let scale = (box.width + 50) / svg.width.baseVal.value;

        if (scale > 0.9) // No need to scale up.
        {
            vbBase.height = (svg.height.baseVal.value) * scale;
        }

        vbBase.x = - (svg.width.baseVal.value - (this.config.marginLeft * 4) - (box.width / (scale > 1 ? scale : 1))) / 4;
        vbBase.y = (svg.height.baseVal.value - vbBase.height) / 2;

		/*  Waisted 2 hours here.
			Changing the properties of the viewBox directly is much better 
			than using setAttribute() method.

			Why not using setAttribute() method.
				1. It is slow.
				2. If the method were called in the ngOnInit(), the SVG will not render.
		*/
		/*
		let vb_w = svg.width.baseVal.value * scale;
		let vb_h = svg.height.baseVal.value * scale;

		let vb_x = - (svg.width.baseVal.value - (box.width / scale)) / 4;
		let vb_y = (svg.height.baseVal.value - vbBase.height) / 2;
		svg.setAttribute('viewBox', `${vb_x} ${vb_y} ${vb_w} ${vb_h}`);
		
		console.log(`objects.w: ${box.width / scale}, canvas.w: ${svg.width.baseVal.value}`);
		*/
    }
}