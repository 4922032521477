<!-- ---------------------- Canvas --------------------------->
<div style="min-width: 220px;">
	<div class=" box box-info">
		<div class="box-header ">
			<h3 class="box-title ">Visualizer</h3>

			<div class="box-tools ">
				<span class="badge bg-green"
					>{{ statsValues[0] }} {{ statsUnits[0] }}</span
				>
				<span style="padding-right: 10px;"></span>
				<span class="badge bg-yellow">{{ statsValues[1] }} {{ statsUnits[1] }}</span>
			</div>
		</div>

		<div class="box-body">
			<svg
				id="canvasContainer"
				width="100%"
				height="150"
				viewBox="0 0 100 140"
				preserveAspectRatio="xMinYMid meet"
				xmlns="http://www.w3.org/2000/svg"
			>
				<svg
					id="array1D"
					width="900"
					style="border: 1px solid blue;"
				></svg>
			</svg>

			<app-media-control
				[isPlaying]="isPlaying"
				[progress]="progress"
				(event)="performMediaControlCommand($event)"
			></app-media-control>
		</div>
		<div class="box-footer"></div>
	</div>
</div>

<button mat-raised-button (click)="testButton1()">Test 1</button>
<button mat-raised-button (click)="testButton2()">Test 2</button>
<button mat-raised-button (click)="testButton3()">Test 3</button>
<button mat-raised-button (click)="testButton4()">Test 4</button>

<div id="output"></div>
