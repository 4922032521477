<h2>Create new nodes</h2>
<mat-form-field style="width:100% !important">
        <input #txtNodes matInput placeholder='List of nodes separate by a comma " , "' value="a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t" />
</mat-form-field>

<br />

<mat-form-field style="width:100% !important">
        <input #txtPositions matInput placeholder="(optional) List of positions of the nodes. Ex (100,200), (150, 200)" 
                value="(100,200), (150, 200)"/>
</mat-form-field>

<br />

<button mat-raised-button (click)="createNodes(txtNodes.value, txtPositions.value)" color="accent" style="width: 100%;">Create Nodes</button>
<br />
<br />
<button mat-raised-button (click)="cancel()" color="warning" style="width: 100%;">Cancel</button>