import { Component, Input } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { PublicService } from '../public.service';

@Component({
	selector: 'app-side-nav',
	templateUrl: './side-nav.component.html',
	styleUrls: ['./side-nav.component.css'],
	providers: [PublicService]

})
export class SideNavComponent
{
	// Default is: isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)

	isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
		.pipe(
			map(result => result.matches),
			shareReplay()
		);

	constructor(private breakpointObserver: BreakpointObserver, private publicService: PublicService)
	{

	}

	sidebarStateChanged(opened: boolean)
	{
		this.publicService.changeSidebarState(opened);
	}
}