/*
	Nguyen, Nguyen

	December 14, 2019
*/

import { EventHandler } from './EventHandler.lib';

export interface Shape2DStyle
{
	fillColor: string;
	strokeColor: string;
	strokeWidth: number;
	textColor: string;
}

export class Shape2D 
{
	readonly id: number = Math.floor(Math.random() * 10000000000) + Math.floor(Math.random() * 10000000000) % 13;
	private mX: number;
	private mY: number;
	private mText: any;
	private mStyle: Shape2DStyle;
	private mOpacity: number;

	public onPositionChanged: EventHandler;
	public onMouseUp: EventHandler;
	public onMouseDown: EventHandler;
	public onMouseMove: EventHandler;
	public onMouseLeave: EventHandler;
	public onTouchStart: EventHandler;
	public onTouchEnd: EventHandler;

	public svg = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
	protected svg_base: any;
	protected svg_text: any;

	[key: string]: any; // + Additional properties from the derived class.

	constructor(x: number, y: number)
	{
		this.svg.setAttributeNS(null, 'id', `${this.id}`);
		this.setX(x);
		this.setY(y);
		this.mOpacity = 1;
		this.onPositionChanged = new EventHandler();
		this.mStyle = { fillColor: null, strokeColor: null, strokeWidth: null, textColor: null };
	}

	// Mouse Events
	activateEventListeners()
	{
		// Mouse Cursor
		this.svg.style.cursor = 'pointer';

		//Event Drag Drop
		this.onTouchStart = new EventHandler();
		this.onTouchEnd = new EventHandler();
		this.onMouseUp = new EventHandler();
		this.onMouseDown = new EventHandler();
		// this.onMouseMove = new EventHandler();
		// this.onMouseLeave = new EventHandler();

		
		this.svg.addEventListener('mousemove', (e) =>
		{
			// Didn't use the setters because these changes are just temporary.
			// These changes should not be save to the member variables
			this.svg_base.style.fill = 'orange';
			this.svg_base.style.stroke = 'yellow';
			this.svg_base.style.strokeWidth = '1';
			this.svg_text.strokeColor = 'brown';
		});

		this.svg.addEventListener('mouseleave', () =>
		{
			this.setFillColor(this.mStyle.fillColor);
			this.setTextColor(this.mStyle.textColor);
			this.setStrokeColor(this.mStyle.strokeColor);
			this.setStrokeWidth(this.mStyle.strokeWidth);
		});
		
		this.svg.addEventListener('mouseup', (e) =>
		{
			if (this.onMouseUp) {
				this.onMouseUp.raiseEvent();
			}
		});

		this.svg.addEventListener('mousedown', (e) =>
		{
			if (this.onMouseDown) {
				if (e.button == 0) // Left click. Also means primary mouse button
				{
					this.onMouseDown.raiseEvent([e]);
				}
			}
		});

		/************************************ FOR TOUCH DEVICES ****************************************/
		this.svg.addEventListener('touchstart', (e) =>
		{
			//alert('touch start')
			if (this.onTouchStart) 
			{
				this.onTouchStart.raiseEvent();
			}
		});

		this.svg.addEventListener('touchend', (e) =>
		{
			//alert('touch end')
			if (this.onTouchEnd) 
			{
				this.onTouchEnd.raiseEvent();
			}
		});
	}

	// Getters
	getSVG()
	{
		return this.svg;
	}

	getX()
	{
		return this.mX;
	}

	getY()
	{
		return this.mY;
	}

	getOpacity()
	{
		return this.mOpacity;
	}

	getColor(): Shape2DStyle
	{
		return this.mStyle;
	}

	getFillColor()
	{
		return this.mStyle.fillColor;
	}

	getStrokeColor()
	{
		return this.mStyle.strokeColor;
	}

	getStrokeWidth()
	{
		return this.mStyle.strokeWidth;
	}

	getText()
	{
		return this.mText;
	}

	getTextColor()
	{
		return this.mStyle.textColor;
	}

	isVisible()
	{
		return this.mOpacity == 1;
	}

	// Setters
	setPosition(x: number, y: number)
	{
		this.mX = x;
		this.svg.setAttributeNS(null, 'x', `${x}`);

		this.mY = y;
		this.svg.setAttributeNS(null, 'y', `${y}`);

		if (this.onPositionChanged) {
			this.onPositionChanged.raiseEvent(['moved']);
		}
	}

	setX(value: number)
	{
		this.mX = value;
		this.svg.setAttributeNS(null, 'x', `${value}`);

		if (this.onPositionChanged) {
			this.onPositionChanged.raiseEvent(['moved']);
		}
	}

	setY(value: number)
	{
		this.mY = value;
		this.svg.setAttributeNS(null, 'y', `${value}`);

		if (this.onPositionChanged) {
			this.onPositionChanged.raiseEvent(['moved']);
		}
	}

	setOpacity(value: number)
	{
		this.mOpacity = value;
		this.svg.setAttributeNS(null, 'opacity', `${value}`);
	}

	toggleVisibility()
	{
		this.mOpacity = (this.mOpacity == 1) ? 0 : 1;
		this.svg.setAttributeNS(null, 'opacity', `${this.mOpacity}`);
	}

	setFillColor(value: string)
	{
		//this.mFillColor = value;
		this.mStyle.fillColor = value;
		this.svg_base.style.fill = value;
	}

	setStrokeColor(value: string)
	{
		this.mStrokeColor = value;
		this.svg_base.style.stroke = value;
	}

	setStrokeWidth(value: number)
	{
		this.svg_base.style.strokeWidth = value;
	}

	setText(value: string)
	{
		this.mText = value;
		this.svg_text.setAttributeNS(null, 'id', `${this.id}_text`);
		this.svg_text.setAttribute('class', 'noselect');
		this.svg_text.innerHTML = value;
	}

	setTextColor(value: string)
	{
		this.mStyle.textColor = value;
		this.svg_text.style.fill = value;
	}
}