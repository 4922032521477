import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
@Component({
	selector: 'app-select-node',
	templateUrl: './select-node.component.html',
	styleUrls: ['./select-node.component.css']
})
export class SelectNodeForm implements OnInit 
{
	// Data
	public nodes: string[] = [];
	public selectedNodes: string[] = [];

	constructor(public _bottomSheetRef: MatBottomSheetRef<SelectNodeForm>)
	{
		for (let e of _bottomSheetRef.containerInstance.bottomSheetConfig.data) {
			this.nodes.push(e.getText());
		}
	}
	ngOnInit(): void
	{
		
	}

	cancel()
	{
		this._bottomSheetRef.dismiss(null);
	}

	selectNode(node: string)
	{
		//console.log(node)
		//this.selectedNodes.push(node);
		this._bottomSheetRef.dismiss(node);
	}
}