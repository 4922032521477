/*
	Nguyen, Nguyen

	November 11, 2020
*/

import { Shape2DStyle, Shape2D } from './Shape2D.lib';
import { AnimationController } from './AnimationController.lib';
import { EventHandler } from './EventHandler.lib';
import { Circle } from './Circle.lib';
import { Performable } from './ScriptManager.lib';
import { Line } from './Line.lib';

export interface GraphVisualizerConfig
{
	readonly canvasID: string; // The ID of a canvas to draw on
	readonly edgeCanvasID: string;
	marginTop: number;
	marginLeft: number;
	graphType: 'directed' | 'undirected' | 'BST' | 'AVL';
	nodeRadius: number;
	horizontalSpacing: number; // Node spacing
	verticalSpacing: number; // Node spacing
	nodeColor: Shape2DStyle;
	nodeAccentColor: Shape2DStyle;
	nodeSelectionColor: Shape2DStyle;
	tagColor: Shape2DStyle;
	edgeColor: Shape2DStyle;
	edgeActivatedColor: Shape2DStyle;
	edgeVisitedColor: Shape2DStyle;
}

export class Node extends Circle
{
	public data: any;
	public edges: Edge[];
	public childNodes: { node: Node, weight: number }[];
	public parentNodes: { node: Node, weight: number }[];
	public visited: boolean = false;
	public level: number;
	public maxDepth: number;
	private offset: { h: number; v: number; }
	private menuElement: any;

	//temp 
	public animCtrl: AnimationController;

	// Events
	public onContextMenu: EventHandler;

	constructor(data: any, color: Shape2DStyle, offset: { h: number, v: number })
	{
		super(0, 0, 20, data, color);
		this.data = data;
		this.edges = [];
		this.childNodes = [];
		this.offset = offset;

		this.onContextMenu = new EventHandler();
		this.menuElement = document.getElementById('menu');

		this.svg.addEventListener('contextmenu', e =>
		{
			e.preventDefault();
			this.menuElement.innerHTML = '';

			// MENU ITEM 1
			let listItem1 = document.createElement('li');
			listItem1.innerHTML = 'Create an Edge';
			listItem1.setAttribute('class', 'menu-item');
			listItem1.addEventListener('click', () =>
			{
				if (this.onContextMenu) {
					this.onContextMenu.raiseEvent([{ command: 'createEdge', args: [this] }]);
				}

				this.menuElement.classList.remove('show');
			});
			this.menuElement.appendChild(listItem1);

			// MENU ITEM 2
			let listItem2 = document.createElement('li');
			listItem2.innerHTML = 'Delete node';
			listItem2.setAttribute('class', 'menu-item');
			listItem2.addEventListener('click', () =>
			{
				if (this.onContextMenu) {
					this.onContextMenu.raiseEvent([{ command: 'deleteNode', args: [this] }]);
				}

				this.menuElement.classList.remove('show');
			});
			this.menuElement.appendChild(listItem2);

			// MENU ITEM 3
			let listItem3 = document.createElement('li');
			listItem3.innerHTML = 'Rename';
			listItem3.setAttribute('class', 'menu-item');
			listItem3.addEventListener('click', () =>
			{
				if (this.onContextMenu) {
					this.onContextMenu.raiseEvent([{ command: 'rename', args: [this] }]);
				}

				this.menuElement.classList.remove('show');
			});
			this.menuElement.appendChild(listItem3);

			// SHOW MENU
			var cv = document.getElementById("canvasContainer");
			const rect = cv.getBoundingClientRect();
			const x = this.getCenterX() + this.getRadius() * 2;
			const y = e.clientY - 30;//- rect.top;//  + this.getRadius() * 5;

			this.menuElement.style.top = `${y}px`;
			this.menuElement.style.left = `${x}px`;
			this.menuElement.classList.add('show');
		});

		document.getElementById("canvasContainer").addEventListener('click', () =>
		{
			this.menuElement.classList.remove('show');
		})
	}

	get Depth()
	{
		return this.maxDepth;
	}

	addEdge(edge: Edge)
	{
		this.edges.push(edge);
	}

	removeAllEdges()
	{
		for(let e of this.edges)
		{
			e.remove();			
		}
		this.edges.length = 0;
	}

	disconnect(targetNode: Node)
	{
		// Need to rewrite. This code is not efficient
		let node = this.childNodes.find(e => e.node == targetNode)

		if (node != null)
		{
			let edge = this.edges.find(e => e.head == targetNode);
			this.removeEdge(edge);

			const index = this.childNodes.indexOf(node, 0);
			this.childNodes.splice(index, 1);
		}
	}

	removeEdge(edge: Edge)
	{
		const index = this.edges.indexOf(edge, 0);
		if (index > -1) 
		{
			edge.remove();
			this.edges.splice(index, 1);
		}
	}

	animateTraverseTo(node: Node, duration: number)
	{
		let edge = this.edges.find(e => e.head == node)
		edge.animateTraversal('forwards', duration);
	}

	animateBacktrackFrom(node: Node, duration: number)
	{
		let edge = this.edges.find(e => e.head == node)
		edge.animateTraversal('backwards', duration);
	}
}

export class Edge extends Line
{
	public tail: Node;
	public head: Node;
	public weight: number;
	public isDirected: boolean;
	public text: any;
	private color: Shape2DStyle;
	private overlayEdge: Edge;
	private overlayEdgeActivatedColor: Shape2DStyle;
	private overlayEdgeVisitedColor: Shape2DStyle;
	public canvas: any; // For adding an overlay edge to the canvas

	// Event
	onAnimationEnd: EventHandler;

	constructor(tailNode: Node, headNode: Node, directed: boolean, color: Shape2DStyle, weight?: number, text?: any)
	{
		super(tailNode.getCenterX(), tailNode.getCenterY(), headNode.getCenterX(), headNode.getCenterY(), text, color);
		this.color = color;
		this.tail = tailNode;
		this.head = headNode;
		this.isDirected = directed;

		if (weight) {
			this.weight = weight;
		}

		if (text) {
			this.text = text;
		}

		// Event
		this.onAnimationEnd = new EventHandler();

		// Event Handlers
		this.tail.onPositionChanged.addHandler(() => { this.setTailPoint(this.tail.getCenterX(), this.tail.getCenterY()) });
		this.head.onPositionChanged.addHandler(() => { this.setHeadPoint(this.head.getCenterX(), this.head.getCenterY()) });
	}

	setOverlayEdgeColor(activatedColor: Shape2DStyle, visitedColor: Shape2DStyle)
	{
		this.overlayEdgeActivatedColor = activatedColor;
		this.overlayEdgeVisitedColor = visitedColor;
	}

	createOverlayEdge()
	{
		if (this.overlayEdge == null) {
			this.overlayEdge = new Edge(this.tail, this.head, this.isDirected, this.overlayEdgeActivatedColor, this.weight, this.text);
			if (this.isDirected) {
				this.overlayEdge.setHeadStyle('arrow');
			}
			this.canvas.appendChild(this.overlayEdge.getSVG());
		}
	}

	remove()
	{
		this.canvas.removeChild(this.getSVG());
		this.removeOverlayEdge();		
	}

	removeOverlayEdge()
	{
		if (this.overlayEdge != null) {
			this.canvas.removeChild(this.overlayEdge.getSVG());
			this.overlayEdge = null;
		}
	}
	
	animateTraversal(direction: 'forwards' | 'backwards', duration: number)
	{
		this.createOverlayEdge();

		if (direction == 'forwards') {
			this.overlayEdge.getSVG().onanimationend = () =>
			{
				this.overlayEdge.getSVG().onanimationend = null;

				if (this.onAnimationEnd) {
					this.onAnimationEnd.raiseEvent();
				}
			};
			this.overlayEdge.getSVG().setAttributeNS(null, 'style',
				`stroke-dasharray: 988; stroke-dashoffset: 988; animation:traverseForwards ${duration.toString()}ms ease-in-out forwards;`);
		}
		else {
			this.overlayEdge.getSVG().setAttributeNS(null, 'style',
				`stroke-dasharray: 988; stroke-dashoffset: 988; animation: traverseBackwards ${duration.toString()}ms ease-out forwards;`);
		}
	}
}

export class GraphVisualizer implements Performable
{
	/***** Config *****/
	readonly config: GraphVisualizerConfig;

	// Canvas / context
	private canvasContainer: any
	private nodeCanvas: any; // The canvas to draw on
	private edgesCanvas: any;
	public root: Node; // Root Node // Public for testing only
	public nodes: Node[];
	private selectedNodes: Node[];

	//public data: any[]; // Store data
	//private elements: Shape2D[]; // Array elements

	// Animation Controller
	animCtrl: AnimationController;

	// Events
	onAnimationEnd: EventHandler;
	onSelectedNodesChanged: EventHandler;
	onStateChanged:EventHandler;

	// Lock
	locked = false;
	private mEnableMovingNodes = true; // Node Moving Nodes

	// Playing speed
	private speedRatio: number = 1;

	public guideline: Line;

	constructor(config: GraphVisualizerConfig)
	{
		this.nodes = [];
		this.selectedNodes = [];
		this.config = config;
		this.canvasContainer = document.getElementById("canvasContainer");
		this.nodeCanvas = document.getElementById(config.canvasID);
		this.edgesCanvas = document.getElementById(config.edgeCanvasID);

		if (this.nodeCanvas == null) {
			console.log(`Canvas ${config.canvasID} not found.`);
		}
		else {
			this.onSelectedNodesChanged = new EventHandler();
			this.onAnimationEnd = new EventHandler();
			this.onStateChanged = new EventHandler();

			this.animCtrl = new AnimationController();
			this.animCtrl.onStop.addHandler(() =>
			{
				if (this.nodesNeedReArraging.length > 0) {
					if (this.nodesNeedReArraging[0].leftLink) {
						this.nodesNeedReArraging.push(this.nodesNeedReArraging[0].leftLink);
					}

					if (this.nodesNeedReArraging[0].rightLink) {
						this.nodesNeedReArraging.push(this.nodesNeedReArraging[0].rightLink);
					}

					this.arrange(this.nodesNeedReArraging[0]);
					this.nodesNeedReArraging.splice(0, 1)
				}
			});
		}

		// Canvas Resize event
		window.addEventListener('resize', this.resizeCanvas.bind(this), false);
		window.addEventListener('orientationchange', this.resizeCanvas.bind(this), false);

		// Mouse Events
		// Double Click on the empty space of the canvas
		this.canvasContainer.addEventListener('click', this.cancelCreatingEdgeMode.bind(this));
		this.canvasContainer.addEventListener('dblclick', this.insertNodeOnDoubleClick.bind(this));
		this.canvasContainer.addEventListener('mousemove', (e) =>
		{
			let rect = this.canvasContainer.getBoundingClientRect();

			if (this.enableMovingNodes && this.capturedNode != null) {
				let x = e.clientX - rect.left - this.config.nodeRadius;
				let y = e.clientY - rect.top - this.config.nodeRadius;
				this.capturedNode.setPosition(x, y);
			}

			if (this.guideline != null) {
				let x = e.clientX - rect.left;
				let y = e.clientY - rect.top;
				this.guideline.setHeadPoint(x, y);
			}
		});

		this.canvasContainer.addEventListener('touchmove', (e) =>
		{
			let rect = this.canvasContainer.getBoundingClientRect();

			if (this.enableMovingNodes && this.capturedNode != null) {
				let x = e.touches[0].clientX - rect.left - this.config.nodeRadius;
				let y = e.touches[0].clientY - rect.top - this.config.nodeRadius;
				this.capturedNode.setPosition(x, y);
			}
		});
	}

	get enableMovingNodes(): boolean
	{
		return this.mEnableMovingNodes;
	}

	set enableMovingNodes(value: boolean)
	{
		this.capturedNode = null;
		this.mEnableMovingNodes = value;
	}

	createGuideline(x1: number, y1: number, tailStyle: string, headStyle: string)
	{
		this.guideline = new Line(x1, y1, x1, y1, null, this.config.edgeColor);
		this.guideline.svg.setAttribute('stroke-dasharray', '10, 5');
		this.guideline.setTailPointOffset(this.config.nodeRadius);
		this.guideline.setHeadPointOffset(this.config.nodeRadius / 2);

		if (tailStyle != null) {
			this.guideline.setTailStyle(tailStyle);
		}

		if (headStyle != null) {
			this.guideline.setHeadStyle(headStyle);
		}
		this.nodeCanvas.appendChild(this.guideline.getSVG());
	}

	deleteGuideline()
	{
		if (this.guideline) {
			this.nodeCanvas.removeChild(this.guideline.getSVG());
			this.guideline = null;
		}
	}

	/**
	 * Returns the playing speed.
	 */
	getSpeedRatio()
	{
		return this.speedRatio;
	}

	/**
	 * Set the playing speed
	 * @param value     Number from 0-100.
	 */
	setSpeed(value: number)
	{
		this.speedRatio = 50 / value;
	}

	public nodesNeedReArraging: Node[] = [];

	arrange(ptr: Node)
	{
		let num = (ptr.maxDepth > 2 ? this.config.horizontalSpacing * 2 : this.config.horizontalSpacing);

		if (ptr.level == 0 && ptr.maxDepth >= 4) {
			num += this.config.horizontalSpacing;
		}
		if (ptr.leftLink != null) {
			this.animCtrl.addLinearMotion(ptr.leftLink, [

				{ x: ptr.getX() - ptr.getRadius() - Math.pow(2, ptr.maxDepth) / 4 * num, y: ptr.leftLink.getY(), duration: 200 }
				// { x: ptr.getX() - ptr.getRadius() - Math.pow(2, ptr.maxDepth) / 4 * this.config.horizontalSpacing - (ptr.leftLink.maxDepth * this.config.horizontalSpacing), y: ptr.leftLink.getY(), duration: 200 }
				//{ x: ptr.getX() - ptr.getRadius() - (ptr.maxDepth > 2 ? this.config.horizontalSpacing : 0) - Math.pow(2, ptr.maxDepth) / 4 * this.config.horizontalSpacing, y: ptr.leftLink.getY(), duration: 200 }
				// { x: ptr.getX() - ptr.getRadius() - ((this.root.maxDepth - ptr.level - 1) * this.config.horizontalSpacing), y: ptr.leftLink.getY(), duration: 200 }
			]);
		}

		if (ptr.rightLink != null) {
			this.animCtrl.addLinearMotion(ptr.rightLink, [
				{ x: ptr.getX() + ptr.getRadius() + Math.pow(2, ptr.maxDepth) / 4 * num, y: ptr.rightLink.getY(), duration: 200 }
				// { x: ptr.getX() + ptr.getRadius() + Math.pow(2, ptr.maxDepth) / 4 * this.config.horizontalSpacing + (ptr.rightLink.maxDepth * this.config.horizontalSpacing), y: ptr.rightLink.getY(), duration: 200 }
				// { x: ptr.getX() + ptr.getRadius() + ((this.root.maxDepth - ptr.level - 1) * this.config.horizontalSpacing), y: ptr.rightLink.getY(), duration: 200 }
			]);
		}

		this.animCtrl.start();
	}
	/**/

	arrange2(ptr: Node)
	{
		console.log(`Arranging ${ptr.data}`)

		if (ptr.leftLink != null) {
			let levelsToRightmost = this.countLevelsToRightmost(ptr.leftLink);

			if (ptr.leftLink.rightLink != null) {
				levelsToRightmost += this.countLevelsToLeftmost(ptr.leftLink.rightLink);
			}

			let num: number

			if (levelsToRightmost > 0)
				num = levelsToRightmost * (2 * this.config.nodeRadius + this.config.horizontalSpacing);
			else
				num = this.config.horizontalSpacing;

			let new_x = ptr.getX() - ptr.getRadius() - num;
			if (new_x != ptr.getX()) {
				this.animCtrl.addLinearMotion(ptr.leftLink, [
					{ x: new_x, y: ptr.leftLink.getY(), duration: 200 }
				]);
			}
		}

		if (ptr.rightLink != null) {
			let levelsToLeftmost = this.countLevelsToLeftmost(ptr.rightLink);

			if (ptr.rightLink.leftLink != null) {
				levelsToLeftmost += this.countLevelsToRightmost(ptr.rightLink.leftLink);
			}

			let num: number

			if (levelsToLeftmost > 0)
				num = levelsToLeftmost * (2 * this.config.nodeRadius + this.config.horizontalSpacing);
			else
				num = this.config.horizontalSpacing;

			let new_x = ptr.getX() + ptr.getRadius() + num;
			if (new_x != ptr.getX()) {
				this.animCtrl.addLinearMotion(ptr.rightLink, [
					{ x: new_x, y: ptr.rightLink.getY(), duration: 200 }
				]);
			}
		}

		this.animCtrl.start();
	}
	/*
	updateMaxDepth(ptr: Node)
	{
		let newDepth: number;
		if (ptr.leftLink == null && ptr.rightLink == null)
		{
			newDepth = 1;
		}
		else if (ptr.leftLink != null && ptr.rightLink != null)
		{
			newDepth = 1 + (ptr.leftLink.maxDepth > ptr.rightLink.maxDepth ? ptr.leftLink.maxDepth : ptr.rightLink.maxDepth);
		}
		else if (ptr.leftLink != null)
		{
			newDepth = 1 + ptr.leftLink.maxDepth;
		}
		else
		{
			newDepth = 1 + ptr.rightLink.maxDepth;
		}

		if (ptr.maxDepth != newDepth)
		{
			ptr.maxDepth = newDepth;

			if (ptr.parentLink != null)
			{
				if (this.nodesNeedReArraging.length == 0)
				{
					this.nodesNeedReArraging.push(ptr.parentLink);
				}
				else
				{
					this.nodesNeedReArraging[0] = ptr.parentLink;
				}

				this.updateMaxDepth(ptr.parentLink);
			}
		}
	}   
	*/

	maxDepth(ptr: Node)
	{
		if (ptr != null) {
			let leftMax = this.maxDepth(ptr.leftLink);
			let rightMax = this.maxDepth(ptr.rightLink);

			return (leftMax > rightMax ? leftMax + 1 : rightMax + 1);
		}
		else {
			return 0;
		}
	}

	countLevelsToLeftmost(ptr: Node)
	{
		if (ptr == null) {
			return 0;
		}
		else if (ptr.leftLink == null) {
			return 0;
		}
		else {
			return 1 + this.countLevelsToLeftmost(ptr.leftLink);
		}
	}

	countLevelsToRightmost(ptr: Node)
	{
		if (ptr == null) {
			return 0;
		}
		else if (ptr.rightLink == null) {
			return 0;
		}
		else {
			return 1 + this.countLevelsToRightmost(ptr.rightLink);
		}
	}

	getNode(value: any)
	{
		return this.nodes.find(e => e.getText() == value);
	}

	get leftmost(): Node
	{
		let current = this.root;

		while (current.leftLink != null) {
			current = current.leftLink;
		}

		return current;
	}

	get rightmost(): Node
	{
		let current = this.root;

		while (current.rightLink != null) {
			current = current.rightLink;
		}

		return current;
	}

	printInOrder()
	{
		this.recursivePrintInOrder(this.root);
	}

	private recursivePrintInOrder(ptr: Node)
	{
		if (ptr) {
			this.recursivePrintInOrder(ptr.leftLink);
			console.log(`element: ${ptr.data}, isNaN: ${isNaN(ptr.data)}, level: ${ptr.level}, maxDepth: ${ptr.Depth}, x: ${ptr.getX()}`);
			this.recursivePrintInOrder(ptr.rightLink);
		}
	}

	perform(command: string, args: any[]): void
	{
		if (!this.locked) {
			switch (command) {
				case 'visit': // Will not raise any event
					{
						let node1 = this.getNode(args[0]);
						node1.setFillColor(this.config.nodeAccentColor.fillColor);
						node1.setStrokeColor(this.config.nodeAccentColor.strokeColor);
					}
					break;
				case 'animateTraverse':
					{
						let node1 = this.getNode(args[0]);
						let node2 = this.getNode(args[1]);

						node1.animateTraverseTo(node2, args[2]);
					}
					break;
				case 'animateBacktrack':
					{
						let node1 = this.getNode(args[0]);
						let node2 = this.getNode(args[1]);
						node1.animateBacktrackFrom(node2, args[2]);
					}
					break;
				case 'print':
					console.log(this.root.leftLink.data)
					break;
				default:
					throw new Error("Method not implemented.");
			}
		}
	}

	switchToCreatingEdgeMode()
	{
		// Disable moving elements
		this.enableMovingNodes = false;

		this.onSelectedNodesChanged.addHandler((e) =>
		{
			if (e.length == 1) {
				let headMarker = this.config.graphType == 'undirected' ? 'dot' : 'arrow-for-guideline';
				this.createGuideline(e[0].getCenterX(), e[0].getCenterY(), 'dot', headMarker);
			}
			else if (e.length == 2) {
				this.createEdge(e[0], e[1], 1);

				this.unselectAllNodes();
				this.cancelCreatingEdgeMode();
			}
		});
	}

	cancelCreatingEdgeMode()
	{
		this.enableMovingNodes = true;
		this.deleteGuideline();
		this.onSelectedNodesChanged.removeAllHandlers();

		//console.log(this.nodes);
	}

	moveNode(ptr: Node, newX: number, newY: number)
	{
		this.animCtrl.addLinearMotion(ptr, [
			{ x: newX, y: newY, duration: 200 }
		]);
		this.animCtrl.start();
	}

	unselectAllNodes()
	{
		this.selectedNodes.length = 0;
	}

	// Delete all nodes and edges
	clear()
	{
		this.root = null;
		this.nodes.length = 0;
		this.nodeCanvas.innerHTML = "";
		this.edgesCanvas.innerHTML = "";
	}

	// Delete a single node
	deleteNode(node: Node)
	{
		const index = this.nodes.indexOf(node, 0);
		if (index > -1) 
		{		
			// Delete incoming edges
			for(let e of this.nodes)
			{
				if(e != node)
				{
					e.disconnect(node);
				}
			}

			// Delete outgoing edges
			node.removeAllEdges();

			

			node.getSVG().remove();
			this.nodes.splice(index, 1);
			console.log('the node has been deleted')
			this.onStateChanged.raiseEvent();
		}
	}

	insertNodeOnDoubleClick(e): void
	{
		const rect = this.canvasContainer.getBoundingClientRect();
		const x = e.clientX - rect.left - this.config.nodeRadius;
		const y = e.clientY - rect.top - this.config.nodeRadius;

		// Random 1 - 100: Math.floor(Math.random() * 100) + 1 

		// let n = this.nodes.length == 0 ? 'a' : String.fromCharCode(this.nodes.length + 97);
		let lastNode = this.nodes[this.nodes.length - 1];
		let n = this.nodes.length == 0 ? 'a' : String.fromCharCode(lastNode.getText().charCodeAt(0) + 1);

		this.insertNodeAt(n, x, y);
	}

	insertNodeAt(elem: any, x: number, y: number)
	{
		var newNode = new Node(elem, this.config.nodeColor, { h: this.config.horizontalSpacing, v: this.config.verticalSpacing });

		newNode.level = 0;
		newNode.maxDepth = 1;
		newNode.animCtrl = this.animCtrl;
		newNode.setPosition(x, y);
		this.nodeCanvas.appendChild(newNode.getSVG());

		this.animCtrl.start();
		this.nodes.push(newNode);

		// Update State
		this.onStateChanged.raiseEvent();

		// Activate Event Listener
		newNode.activateEventListeners();

		// EVENT HANDLER: MOUSE EVENTS
		newNode.onMouseUp.addHandler(() => { this.capturedNode = null; this.onStateChanged.raiseEvent();});
		newNode.onMouseDown.addHandler(() =>
		{
			this.capturedNode = newNode;

			if (this.onSelectedNodesChanged && this.onSelectedNodesChanged.handlers.length > 0) {
				this.selectedNodes.push(newNode);
				console.log('Number of selected nodes: ' + this.selectedNodes.length);
				this.onSelectedNodesChanged.raiseEvent([this.selectedNodes]);
			}
		});

		// EVENT HANDLER: TOUCH EVENTS
		newNode.onTouchEnd.addHandler(() => { this.capturedNode = null; this.onStateChanged.raiseEvent();});
		newNode.onTouchStart.addHandler(() => 
		{
			this.capturedNode = newNode;

			if (this.onSelectedNodesChanged && this.onSelectedNodesChanged.handlers.length > 0) {
				this.selectedNodes.push(newNode);
				console.log('Number of selected nodes: ' + this.selectedNodes.length);
				this.onSelectedNodesChanged.raiseEvent([this.selectedNodes]);
			}
		});

		// EVENT HANDLER: CONTEXT MENU
		newNode.onContextMenu.addHandler(e =>
		{
			switch (e.command) 
			{
				case 'createEdge':
					this.switchToCreatingEdgeMode();
					this.selectedNodes.push(e.args[0]);
					this.onSelectedNodesChanged.raiseEvent([this.selectedNodes]);
					break;
				case 'deleteNode':
					this.deleteNode(e.args[0]);
					break;
				case 'rename':						
					let value = prompt("Please enter a new name", e.args[0].getText());
					if (value != null)
					{
						e.args[0].data = value;
						e.args[0].setText(value);
						this.nodes.sort((a, b) => (a.data > b.data) ? 1 : -1)
						this.onStateChanged.raiseEvent(['nodeRenamed']);
					}						
					break;
			}
		});
	}
	private capturedNode: Node;

	createNodesFromList(nodes: string, positions: string)
	{
		// Build array of positions. 
		let posList: { x: number, y: number }[] = [];
		let match: any;
		let positionRegex = new RegExp(/\(\s*\d+\s*,\s*\d+\s*\)/g);
		while ((match = positionRegex.exec(positions)) != null) {
			let digitRex = new RegExp(/\d+/g);

			let n1 = Number(digitRex.exec(match[0])[0]);
			let n2 = Number(digitRex.exec(match[0])[0]);

			posList.push({ x: n1, y: n2 });;
		}

		let row = 1, col = 1;
		let nodeRegex = new RegExp(/\w+/g);
		while ((match = nodeRegex.exec(nodes)) != null) {
			let pos = posList.shift();

			if (pos != null) {
				this.insertNodeAt(match[0], pos.x, pos.y);
			}
			else {
				if (row % 6 == 0) {
					++col;
					row = 1;
				}
				this.insertNodeAt(match[0], row * this.config.nodeRadius * 3, col * this.config.nodeRadius * 3);
				++row;
			}

		}
		//
	}

	/**
	 * Create an edge connecting two given nodes.
	 * In case of undirected graph, 2 edges will be created.
	 * 
	 * @param nodeA Source node
	 * @param nodeB Target node
	 * @param weight The weight or the cost of the edge
	 */
	createEdge(nodeA: Node, nodeB: Node, weight: number)
	{	
		// Avoid duplicating
		if (!nodeA.childNodes.find(e => e.node == nodeB)) {
			if (this.config.graphType == 'directed') {
				// Linking
				nodeA.childNodes.push({ node: nodeB, weight: weight });
				
				// Create an edge
				let edge = new Edge(nodeA, nodeB, true, this.config.edgeColor);
				edge.setOverlayEdgeColor(this.config.edgeActivatedColor, this.config.edgeVisitedColor);
				edge.setHeadStyle('arrow');
				edge.canvas = this.edgesCanvas;

				// Add the edge to the node
				nodeA.addEdge(edge);
				
				// Draw to canvas
				this.edgesCanvas.appendChild(edge.getSVG());

				// Update State
				this.onStateChanged.raiseEvent();
			}
			else if (this.config.graphType == 'undirected') {
				// Linking
				nodeA.childNodes.push({ node: nodeB, weight: weight });
				nodeB.childNodes.push({ node: nodeA, weight: weight });

				// Create 2 edges
				let edgeA = new Edge(nodeA, nodeB, false, this.config.edgeColor);
				edgeA.setOverlayEdgeColor(this.config.edgeActivatedColor, this.config.edgeVisitedColor);
				edgeA.canvas = this.edgesCanvas;

				let edgeB = new Edge(nodeB, nodeA, false, this.config.edgeColor);
				edgeB.setOverlayEdgeColor(this.config.edgeActivatedColor, this.config.edgeVisitedColor);
				edgeB.canvas = this.edgesCanvas;

				// Add the edges to the nodes
				nodeA.addEdge(edgeA);
				nodeB.addEdge(edgeB);

				// Draw to canvas
				this.edgesCanvas.appendChild(edgeA.getSVG());
				this.edgesCanvas.appendChild(edgeB.getSVG());

				// Update State
				this.onStateChanged.raiseEvent();
			}
		}
	}

	createEdgesFromList(str: string)
	{
		var regex = new RegExp(/\(\s*\w+\s*,\s*\w+\s*\)/g); // (a,b), (2,2), (2, 2), ( 2 , 22 )
		let match: any;

		while ((match = regex.exec(str)) != null) {
			let temp = String(match[0]);

			let t = temp.replace(/[^a-zA-Z, ]/g, "").split(',');
			this.createEdge(this.getNode(t[0].trim()), this.getNode(t[1].trim()), 1);

			if (this.config.graphType == 'undirected') {
				this.createEdge(this.getNode(t[1].trim()), this.getNode(t[0].trim()), 1);
			}
			/*
			if (temp[0] == '(') // Directed Edge
			{
			}
			else // Undirected Edge
			{

			}
			*/
		}
	}

	/**
	 * Resize to fit the content
	 */
	resizeCanvas()
	{
		return;
		let svg = document.querySelector(`#canvasContainer`) as SVGSVGElement;
		let vbBase = svg.viewBox.baseVal;
		let box = svg.getBBox();

		// scale = Size of Objects / Size of Canvas
		let scale = (box.width) / svg.width.baseVal.value;

		if (scale > 0.9) // No need to scale up.
		{
			vbBase.height = (svg.height.baseVal.value) * scale;
		}

		vbBase.x = - (svg.width.baseVal.value - (this.config.marginLeft * 4) - (box.width / (scale > 1 ? scale : 1))) / 4;
		vbBase.y = 0;
	}

	setAllEdgesAsUnvisited()
	{
		for (let node of this.nodes) {
			for (let edge of node.edges) {
				edge.removeOverlayEdge();
			}
		}
	}

	setAllNodesAsUnvisited()
	{
		for (let e of this.nodes) {
			e.visited = false;
			e.setFillColor(this.config.nodeColor.fillColor);
			e.setStrokeColor(this.config.nodeColor.strokeColor);
		}
	}

	dfs(node: Node): void
	{
		if (!node.visited) {
			console.log(node.getText());
		}

		node.visited = true;
		for (var e of node.childNodes) {
			if (!e.node.visited) {
				this.dfs(e.node);
			}
		}
	}

	getDFSTraversalTrack(previousNode: Node, currentNode: Node, results: { action: 'visit' | 'back', node: string }[]): void
	{
		if (!currentNode.visited) {
			results.push({ action: 'visit', node: currentNode.getText() });
		}

		currentNode.visited = true;
		for (var e of currentNode.childNodes) {
			if (!e.node.visited) {
				this.getDFSTraversalTrack(currentNode, e.node, results);
			}
		}

		if (previousNode) {
			results.push({ action: 'visit', node: previousNode.getText() });
		}
	}

	isConnected(): boolean
	{
		// Mark all the nodes as not visited 
		for (var e of this.nodes) {
			e.visited = false;
		}

		// Find a vertex with non-zero degree 
		let i = 0;
		while (i < this.nodes.length && this.nodes[i].childNodes.length == 0) {
			++i;
		}

		if (i >= this.nodes.length) {
			return true;
		}

		this.dfs(this.nodes[i]);

		i = 0;
		while (i < this.nodes.length && this.nodes[i].visited) {
			++i;
		}

		return i >= this.nodes.length;
	}

	// Return 0: Not a Eulerian Graph, 1: Eulerian Cycle, 2: Eulerian Path
	isEulerian(): number
	{
		if (!this.isConnected()) {
			return 0;
		}

		// Count vertices with odd degree
		let numOfOdds = 0;
		for (let e of this.nodes) {
			if (e.childNodes.length % 2 != 0) {
				++numOfOdds;
			}
		}

		if (numOfOdds > 2) {
			return 0;
		}

		return numOfOdds == 0 ? 1 : 2; // 1: Eulerian . 2: Semi Eulerian
	}

	/*
		Returns:
			0: Not a Hamiltonian Graph
			2: Might be true or false. Don't know
	*/
	checkHamiltonian() : number
	{
		if (!this.isConnected())
		{
			return 0;
		}

		let i = 0;
		while (i  < this.nodes.length && this.nodes[i].childNodes.length > 1)
		{
			++i;
		}
		
		if (i < this.nodes.length)
		{
			return 0;
		}

		return 2; // We don't know. Might be a Hamiltonian Graph, might be not.
	}
}