<h2>Create new edges</h2>

<mat-form-field style="width:100% !important">
        <input #txtEdges matInput placeholder="(optional) List of positions of the nodes. Ex (100,200), (150, 200)" 
                value="(a,b), (b, c), (d,e)"/>
</mat-form-field>

<br />

<button mat-raised-button (click)="createNodes(txtEdges.value)" color="accent" style="width: 100%;">Create Nodes</button>
<br />
<br />
<button mat-raised-button (click)="cancel()" color="warning" style="width: 100%;">Cancel</button>