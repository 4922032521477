import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { PublicService } from 'src/app/public.service';
import { MatSliderChange } from '@angular/material/slider';

@Component({
	selector: "app-media-control",
	template: `
		<div class="grid">
			<span class="spacer"></span>
			<div style="width: 98%; text-align: center;">
				<mat-progress-bar mode="determinate" [value]="progress" class="progress-bar-darkgray"></mat-progress-bar>
			</div>
			<span class="spacer"></span>
		</div>
		<div class="grid" style="padding-top: 10px;">
			<button mat-mini-fab color="white" aria-label="Previous" (click)="sendPlayerCommand('previous')"><mat-icon>fast_rewind</mat-icon></button>
			<span style="padding-right: 10px;"></span>
			<button mat-fab color="white" aria-label="Play" 
			(click)="sendPlayerCommand(isPlaying? 'pause' : 'play')"><mat-icon>{{isPlaying? 'pause' : 'play_arrow'}}</mat-icon></button>
			<span style="padding-right: 10px;"></span>
			<button mat-mini-fab color="white" aria-label="Next" (click)="sendPlayerCommand('next')"><mat-icon>fast_forward</mat-icon></button>
			
			<span style="padding-left: 30px;"></span>

			<img src="assets/img/turtle.svg" style="height: 30px;" />
			<mat-slider (input)="speedSliderOnInput($event)" (change)="sendPlayerCommand('speed', [$event.value])" [value] = "speedSliderValue"
				min="0" max="100" >
			</mat-slider>
			<img src="assets/img/rabbit.svg" style="height: 30px;" />
			<span style="padding-left: 30px;"></span>
			<button mat-raised-button (click)="sendPlayerCommand('random')">Generate Random Numbers</button>
			<span class="spacer"></span>
		</div>
		`,
	styles: []
})

export class MediaControlComponent implements OnInit
{
	@Input('isPlaying') isPlaying: boolean;
	@Input('progress') progress: number;
	@Output() event = new EventEmitter<{ command: string, args: any[] }>();

	// Speed Slider
	@Input('speedSliderValue') speedSliderValue = 50;
	private speedSliderLimit = { min: 10, max: 100 };

	constructor(private publicService: PublicService) { }

	ngOnInit()
	{
		this.publicService.sidebarStateChanged$.subscribe(() => this.sendPlayerCommand('resizeCanvas'));
	}

	/******************************** COMMAND *************************************/
	sendPlayerCommand(cmd: string, args?: any[])
	{
		this.event.emit({ command: cmd, args: args });
	}

	/**************************** SPEED SLIDER ************************************/
	speedSliderOnInput($event: MatSliderChange)
	{
		if ($event.source.value < this.speedSliderLimit.min)
		{
			$event.source.value = this.speedSliderLimit.min;
		}
		else if ($event.source.value > this.speedSliderLimit.max)
		{
			$event.source.value = this.speedSliderLimit.max;
		}
	}

	speedSliderOnChange($event)
	{
		//console.log(`value changed: ${$event.value}`);
	}
}
