import { EventHandler } from './EventHandler.lib';

/*
    Nguyen, Nguyen

    December 24, 2019
*/
export interface Performable
{
    perform(command: string, args: any): void;
}

export interface Action
{
    target: Performable,
    command: string,
    args: any[]
}

export class ScriptManager
{
    // The info of the script. (optional)
    private generalInfo: { key: string, value: any }[];

    // Array of groups of actions. Each groups can store multiple actions
    private actionGroups: { key: string, snapID: number, actions: Action[] }[];

    // Track the current index (for playing).
    private currentScriptIndex = -1;

    // Event
    onPositionChanged: EventHandler;

    constructor()
    {
        // Init
        this.actionGroups = [];
        this.generalInfo = [];
        this.onPositionChanged = new EventHandler();
    }

    /************************** GENERAL INFO ******************************/
    /**
     * Insert or update the general information of the script
     * @param key       The key of the element
     * @param value     The element value to insert 
     */
    setGeneralInfo(key: string, value: any)
    {
        let el = this.generalInfo.find(e => e.key == key);

        if (el)
        {
            el.value = value;
        }
        else
        {
            this.generalInfo.push({ key, value });
        }
    }

    /**
     * Retrieve a value of a general inforamtion with a given key
     * @param key   The key of the element
     * @returns     If the key exists, return the value, otherwise return undefined.
     */
    getGeneralInfo(key: string)
    {
        return this.generalInfo.find(e => e.key == key);
    }
    /************************ END GENERAL INFO ****************************/

    /**
    * Add an array of actions to the script
    */
    addScript(actionGroup: { key: string, snapID: number, actions: Action[] })
    {
        this.actionGroups.push(actionGroup);
    }

    /**
     * Clear all actions in the script
     */
    clear()
    {
        this.actionGroups.length = 0;
        this.generalInfo.length = 0;
        this.currentScriptIndex = -1;
    }

    jumpToSnapshot(snapID: number)
    {
        let a = this.actionGroups.filter(e => e.snapID == snapID);

        console.log(a);


    }

    findScriptIndexBySnapID(snapID: number)
    {
        let left = 0;
        let right = this.actionGroups.length - 1;
        let result = -1

        while (left <= right && result < 0)
        {
            let mid = Math.floor(left + (right - left) / 2);
            //console.log(`->>> ${mid}`);
            if (snapID < this.actionGroups[mid].snapID)
            {
                right = mid - 1;
            }
            else if (snapID > this.actionGroups[mid].snapID)
            {
                left = mid + 1
            }
            else
            {
                result = mid;
            }
        }

        //console.log(`->>> ${result}`);

        // Not found
        if (result < 0)
            return result;
        else
        {
            // Keep going backward to the beginning script index of the snapshot
            while (result > 0 && this.actionGroups[result - 1].snapID == this.actionGroups[result].snapID)
            {
                --result;
                //console.log('This comes from the while loop');
            }

            //console.log(`->>> ${result}`);
            return result;
        }
    }

    getPreviousSnapID()
    {
        if (this.currentScriptIndex > 0)
        {
            let currentSnapID = this.actionGroups[this.currentScriptIndex].snapID;

            if (this.actionGroups[this.currentScriptIndex - 1].snapID == currentSnapID)
            {
                return currentSnapID;
            }
            else
            {
                return currentSnapID > 0 ? currentSnapID - 1 : 0;
            }
        }
        else
        {
            return 0;
        }
    }

    previous()
    {
        if (this.currentScriptIndex > -1)
        {
            this.redo();
            --this.currentScriptIndex;

            if (this.onPositionChanged)
            {
                this.onPositionChanged.raiseEvent([this.currentScriptIndex]);
            }
        }
    }

    redo()
    {
        if (this.currentScriptIndex < this.actionGroups.length)
        {
            this.actionGroups[this.currentScriptIndex].actions.forEach(e =>
            {
                if (e.command != 'delay')
                {
                    e.target.perform(e.command, e.args);
                }
            });
        }
    }
    /**
     * Perform the next action
     */
    next()
    {
        if (this.currentScriptIndex < this.actionGroups.length)
        {
            ++this.currentScriptIndex;
            this.actionGroups[this.currentScriptIndex].actions.forEach(e => { e.target.perform(e.command, e.args) });

            if (this.onPositionChanged)
            {
                this.onPositionChanged.raiseEvent([this.currentScriptIndex]);
            }
        }
    }

    /**
     * Return the length of the script
     */
    getLength()
    {
        return this.actionGroups.length;
    }

    setCurrentPosition(index: number)
    {
        this.currentScriptIndex = index;
    }

    getCurrentPosition()
    {
        return this.currentScriptIndex;
    }

    /**
     * Print out the script to the console. For debugging purpose.
     */
    print()
    {
        this.actionGroups.forEach(e =>
        {
            console.log(e);
        });
    }

    getHTML()
    {
        let html = "";
        let i = -1;
        this.actionGroups.forEach(group =>
        {
            html += `<h5>group[${++i}] -> key: ${group.key}, snapID: ${group.snapID}</h5>`;
            html += '<p style="padding-left: 15px;">';
            group.actions.forEach(e => html += e.command + '<br />');
            html += '</p>'
        });
        return html;
    }
}