import { Component, OnInit, Input } from '@angular/core';
import { Array1DVisualizer, Array1DVisualizerConfig } from 'src/app/lib/Array1DVisualizer.lib';
import { ScriptManager } from 'src/app/lib/ScriptManager.lib';
import { Algorithms } from 'src/app/lib/Algorithms/InplaceAlgorithmScriptBuilder.lib';
import { ArrayVisualizerSnapshotManager } from 'src/app/lib/ArrayVisualizerSnapshotManager.lib';

@Component({
	selector: 'nn-inplace-algorithm-visualizer',
	templateUrl: './inplace-algorithm-visualizer.component.html',
	styleUrls: ['./inplace-algorithm-visualizer.component.css']
})
export class InplaceAlgorithmVisualizerComponent implements OnInit
{
	@Input('algorithm') algorithm: string;
	@Input('selectedCode') selectedCode: string;
	@Input('statsValues') statsValues: any[];
	@Input('statsUnits') statsUnits: string[];
	@Input('statsPrefixes') statsPrefixes: string[];
	@Input('statsSuffixes') statsSuffixes: string[];

	// Visualizer & ScriptPlayer
	private vs: Array1DVisualizer;
	private scriptManager: ScriptManager;
	private snapshotManager: ArrayVisualizerSnapshotManager;
	private autoPlayNext = false;

	// For displaying
	swapCounter: string;
	comparisonCounter: string;

	// For highlighting the code statements in the Code Viewer.
	private selectedLines: HTMLElement[] = [];

	isPlaying = false;
	progress: number = 0;

	constructor() { }

	// Very very Important to clean up things
	ngOnDestroy()
	{
		// If autoPlayNext==true && the script is not cleared, 
		// 		the timer will keep running til the end of the script.
		//
		// Which means, this component will not be desctructed 
		//      if the script keeps playing.
		this.scriptManager.clear();

		// From Angular 11, the canvas must be destroyed
		// when routing between pages that use the same element ID.
		document.getElementById('canvasContainer').remove();
	}

	ngOnInit()
	{
		// Instantiate a visualizer
		let config: Array1DVisualizerConfig = {
			canvasID: 'array1D',
			marginTop: 50,
			marginLeft: 55,
			cellWidth: 50,
			cellHeight: 40,
			cellSpacing: 5,
			iteratorWidth: 25,
			iteratorHeight: 25,
			showGrid: true,
			cellColor: { fillColor: '#0097A7', strokeColor: '#18FFFF', strokeWidth: 2, textColor: 'white' },
			cellAccentColor: { fillColor: '#E64A19', strokeColor: '#ffccbc', strokeWidth: 2, textColor: 'white' },
			cellSelectionColor: { fillColor: '#795548', strokeColor: '#4e342e', strokeWidth: 2, textColor: 'white' },
			iteratorColor: { fillColor: '#fdd835', strokeColor: '#ffff6b', strokeWidth: 2, textColor: '#212121' },
			tagColor: { fillColor: 'transparent', strokeColor: 'transparent', strokeWidth: 2, textColor: '#212121' },
		};

		this.vs = new Array1DVisualizer([12, 6, 64, 66, 74, 23, 9, 10], config);
		//this.vs = new Array1DVisualizer(['Y', 'F', 'A', 'B', 'G', 'O', 'C', 'D'], config);
		this.prepareVS();

		// Snapshot - Init the first snapshot
		this.snapshotManager = new ArrayVisualizerSnapshotManager(this.vs.getSnapShot());

		// Script Manager
		this.scriptManager = new ScriptManager();
		this.buildScript();

		// Visualizer's event
		this.vs.onAnimationEnd.addHandler((args: any) =>
		{
			let length = this.scriptManager.getLength();
			this.progress = Math.ceil((this.scriptManager.getCurrentPosition() / (length - 2)) * 100);

			if (this.autoPlayNext)
			{
				if (this.scriptManager.getCurrentPosition() >= length - 1)
				{
					this.autoPlayNext = false;
					this.isPlaying = false;
				}
				setTimeout(() => { this.scriptManager.next(); }, 50);
			}
		});

		// ScriptManager's event
		this.scriptManager.onPositionChanged.addHandler(() =>
		{
			if (this.scriptManager.getCurrentPosition() == this.scriptManager.getLength() - 2)
			{
				this.progress = 100;
				this.autoPlayNext = false;
				this.isPlaying = false;
			}
		});
	}

	codeTracerUnselect()
	{
		this.selectedLines.forEach(e =>
		{
			e.style.backgroundColor = 'white';
		});

		this.selectedLines.length = 0;
	}

	codeTracerSelectLine(stmt: number)
	{
		let e = document.getElementById(`${this.selectedCode}_${stmt}`);

		if (e != null)
		{
			e.style.backgroundColor = 'yellow';
			this.selectedLines.push(e);
		}
	}

	codeTracerSelectMultipleLines(stmts: number[])
	{
		for (let i = 0; i < stmts.length; ++i)
		{
			let e = document.getElementById(`${this.selectedCode}_${stmts[i]}`);

			if (e != null)
			{
				e.style.backgroundColor = 'yellow';
				this.selectedLines.push(e);
			}
		}
	}

	buildScript()
	{
		let scriptBuilder = new Algorithms.InplaceAlgorithmScriptBuilder(this, this.scriptManager, this.snapshotManager, this.vs);
		scriptBuilder.buildScript(this.algorithm);

		// General Info
		for (let i = 0; i < this.statsValues.length; ++i)
		{
			this.statsValues[i] = this.scriptManager.getGeneralInfo(`${i}`).value;
		}
	}

	perform(command: string, args: any): void
	{
		switch (command)
		{
			case 'updateStatsValue':
				if (args[0] < this.statsValues.length)
				{
					this.statsValues[args[0]] = args[1];
				}
				break;
			case 'updateComparisonCounter':
				this.comparisonCounter = args[0];
				break;

			case 'updateSwapCounter':
				this.swapCounter = args[0];
				break;

			case 'unselectAllStatements':
				this.codeTracerUnselect();
				break;

			case 'selectStatement':
				this.codeTracerUnselect();
				this.codeTracerSelectLine(args[0]);
				break;

			case 'selectMultipleStatements':
				this.codeTracerUnselect();
				this.codeTracerSelectMultipleLines(args);
				break;

			case 'delay':
				if (this.isPlaying)
				{
					setTimeout(() =>
					{
						// Check if there is any action in the script. Except itself
						// getCurrentPosition() + 1 (`1` means this delay action itself)
						if (this.scriptManager.getCurrentPosition() + 1 < this.scriptManager.getLength())
						{
							this.scriptManager.next();
						}
					}, args[0] * this.vs.getSpeedRatio());
				}
				break;

			default:
				throw new Error("Method not implemented.");
		}
	}

	prepareVS()
	{
		switch (this.algorithm)
		{
			case "BubbleSort":
				// Add 2 interators
				if (this.vs.getNumberOfIters() == 0)
				{
					this.vs.createIterator('i', 0, false);
					this.vs.createIterator('j', 0, false);
				}
				break;

			case "BubbleSortFlag":
				// Add 2 interators
				if (this.vs.getNumberOfIters() == 0)
				{
					this.vs.createIterator('i', this.vs.getLength() - 1, false);
					this.vs.createIterator('j', 0, false);
				}
				break;

			case "InsertionSort":
				// Add 2 interators
				if (this.vs.getNumberOfIters() == 0)
				{
					this.vs.createIterator('i', this.vs.getLength() - 1, false);
					this.vs.createIterator('j', 0, false);
				}
				break;

			case "QuickSort1":
				// Add 2 interators
				if (this.vs.getNumberOfIters() == 0)
				{
					this.vs.createIterator('h', 1, false);
					this.vs.createIterator('k', this.vs.getLength() - 1, false);
				}

				if (this.vs.NumberOfTags == 0)
				{
					let n = this.vs.getLength();
					for (let i = 0; i < n; ++i)
					{
						this.vs.createTag('pivot', i, false);
					}
				}
				break;

			case "QuickSort2":
				// Add 2 interators
				if (this.vs.getNumberOfIters() == 0)
				{
					this.vs.createIterator('h', 0, false);
					this.vs.createIterator('k', 1, false);
				}

				if (this.vs.NumberOfTags == 0)
				{
					let n = this.vs.getLength();
					for (let i = 0; i < n; ++i)
					{
						this.vs.createTag('pivot', i, false);
					}
				}
				break;

			case "SelectionSort":
				// Add 2 interators
				if (this.vs.getNumberOfIters() == 0)
				{
					this.vs.createIterator('i', 0, false);
					this.vs.createIterator('j', 0, false);
				}

				if (this.vs.NumberOfTags == 0)
				{
					let n = this.vs.getLength();
					for (let i = 0; i < n; ++i)
					{
						this.vs.createTag('min', i, false);
					}
				}
				break;
		}
	}

	performMediaControlCommand($event: { command: string, args: any[] })
	{
		switch ($event.command)
		{
			case 'play':
				if (!this.vs.locked)
				{
					this.prepareVS();
					this.autoPlayNext = true; // This line of code must be on the top.
					this.isPlaying = true;
					this.scriptManager.next();

					// Update the badge display. These lines of codes should be outside of this function.
					if (this.scriptManager.getCurrentPosition() == 0)
					{
						for (let i = 0; i < this.statsValues.length; ++i)
						{
							this.statsValues[i] = 0;
						}
					}
				}
				break;

			case 'pause':
				this.autoPlayNext = false;
				this.isPlaying = false;
				break;

			case 'previous':
				if (!this.vs.locked)
				{
					let prevID = this.scriptManager.getPreviousSnapID();
					this.vs.restore(this.snapshotManager.getSnapshot(prevID));
					this.scriptManager.setCurrentPosition(this.scriptManager.findScriptIndexBySnapID(prevID));
					// console.log(`Backed to ${prevID}`);
					// console.log(this.snapshotManager.getSnapshot(prevID));
				}
				break;

			case 'next':
				if (!this.vs.locked)
				{
					this.scriptManager.next();
				}
				break;

			case 'resizeCanvas':
				this.vs.resizeCanvas();
				break;

			case 'random':
				this.autoPlayNext = false;
				this.isPlaying = false;
				this.scriptManager.clear();
				this.vs.createRandomNumbers(10);
				this.prepareVS();
				this.snapshotManager = new ArrayVisualizerSnapshotManager(this.vs.getSnapShot());
				this.buildScript();
				break;

			case 'speed':
				this.vs.setSpeed($event.args[0])
				break;
			default:
				throw new Error("Method not implemented.");
		}
	}

	testButton1()
	{
		let htmlE = document.getElementById('output');
		htmlE.innerHTML = this.scriptManager.getHTML();

		//this.scriptManager.jumpToSnapshot(5);
		for (let i = 0; i < this.snapshotManager.snapID; ++i)
		{
			console.log(`ScrIndex of Snap ${i}`);
			console.log(this.scriptManager.findScriptIndexBySnapID(i));
		}
	}


	testButton2()
	{
		for (let i = 0; i <= this.snapshotManager.snapID; ++i)
		{
			console.log(this.snapshotManager.getSnapshot(i));
		}
	}

	testButton3()
	{

	}

	testButton4()
	{

	}
}
