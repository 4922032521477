import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'nn-info-box',
	template: `
    <div class="info-box">
      	<span class="info-box-icon {{cssBgColor}}"><i class="material-icons md-light">speed</i></span>
      	<div class="info-box-content">
			<span class="info-box-title">{{title}}</span>
			<span class="info-box-message">{{message}}</span>
      	</div>
    </div>
  `,
	styles: [`
    
  `]
})
export class InfoBoxComponent implements OnInit
{
	@Input('title') title: string;
	@Input("message") message: string;
	@Input("cssBgColor") cssBgColor: string;

	constructor() { }

	ngOnInit()
	{
	}

}
