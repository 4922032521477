/*
    Nguyen, Nguyen

    January 12, 2020
*/

import { Shape2D, Shape2DStyle } from './Shape2D.lib';

export class Circle extends Shape2D
{
    private mRadius: number;
    private mTextAlign: string;

    constructor(x: number, y: number, radius: number,
        text: any, color: Shape2DStyle)
    {
        super(x, y);
        this.mRadius = radius;

        // Frame
        this.svg.setAttributeNS(null, 'w', `${radius * 2}`);
        this.svg.setAttributeNS(null, 'h', `${radius * 2}`);

        this.svg_base = document.createElementNS("http://www.w3.org/2000/svg", "circle");
        this.svg_base.setAttributeNS(null, 'cx', `${radius + color.strokeWidth / 2}`);
        this.svg_base.setAttributeNS(null, 'cy', `${radius + color.strokeWidth / 2}`);
        this.svg_base.setAttributeNS(null, 'r', `${radius}`);

        this.setFillColor(color.fillColor);
        this.setStrokeColor(color.strokeColor);
        this.setStrokeWidth(color.strokeWidth);
        
        // Text
        this.svg_text = document.createElementNS("http://www.w3.org/2000/svg", "text");
        this.svg_text.setAttributeNS(null, 'x', `${radius}`);
        this.svg_text.setAttributeNS(null, 'y', `${radius}`);
        this.svg_text.setAttributeNS(null, 'text-anchor', 'middle');
        this.svg_text.setAttributeNS(null, 'alignment-baseline', 'central');
        this.setText(text);
        this.setTextColor(color.textColor);

        this.svg.appendChild(this.svg_base);
        this.svg.appendChild(this.svg_text);
    }
    
    // Getters
    getRadius()
    {
        return this.mRadius;
    }

    getCenter()
    {
        return { x: this.getX() + this.mRadius, y: this.getY() + this.mRadius };
    }

    getCenterX()
    {
        return this.getX() + this.mRadius;
    }

    getCenterY()
    {
        return this.getY() + this.mRadius;
    }

    getTextAlign()
    {
        return this.mTextAlign;
    }

    setTextAlign(value: string)
    {
        this.mTextAlign = value;
    }
}