import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-undirected-graph',
  templateUrl: './undirected-graph.component.html',
  styleUrls: ['./undirected-graph.component.css']
})
export class UndirectedGraphComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

	ngOnInit(): void
	{
		this.title.setTitle('Undirected graph');
		this.meta.updateTag({ name: 'description', content: 'Undirected Graph Visualization' });
    this.meta.updateTag({ name: 'keywords', content: 'graph, undirected, computer science, node, nodes, vertex, vertices, edge, dfs, depth first search, bfs, breath first search ' });
	}
}