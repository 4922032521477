<div style="min-width: 220px;">
	<div class=" box box-info">
		<div class="box-header ">
			<h3 class="box-title "></h3>

			<div class="box-tools ">
				<span class="badge bg-green"></span>
				<span style="padding-right: 10px;"></span>
				<span class="badge bg-yellow"></span>
			</div>
		</div>

		<div class="box-body">
			<svg
				id="canvasContainer"
				width="100%"
				height="550"
				viewBox="0 0 100 500"
				preserveAspectRatio="xMinYMin meet"
				xmlns="http://www.w3.org/2000/svg"
			>
				<defs>
					<marker
						id="head"
						orient="auto"
						markerWidth="3"
						markerHeight="6"
						refX="2"
						refY="2"
					>
						<!-- triangle pointing right (+x) -->
						<path d="M0,0 V4 L3,2 Z" fill="black" />
					</marker>
				</defs>

				<svg id="edges" width="900">
					<path
						id="arrow-line"
						marker-end="url(#head)"
						stroke-width="2"
						fill="none"
						stroke="red"
						d="M0,0 1,1"
					/>
				</svg>
				<svg id="nodes" width="900"></svg>
			</svg>
		</div>
		<div class="box-footer">
			<mat-form-field style="width:100px !important">
				<input
					#txtNumber
					matInput
					placeholder="Enter a number"
					value="50"
				/>
			</mat-form-field>
			<button mat-raised-button (click)="btnInsert(txtNumber.value)">
				Insert
			</button>
			|
			<button mat-raised-button (click)="testButton3()">Insert Random Number</button>
			<button mat-raised-button (click)="testButton1()">Insert 50, 30, 10, 40</button>
			<button mat-raised-button (click)="testButton2()">Insert 100, 70, 55, 75, 80</button>
			<button mat-raised-button (click)="testButton4()">Print to Console</button>
			<button mat-raised-button (click)="testButton5()">Test 5</button>
			<button mat-raised-button (click)="testButton6()">Test 6</button>

			
			
		</div>
	</div>
</div>
