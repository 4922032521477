import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-add-edges',
  templateUrl: './add-edges.component.html',
  styleUrls: ['./add-edges.component.css']
})
export class AddEdgesForm {

  constructor(private _bottomSheetRef: MatBottomSheetRef<AddEdgesForm>) { }

  cancel()
	{
		this._bottomSheetRef.dismiss('');
	}
	
	createNodes(edges: string) 
	{		
		this._bottomSheetRef.dismiss(edges);
	}
}