import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-directed-graph',
	templateUrl: './directed-graph.component.html',
	styleUrls: ['./directed-graph.component.css']
})
export class DirectedGraphComponent implements OnInit
{

	constructor(private title: Title, private meta: Meta) { }

	ngOnInit(): void
	{
		this.title.setTitle('Directed graph');
		this.meta.updateTag({ name: 'description', content: 'Directed Graph Visualization' });
        this.meta.updateTag({ name: 'keywords', content: 'graph, directed, computer science, node, nodes, vertex, vertices, edge, dfs, depth first search, bfs, breath first search ' });
	}
}