import { Shape2DStyle, Shape2D } from './Shape2D.lib';
import { AnimationController } from './AnimationController.lib';
import { EventHandler } from './EventHandler.lib';
import { Circle } from './Circle.lib';
import { Performable } from './ScriptManager.lib';
import { Line } from './Line.lib';

export interface TreeVisualizerConfig
{
    readonly canvasID: string; // The ID of a canvas to draw on
    readonly edgeCanvasID: string;
    marginTop: number;
    marginLeft: number;
    treeType: 'BST' | 'AVL';
    nodeRadius: number;
    horizontalSpacing: number; // Node spacing
    verticalSpacing: number; // Node spacing
    nodeColor: Shape2DStyle;
    nodeAccentColor: Shape2DStyle;
    nodeSelectionColor: Shape2DStyle;
    tagColor: Shape2DStyle;
}

export class Node extends Circle
{
    public data: any;
    public leftLink: Node;
    public rightLink: Node;
    public parentLink: Node;
    public level: number;
    public maxDepth: number;
    private offset: { h: number; v: number; }

    //temp 
    public animCtrl: AnimationController;

    constructor(data: any, parentLink: Node, leftLink: Node, rightLink: Node, color: Shape2DStyle, offset: { h: number, v: number })
    {
        super(0, 0, 20, data, color);
        this.data = data;
        this.leftLink = leftLink;
        this.rightLink = rightLink;
        this.parentLink = parentLink;
        this.offset = offset;

        if (this.parentLink)
        {
            if (this.data < parentLink.data)
            {
                // this.setPosition(parentLink.getX() - parentLink.getRadius() - offset.h, parentLink.getY() + offset.v);
            }
            else
            {
                // this.setPosition(parentLink.getX() + parentLink.getRadius() + offset.h, parentLink.getY() + offset.v);
            }
        }

        if (parentLink)
        {
            parentLink.onPositionChanged.addHandler(() =>
            {
                if (this.data < parentLink.data)
                {
                    this.setPosition(parentLink.getX() - parentLink.getRadius() - this.maxDepth * offset.h, parentLink.getY() + offset.v);
                }
                else
                {
                    this.setPosition(parentLink.getX() + parentLink.getRadius() + this.maxDepth * offset.h, parentLink.getY() + offset.v);
                }
            });
        }
    }

    get Depth()
    {
        return this.maxDepth;
    }
}

export class DirectedEdge extends Line
{
    public tail: Node;
    public head: Node;
    public weight: number;
    public text: any;

    constructor(tailNode: Node, headNode: Node, color: Shape2DStyle, weight?: number, text?: any)
    {
        super(tailNode.getCenterX(), tailNode.getCenterY(), headNode.getCenterX(), headNode.getCenterY(), text, color);

        this.tail = tailNode;
        this.head = headNode;

        if (weight)
        {
            this.weight = weight;
        }

        if (text)
        {
            this.text = text;
        }

        // Event Handlers
        this.tail.onPositionChanged.addHandler(() => { this.setTailPoint(this.tail.getCenterX(), this.tail.getCenterY()) });
        this.head.onPositionChanged.addHandler(() => { this.setHeadPoint(this.head.getCenterX(), this.head.getCenterY()) });
    }
}

export class TreeVisualizer implements Performable
{
    /***** Config *****/
    readonly config: TreeVisualizerConfig;

    // Canvas / context
    private canvas: any; // The canvas to draw on
    private edgesCanvas: any;
    public root: Node; // Root Node // Public for testing only
    //public data: any[]; // Store data
    //private elements: Shape2D[]; // Array elements

    // Animation Controller
    animCtrl: AnimationController;

    // Event
    onAnimationEnd: EventHandler;

    // Lock
    locked = false;

    // Playing speed
    private speedRatio: number = 1;

    constructor(config: TreeVisualizerConfig)
    {
        this.config = config;
        this.canvas = document.getElementById(config.canvasID);
        this.edgesCanvas = document.getElementById(config.edgeCanvasID);

        if (this.canvas == null)
        {
            console.log(`Canvas ${config.canvasID} not found.`);
        }
        else
        {
            // Init
            this.onAnimationEnd = new EventHandler();
            this.animCtrl = new AnimationController();

            this.animCtrl.onStop.addHandler(() =>
            {
                if (this.nodesNeedReArraging.length > 0)
                {
                    // BFS
                    if (this.nodesNeedReArraging[0].leftLink)
                    {
                        this.nodesNeedReArraging.push(this.nodesNeedReArraging[0].leftLink);
                    }

                    if (this.nodesNeedReArraging[0].rightLink)
                    {
                        this.nodesNeedReArraging.push(this.nodesNeedReArraging[0].rightLink);
                    }

                    this.arrange(this.nodesNeedReArraging[0]);
                    this.nodesNeedReArraging.splice(0, 1)
                }
            });
        }

        // Canvas Resize event
        window.addEventListener('resize', this.resizeCanvas.bind(this), false);
        window.addEventListener('orientationchange', this.resizeCanvas.bind(this), false);
    }

    public nodesNeedReArraging: Node[] = [];

    arrange(ptr: Node)
    {
        let num = (ptr.maxDepth > 2 ? this.config.horizontalSpacing * 2 : this.config.horizontalSpacing);

        if (ptr.level == 0 && ptr.maxDepth >= 4)
        {
            num += this.config.horizontalSpacing;
        }
        if (ptr.leftLink != null)
        {
            this.animCtrl.addLinearMotion(ptr.leftLink, [

                { x: ptr.getX() - ptr.getRadius() - Math.pow(2, ptr.maxDepth) / 4 * num, y: ptr.leftLink.getY(), duration: 200 }
                // { x: ptr.getX() - ptr.getRadius() - Math.pow(2, ptr.maxDepth) / 4 * this.config.horizontalSpacing - (ptr.leftLink.maxDepth * this.config.horizontalSpacing), y: ptr.leftLink.getY(), duration: 200 }
                //{ x: ptr.getX() - ptr.getRadius() - (ptr.maxDepth > 2 ? this.config.horizontalSpacing : 0) - Math.pow(2, ptr.maxDepth) / 4 * this.config.horizontalSpacing, y: ptr.leftLink.getY(), duration: 200 }
                // { x: ptr.getX() - ptr.getRadius() - ((this.root.maxDepth - ptr.level - 1) * this.config.horizontalSpacing), y: ptr.leftLink.getY(), duration: 200 }
            ]);
        }

        if (ptr.rightLink != null)
        {
            this.animCtrl.addLinearMotion(ptr.rightLink, [
                { x: ptr.getX() + ptr.getRadius() + Math.pow(2, ptr.maxDepth) / 4 * num, y: ptr.rightLink.getY(), duration: 200 }
                // { x: ptr.getX() + ptr.getRadius() + Math.pow(2, ptr.maxDepth) / 4 * this.config.horizontalSpacing + (ptr.rightLink.maxDepth * this.config.horizontalSpacing), y: ptr.rightLink.getY(), duration: 200 }
                // { x: ptr.getX() + ptr.getRadius() + ((this.root.maxDepth - ptr.level - 1) * this.config.horizontalSpacing), y: ptr.rightLink.getY(), duration: 200 }
            ]);
        }

        this.animCtrl.start();
    }
    /**/

    arrange2(ptr: Node)
    {
        console.log(`Arranging ${ptr.data}`)

        if (ptr.leftLink != null)
        {
            let levelsToRightmost = this.countLevelsToRightmost(ptr.leftLink);

            if (ptr.leftLink.rightLink != null)
            {
                levelsToRightmost += this.countLevelsToLeftmost(ptr.leftLink.rightLink);
            }

            let num: number

            if (levelsToRightmost > 0)
                num = levelsToRightmost * (2 * this.config.nodeRadius + this.config.horizontalSpacing);
            else
                num = this.config.horizontalSpacing;

            let new_x = ptr.getX() - ptr.getRadius() - num;
            if (new_x != ptr.getX())
            {
                this.animCtrl.addLinearMotion(ptr.leftLink, [
                    { x: new_x, y: ptr.leftLink.getY(), duration: 200 }
                ]);
            }
        }

        if (ptr.rightLink != null)
        {
            let levelsToLeftmost = this.countLevelsToLeftmost(ptr.rightLink);

            if (ptr.rightLink.leftLink != null)
            {
                levelsToLeftmost += this.countLevelsToRightmost(ptr.rightLink.leftLink);
            }

            let num: number

            if (levelsToLeftmost > 0)
                num = levelsToLeftmost * (2 * this.config.nodeRadius + this.config.horizontalSpacing);
            else
                num = this.config.horizontalSpacing;

            let new_x = ptr.getX() + ptr.getRadius() + num;
            if (new_x != ptr.getX())
            {
                this.animCtrl.addLinearMotion(ptr.rightLink, [
                    { x: new_x, y: ptr.rightLink.getY(), duration: 200 }
                ]);
            }
        }

        this.animCtrl.start();
    }
    /*
    updateMaxDepth(ptr: Node)
    {
        let newDepth: number;
        if (ptr.leftLink == null && ptr.rightLink == null)
        {
            newDepth = 1;
        }
        else if (ptr.leftLink != null && ptr.rightLink != null)
        {
            newDepth = 1 + (ptr.leftLink.maxDepth > ptr.rightLink.maxDepth ? ptr.leftLink.maxDepth : ptr.rightLink.maxDepth);
        }
        else if (ptr.leftLink != null)
        {
            newDepth = 1 + ptr.leftLink.maxDepth;
        }
        else
        {
            newDepth = 1 + ptr.rightLink.maxDepth;
        }

        if (ptr.maxDepth != newDepth)
        {
            ptr.maxDepth = newDepth;

            if (ptr.parentLink != null)
            {
                if (this.nodesNeedReArraging.length == 0)
                {
                    this.nodesNeedReArraging.push(ptr.parentLink);
                }
                else
                {
                    this.nodesNeedReArraging[0] = ptr.parentLink;
                }

                this.updateMaxDepth(ptr.parentLink);
            }
        }
    }   
    */

    updateMaxDepth(ptr: Node)
    {
        let newDepth: number;
        if (ptr.leftLink == null && ptr.rightLink == null)
        {
            newDepth = 1;
        }
        else if (ptr.leftLink != null && ptr.rightLink != null)
        {
            newDepth = 1 + (ptr.leftLink.maxDepth > ptr.rightLink.maxDepth ? ptr.leftLink.maxDepth : ptr.rightLink.maxDepth);
        }
        else if (ptr.leftLink != null)
        {
            newDepth = 1 + ptr.leftLink.maxDepth;
        }
        else
        {
            newDepth = 1 + ptr.rightLink.maxDepth;
        }

        if (ptr.maxDepth != newDepth)
        {
            ptr.maxDepth = newDepth;

            if (ptr.parentLink != null)
            {

                if (this.nodesNeedReArraging.length == 0)
                {
                    this.nodesNeedReArraging.push(ptr.parentLink);
                }
                else
                {
                    this.nodesNeedReArraging[0] = ptr.parentLink;
                }

                this.updateMaxDepth(ptr.parentLink);
            }
        }
        /*
        if (this.nodesNeedReArraging.length == 0)
        {
            this.nodesNeedReArraging.push(ptr.parentLink);
        }
        else
        {
            this.nodesNeedReArraging[0] = this.root;
        }
        */
    }

    maxDepth(ptr: Node)
    {
        if (ptr != null)
        {
            let leftMax = this.maxDepth(ptr.leftLink);
            let rightMax = this.maxDepth(ptr.rightLink);

            return (leftMax > rightMax ? leftMax + 1 : rightMax + 1);
        }
        else
        {
            return 0;
        }
    }

    countLevelsToLeftmost(ptr: Node)
    {
        if (ptr == null)
        {
            return 0;
        }
        else if (ptr.leftLink == null)
        {
            return 0;
        }
        else
        {
            return 1 + this.countLevelsToLeftmost(ptr.leftLink);
        }
    }

    countLevelsToRightmost(ptr: Node)
    {
        if (ptr == null)
        {
            return 0;
        }
        else if (ptr.rightLink == null)
        {
            return 0;
        }
        else
        {
            return 1 + this.countLevelsToRightmost(ptr.rightLink);
        }
    }

    get leftmost(): Node
    {
        let current = this.root;

        while (current.leftLink != null)
        {
            current = current.leftLink;
        }

        return current;
    }

    get rightmost(): Node
    {
        let current = this.root;

        while (current.rightLink != null)
        {
            current = current.rightLink;
        }

        return current;
    }

    printInOrder()
    {
        this.recursivePrintInOrder(this.root);
    }

    private recursivePrintInOrder(ptr: Node)
    {
        if (ptr)
        {
            this.recursivePrintInOrder(ptr.leftLink);
            console.log(`element: ${ptr.data}, isNaN: ${isNaN(ptr.data)}, level: ${ptr.level}, maxDepth: ${ptr.Depth}, x: ${ptr.getX()}`);
            this.recursivePrintInOrder(ptr.rightLink);
        }
    }

    perform(command: string, args: any[]): void
    {
        if (!this.locked)
        {
            switch (command)
            {
                case "test": // Will not raise any event
                    this.animCtrl.addLinearMotion(this.root, [
                        { x: 300, y: 400, duration: 2000 },
                        { x: 100, y: 300, duration: 2000 },
                        { x: 200, y: 100, duration: 2000 },
                    ]);

                    this.animCtrl.start();
                    break;

                case 'insert':
                    this.insert(args[0]);
                    break;

                case 'print':
                    console.log(this.root.leftLink.data)
                    break;

                default:
                    throw new Error("Method not implemented.");
            }
        }
    }

    moveNode(ptr: Node, newX: number, newY: number)
    {
        this.animCtrl.addLinearMotion(ptr, [
            { x: newX, y: newY, duration: 200 }
        ]);
        this.animCtrl.start();
    }

    insert(elem: any)
    {
        if (this.root == null)
        {
            this.root = new Node(elem, null, null, null, this.config.nodeColor, { h: this.config.horizontalSpacing, v: this.config.verticalSpacing });
            this.root.level = 0;
            this.root.maxDepth = 1;
            this.root.animCtrl = this.animCtrl;
            // let svg = document.querySelector(`#canvasContainer`) as SVGSVGElement;
            // this.root.setPosition(svg.width.baseVal.value / 2, 0);
            this.root.setPosition(this.canvas.width.baseVal.value / 2, 0);
            this.canvas.appendChild(this.root.getSVG());
        }
        else if (this.config.treeType == 'BST') 
        {
            this.bstInsert(this.root, elem, 1);

            // 
            if (this.leftmost.getX() <= this.config.nodeRadius * 2 + this.config.horizontalSpacing)
            {
                this.moveNode(this.root, this.root.getX() + this.config.nodeRadius * 2 + this.config.horizontalSpacing, this.root.getY());
            }
        }

        this.animCtrl.start();
    }


    bstInsert(ptr: Node, elem: any, level: number)
    {
        if (!isNaN(elem))
        {
            elem = parseFloat(elem.toString())
        }

        if (elem == ptr.data)
        {
            console.error('duplicate')
            return null;
        }
        else if (elem < ptr.data)
        {
            if (ptr.leftLink == null)
            {
                // Create a Node
                ptr.leftLink = new Node(elem, ptr, null, null, this.config.nodeColor, { h: this.config.horizontalSpacing, v: this.config.verticalSpacing })
                ptr.leftLink.level = level;
                ptr.leftLink.setPosition(ptr.getX() - this.config.nodeRadius - Math.pow(2, ptr.maxDepth) / 4 - this.config.horizontalSpacing, ptr.getY() + this.config.verticalSpacing);
                this.updateMaxDepth(ptr.leftLink);
                ptr.animCtrl = this.animCtrl;

                this.canvas.appendChild(ptr.leftLink.getSVG());

                // Create an Edge
                let edge = new DirectedEdge(ptr, ptr.leftLink, this.config.nodeSelectionColor);
                this.edgesCanvas.appendChild(edge.getSVG());

                return ptr.leftLink;
            }
            else
            {
                return this.bstInsert(ptr.leftLink, elem, level + 1);
            }
        }
        else
        {
            if (ptr.rightLink == null)
            {
                // Create a Node
                ptr.rightLink = new Node(elem, ptr, null, null, this.config.nodeColor, { h: this.config.horizontalSpacing, v: this.config.verticalSpacing });
                ptr.rightLink.level = level;
                ptr.rightLink.setPosition(ptr.getX() + this.config.nodeRadius + Math.pow(2, ptr.maxDepth) / 4 - + this.config.horizontalSpacing, ptr.getY() + this.config.verticalSpacing);
                this.updateMaxDepth(ptr.rightLink);
                ptr.animCtrl = this.animCtrl;

                this.canvas.appendChild(ptr.rightLink.getSVG());

                // Create an Edge
                let edge = new DirectedEdge(ptr, ptr.rightLink, this.config.nodeSelectionColor);
                this.edgesCanvas.appendChild(edge.getSVG());

                return ptr.rightLink;
            }
            else
            {
                return this.bstInsert(ptr.rightLink, elem, level + 1);
            }
        }
    }

    bstInserEdge(nodeA?: Node, nodeB?: Node)
    {

        let line = new Line(70, 50, 100, 190, '', this.config.nodeSelectionColor);
        this.edgesCanvas.appendChild(line.getSVG());
    }

    /**
     * Resize to fit the content
     */
    resizeCanvas()
    {
        let svg = document.querySelector(`#canvasContainer`) as SVGSVGElement;
        let vbBase = svg.viewBox.baseVal;
        let box = svg.getBBox();

        // scale = Size of Objects / Size of Canvas
        let scale = (box.width) / svg.width.baseVal.value;

        if (scale > 0.9) // No need to scale up.
        {
            vbBase.height = (svg.height.baseVal.value) * scale;
        }

        vbBase.x = - (svg.width.baseVal.value - (this.config.marginLeft * 4) - (box.width / (scale > 1 ? scale : 1))) / 4;
        vbBase.y = 0;
    }
}