import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})

export class PublicService
{
	// Observable string sources
	private sidebarState = new Subject<boolean>();

	// Observable string streams
	sidebarStateChanged$ = this.sidebarState.asObservable();

	constructor() { }

	// Service message commands
	changeSidebarState(opened: boolean)
	{
		this.sidebarState.next(opened);
	}
}
