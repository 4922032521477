import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

// Google Analytics
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent
{
  title = 'Learn CS';

  // The constructor was added for Google Analytics. By default, there is no constructors.
  constructor(router: Router)
  {
    const navEndEvents = router.events.pipe(filter(event => event instanceof NavigationEnd));
    navEndEvents.subscribe((event: NavigationEnd) =>
    {
      gtag('config', 'UA-24490275-7', { 'page_path': event.urlAfterRedirects });
    })
  }
}