import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SideNavComponent } from './side-nav/side-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatMenuModule} from '@angular/material/menu';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { BubbleSortComponent } from './views/Algorithms/bubble-sort/bubble-sort.component';
import { GoogleAnalyticsService } from './google-analytics.service';
import { MediaControlComponent } from './CustomComponents/media-control/media-control.component';
import { InfoBoxComponent } from './CustomComponents/info-box/info-box.component';
import { SmallBoxComponent } from './CustomComponents/small-box/small-box.component';
import { MatTabsModule } from '@angular/material/tabs'
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSliderModule } from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatInputModule} from '@angular/material/input';
import {MatStepperModule} from '@angular/material/stepper';
import { InplaceAlgorithmVisualizerComponent } from './CustomComponents/inplace-algorithm-visualizer/inplace-algorithm-visualizer.component';
import { BubbleSortFlagComponent } from './views/Algorithms/bubble-sort-flag/bubble-sort-flag.component';
import { InsertionSortComponent } from './views/Algorithms/insertion-sort/insertion-sort.component';
import { SelectionSortComponent } from './views/Algorithms/selection-sort/selection-sort.component';
import { BinaryTreeComponent } from './views/data-structures/binary-tree/binary-tree.component';
import { TreeVisualizerComponent } from './CustomComponents/tree-visualizer/tree-visualizer.component';
import { UndirectedGraphComponent } from './views/data-structures/graphs/undirected-graph.component';
import { FadeComponent } from './CustomComponents/fade/fade.component';
import { GraphComponent } from './CustomComponents/graph/graph.component';
import { AddNodesForm } from './CustomComponents/graph/forms/add-nodes/add-nodes.component';
import { AddEdgesForm } from './CustomComponents/graph/forms/add-edges/add-edges.component';
import { SelectNodeForm } from './CustomComponents/graph/forms/select-node/select-node.component';
import { DirectedGraphComponent } from './views/data-structures/graphs/directed-graph/directed-graph.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateEditEdgeComponent } from './CustomComponents/graph/forms/create-edit-edge/create-edit-edge.component';

@NgModule({
  declarations: [
    AppComponent,
    SideNavComponent,
    routingComponents,
    BubbleSortComponent,
    MediaControlComponent,
    InfoBoxComponent,
    SmallBoxComponent,
    InplaceAlgorithmVisualizerComponent,
    BubbleSortFlagComponent,
    InsertionSortComponent,
    SelectionSortComponent,
    BinaryTreeComponent,
    TreeVisualizerComponent,
    UndirectedGraphComponent,
    FadeComponent,
    GraphComponent,
    AddNodesForm,
    AddEdgesForm,
    SelectNodeForm,
    DirectedGraphComponent,
    CreateEditEdgeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatBottomSheetModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTabsModule,
    MatProgressBarModule,
    MatSliderModule,
    MatSnackBarModule,
    MatInputModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    GoogleAnalyticsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
